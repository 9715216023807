import { BigNumber, ethers } from "ethers";
import moment from "moment";
import { StyledComponent } from "styled-components";
import { GnosisLogo, ParadigmLogo } from "../assets/icons/logo";
import { USDCLogo, STETHLogo } from "../assets/icons/tokens";
import {
  AuctionAddressMap,
  AuctionOptions,
  getBiddingToken,
  getUnderlyingToken,
  isParadigmAuction,
  SCHEDULE,
} from "../constants/constants";
import colors from "../design/colors";
import {
  AuctionData,
  AugmentedAuctionData,
  BidData,
  ProcessedBidData,
  VaultDataResponses,
} from "../models/auction";
import {
  Assets,
  getAssetColor,
  getAssetDecimals,
  getAssetLogo,
  getAssetLogoSize,
} from "./asset";
import { evaluateBids, findClearingPrice } from "./calculations";
import { decodeOrder } from "./order";
import { formatStrikeTitle } from "./text";

export const AuctionContractList = [
  "GNOSIS",
  "PARADIGM",
  "R-EARN",
  "R-EARN-STETH",
] as const;

export const getAuctionContractColor = (
  auctionContract: AuctionContracts
): string => colors.auctionContract[auctionContract];

export const getAuctionContractLogo: (auctionContract: AuctionContracts) =>
  | StyledComponent<
      React.FC<React.SVGAttributes<SVGElement>>,
      any,
      { backgroundColor?: string },
      never
    >
  | React.FC<React.SVGAttributes<SVGElement>>
  | React.FC<
      React.SVGAttributes<SVGElement> & {
        markerConfig?: {
          height?: number;
          width?: number;
          right?: string;
          bottom?: string;
          border?: string;
        };
      }
    >
  | React.FC<React.SVGAttributes<SVGElement> & { showBackground?: boolean }> = (
  auctionContract
) => {
  switch (auctionContract) {
    case "GNOSIS":
      return GnosisLogo;
    case "PARADIGM":
      return ParadigmLogo;
    case "R-EARN":
      return USDCLogo;
    case "R-EARN-STETH":
      return STETHLogo;
  }
};

export type AuctionContracts = typeof AuctionContractList[number];

export const isAuctionSupportedOnChain = (
  AuctionOption: AuctionOptions,
  chainId: number
): Boolean => {
  return AuctionAddressMap[AuctionOption].chainId === chainId;
};

export function getAuctionName(auctionData: AuctionData) {
  const title = auctionData.option.symbol.split("/")[1];

  return (
    title.split("-")[0] +
    "-" +
    title.split("-")[1] +
    "-" +
    title.split("").pop()
  );
}

export function resolveClearingStatus(
  auctionData: AuctionData,
  bidData: BidData[]
) {
  let clearingPrice: any;
  let filledSize: any;
  let processedBids: ProcessedBidData[];
  const biddingTokenDecimals = auctionData.bidding.decimals;
  const size = auctionData.size;

  if (bidData.length === 0) {
    clearingPrice = undefined;
    filledSize = undefined;
    processedBids = [];
  } else {
    const priceDiscovery = findClearingPrice(auctionData, bidData);
    clearingPrice = ethers.utils.formatUnits(
      priceDiscovery.clearing,
      biddingTokenDecimals
    );

    const allocationDiscovery = evaluateBids(
      auctionData,
      bidData,
      priceDiscovery.clearing.toString()
    );
    filledSize = ethers.utils.formatUnits(
      allocationDiscovery.filled.mul(10 ** 8).div(size),
      6
    );

    processedBids = allocationDiscovery.bids;
  }

  return {
    clearingPrice,
    filledSize,
    processedBids,
  };
}

export function getAuctionOption(auctionTitle: string) {
  const [auctionName] = auctionTitle.split("&");
  const [underlying, , optionType] = auctionName.split("-");

  return underlying + "-" + (optionType === "C" ? "call" : "put");
}

export function isUpcomingAuction(auctionTitle: string) {
  if (auctionTitle) {
    const [auctionName, index] = auctionTitle.split("&");
    const [underlying, expiry, optionType] = auctionName.split("-");

    const auctionOption =
      underlying + "-" + (optionType === "C" ? "call" : "put");

    const schedule = SCHEDULE[auctionOption as AuctionOptions];
    let friday: string;
    if (schedule?.frequency === "weekly") {
      friday = moment().add(1, "week").day(5).format("DDMMMYY").toUpperCase();
    } else {
      const expiry = moment().add(1, "month").endOf("month");
      friday =
        expiry.day() < 5
          ? expiry.subtract(1, "week").day(5).format("DDMMMYY").toUpperCase()
          : expiry.day(5).format("DDMMMYY").toUpperCase();
    }

    const rightIndex = index === "1";
    const rightExpiry = expiry === friday;
    return rightIndex && rightExpiry ? auctionOption : undefined;
  } else {
    return undefined;
  }
}

export function resolveAuctionData(data: AugmentedAuctionData) {
  const biddingToken = data.bidding.symbol as Assets;
  const underlyingToken = data.option.underlying.symbol as Assets;
  const oTokenAddress = data.option.id;
  const title = data.option.symbol.split("/")[1];
  let vaultAssetRaw = title.split("-")[0];
  if (vaultAssetRaw === "SPELLTWO") {
    vaultAssetRaw = "SPELL";
  }
  const vaultAsset = vaultAssetRaw as Assets;
  const Logo = getAssetLogo(vaultAsset);
  const BiddingLogo = getAssetLogo(biddingToken);
  const biddingTokenColor = getAssetColor(biddingToken);
  const logoSizeXSmall = getAssetLogoSize(vaultAsset, "xs");
  const logoSizeSmall = getAssetLogoSize(vaultAsset, "s");
  const logoSizeMedium = getAssetLogoSize(vaultAsset, "m");
  const logoSizeLarge = getAssetLogoSize(vaultAsset, "l");
  const color = getAssetColor(vaultAsset);

  const starttime = moment
    .unix(Number(data.start))
    .utc()
    .format("DD MMM YY, HH:mm [UTC]");

  const size = ethers.utils.formatUnits(data.size, 8);
  const biddingTokenDecimals = data.bidding.decimals;
  const index = data.index;
  const decimalPlaces = biddingToken === "USDC" ? 2 : 4;
  const strike = ethers.utils.formatUnits(data.option.strike, 8);
  const expiry = moment
    .unix(Number(data.option.expiry))
    .utc()
    .format("DD MMM YY, HH:mm UTC");
  const optionType = data.option.put ? "put" : "call";
  const vault = vaultAsset + "-" + optionType;

  const endtime = isParadigmAuction(vault as AuctionOptions)
    ? moment
        .unix(Number(data.start))
        .add(10, "minutes")
        .utc()
        .format("DD MMM YY, HH:mm [UTC]")
    : moment.unix(Number(data.end)).utc().format("DD MMM YY, HH:mm [UTC]");

  let clearing: string | undefined = undefined;
  let filled: string | undefined = undefined;
  let minBid =
    data.size !== "0"
      ? ethers.utils.formatUnits(
          BigNumber.from(data.minimum)
            .mul(10 ** 8)
            .div(data.size),
          biddingTokenDecimals
        )
      : "0";

  if (data.clearing !== "0x") {
    try {
      const clearingOrder = decodeOrder(data.clearing);

      clearing = ethers.utils.formatUnits(
        clearingOrder.sellAmount.mul(10 ** 8).div(clearingOrder.buyAmount),
        biddingTokenDecimals
      );

      filled =
        data.size !== "0"
          ? ethers.utils.formatUnits(
              BigNumber.from(data.filled)
                .mul(10 ** 8)
                .div(data.size),
              6
            )
          : "0";
    } catch {
      clearing = ethers.utils.formatUnits(
        BigNumber.from(data.clearing),
        biddingTokenDecimals
      );

      filled =
        data.size !== "0"
          ? ethers.utils.formatUnits(
              BigNumber.from(data.filled)
                .mul(10 ** 8)
                .div(data.size),
              6
            )
          : "0";
      minBid = "0";
    }
  }

  const link = "/auction/" + getAuctionName(data) + "&" + index;

  return {
    biddingToken,
    Logo,
    logoSizeXSmall,
    logoSizeSmall,
    logoSizeMedium,
    logoSizeLarge,
    color,
    title,
    vaultAsset,
    endtime,
    starttime,
    size,
    strike,
    biddingTokenDecimals,
    decimalPlaces,
    filled,
    clearing,
    minBid,
    link,
    vault,
    expiry,
    underlyingToken,
    BiddingLogo,
    biddingTokenColor,
    index,
    optionType,
    oTokenAddress,
  };
}

export function constructUpcomingAuction(
  auction: AuctionOptions,
  vaultSizes: VaultDataResponses
) {
  const today = moment();

  const _auction = auction as AuctionOptions;

  const frequency = SCHEDULE[_auction]?.frequency;

  const [start, end] = getScheduledTime(_auction);

  let expiry: moment.Moment;
  if (frequency === "weekly") {
    expiry =
      today.day() === 6
        ? today.add(2, "week").utc().day(5).hour(8).minute(0).second(0)
        : today.add(1, "week").utc().day(5).hour(8).minute(0).second(0);
  } else {
    expiry = today.add(1, "month").endOf("month");
    expiry =
      expiry.day() < 5 ? expiry.subtract(1, "week").day(5) : expiry.day(5);
  }

  const expiryText = expiry.format("DDMMMYY").toUpperCase();
  const vaultAsset = auction.split("-")[0];
  const isPut = auction.split("-")[1] === "put";
  const optionType = isPut ? "P" : "C";
  const bidding = getBiddingToken(_auction);
  const underlying = getUnderlyingToken(_auction);
  const decimal = getAssetDecimals(vaultAsset as Assets);
  const divider = ethers.utils.parseUnits("1", decimal);
  const size = vaultSizes[_auction].estimatedSize.mul(10 ** 8).div(divider);

  let strike = "0";
  let strikeText = "";
  try {
    strike = vaultSizes[_auction].strike.toString();
    if (strike !== "0") {
      strikeText = formatStrikeTitle(
        ethers.utils.formatUnits(vaultSizes[_auction].strike, 8)
      );
    }
  } catch {}

  return {
    id: "",
    bidding: {
      id: "",
      name: "",
      symbol: bidding,
      decimals: 0,
    },
    option: {
      id: "",
      name: "",
      symbol: `o${bidding}USDC/${vaultAsset}-${expiryText}-${strikeText}${optionType}`,
      decimals: "",
      expiry: expiry.unix().toString(),
      strike: strike,
      underlying: {
        id: "",
        name: "",
        symbol: underlying,
        decimals: 0,
      },
      put: isPut,
    },
    minimum: "0",
    size: size.toString(),
    start: start.toString(),
    end: end.toString(),
    filled: "0",
    clearing: "0x",
    live: false,
    index: 1,
    chainId: 0,
  } as AugmentedAuctionData;
}

export function getScheduledTime(_auction: AuctionOptions): number[] {
  const today = moment();
  const frequency = SCHEDULE[_auction]?.frequency;

  let start: number;
  let end: number;
  if (frequency === "weekly") {
    start =
      today.day() === 6
        ? moment
            .utc(SCHEDULE[_auction]!.start, "HH.mm")
            .day(5)
            .add(1, "week")
            .unix()
        : moment.utc(SCHEDULE[_auction]!.start, "HH.mm").day(5).unix();
    end =
      today.day() === 6
        ? moment
            .utc(SCHEDULE[_auction]!.end, "HH.mm")
            .day(5)
            .add(1, "week")
            .unix()
        : moment.utc(SCHEDULE[_auction]!.end, "HH.mm").day(5).unix();
  } else {
    let endOfMonth = today.endOf("month");
    endOfMonth =
      endOfMonth.day() < 5
        ? endOfMonth.subtract(1, "week").day(5)
        : endOfMonth.day(5);
    start = moment
      .utc(SCHEDULE[_auction]!.start, "HH.mm")
      .month(endOfMonth.month())
      .date(endOfMonth.date())
      .unix();
    end = moment
      .utc(SCHEDULE[_auction]!.end, "HH.mm")
      .month(endOfMonth.month())
      .date(endOfMonth.date())
      .unix();
  }

  return [start, end];
}
