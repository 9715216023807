const csvDownload = (
  data: any[],
  name: string = `export.csv`,
  delimiter: string = `,`
) => {
  const items = data;
  const filename = name;
  const d = delimiter;

  const header: any[] = Array.from(
    new Set(items.reduce((r, e) => [...r, ...Object.keys(e)], []))
  );
  let csv = items.map((row) =>
    header.map((fieldName) => JSON.stringify(row[fieldName] || "")).join(d)
  );
  csv.unshift(header.join(d));
  const csvString = csv.join("\r\n");

  const blob = new Blob([csvString], {
    type: "text/plain;charset=utf-8",
  });

  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = filename;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default csvDownload;
