export const AuctionGraphql = () => {
  return `auctions: auctions (first: 1000) {
      id
      option {
        id
        name
        symbol
        decimals
        expiry
        strike
        underlying {
          id
          name
          symbol
          decimals
        }
        put
      }
      bidding {
        id
        name
        symbol
        decimals
      }
      minimum
      size
      start
      end
      filled
      clearing
      live
    }`;
};

export const BidsGraphql = (auctionId?: string) => {
  return `bids: bids ${
    auctionId ? `(where: {auction: ${auctionId}})` : `(skip: 500, first: 1000)`
  } {
      id
      index
      auction
      account {
        id
        address
      }
      size
      payable
      createtx
      claimtx
      canceltx
      bytes
    }`;
};

export default AuctionGraphql;
