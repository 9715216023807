import styled from "styled-components";
import HeaderLogo from "./HeaderLogo";
import AccountStatus from "../Wallet/AccountStatus";
import theme from "../../design/theme";
import NetworkSwitcherButton from "../NetworkSwitcher/NetworkSwitcherButton";
import { BaseLink, Title } from "../../design";
import { useRouteMatch } from "react-router-dom";
import { useLastUpdated } from "../../hooks/subgraphDataContext";
import moment from "moment";
import { useMemo } from "react";
import colors from "../../design/colors";
import useTextAnimation from "../../hooks/useTextAnimation";

export interface NavItemProps {
  isSelected: boolean;
}

export interface MobileMenuOpenProps {
  isMenuOpen: boolean;
}

const HeaderContainer = styled.div<MobileMenuOpenProps>`
  height: ${theme.header.height}px;
  top: 0;

  z-index: -1000;
`;

const LogoContainer = styled.div``;

const HeaderButtonContainer = styled.div`
  display: flex;
  margin-right: 8px;
`;

const LinksContainer = styled.div`
  display: flex;
  margin-left: 28px;
  margin-right: auto;
`;

const NavItem = styled.div<NavItemProps>`
  display: flex;
  align-items: center;
  padding: 0px 28px;
  height: 100%;
  opacity: ${(props) => (props.isSelected ? "1" : "0.48")};

  &:hover {
    opacity: ${(props) => (props.isSelected ? theme.hover.opacity : "1")};
  }
`;

const NavLinkText = styled(Title)`
  letter-spacing: 1.5px;
  font-size: 12px;
  line-height: 20px;
  color: black;
`;

const UpdateStatusContainer = styled.div`
  font-size: 12px;
  font-family: VCR;
  color: ${colors.green};
  margin-left: auto;
  margin-right: 15px;
`;

const Header = () => {
  const auctions = useRouteMatch({ path: "/", exact: true });
  const approval = useRouteMatch({ path: "/approval", exact: true });
  const { lastUpdated, loading } = useLastUpdated();

  const renderLastUpdated = useMemo(() => {
    return moment.unix(lastUpdated).utc().format("HH:mm:ss UTC").toUpperCase();
  }, [lastUpdated]);

  const loadingText = useTextAnimation();

  const renderLinkItem = (
    title: string,
    to: string,
    isSelected: boolean,
    external: boolean = false
  ) => {
    return (
      <BaseLink
        to={to}
        target={external ? "_blank" : undefined}
        rel={external ? "noreferrer noopener" : undefined}
        onClick={() => {}}
      >
        <NavItem isSelected={isSelected}>
          <NavLinkText>{title}</NavLinkText>
        </NavItem>
      </BaseLink>
    );
  };

  return (
    <HeaderContainer isMenuOpen={false} className="d-flex align-items-center">
      {/* LOGO */}
      <LogoContainer>
        <HeaderLogo />
      </LogoContainer>

      {/* LINKS */}
      <LinksContainer>
        {renderLinkItem("AUCTIONS", "/", Boolean(auctions))}
        {renderLinkItem("TOKEN APPROVAL", "/approval", Boolean(approval))}
      </LinksContainer>

      <UpdateStatusContainer>
        LAST UPDATED: {loading ? loadingText.toUpperCase() : renderLastUpdated}
      </UpdateStatusContainer>

      <HeaderButtonContainer>
        <NetworkSwitcherButton />
      </HeaderButtonContainer>

      <AccountStatus variant="desktop" />
    </HeaderContainer>
  );
};

export default Header;
