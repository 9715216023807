import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import theme from "../../design/theme";
import useAuction from "../../hooks/useAuction";
import { AugmentedAuctionData, AugmentedBidData } from "../../models/auction";
import { impersonateAddress } from "../../utils/development";
import { decodeOrder } from "../../utils/order";
import { BigNumber, ethers } from "ethers";
import { evaluateBids, findClearingPrice } from "../../utils/calculations";
import useTextAnimation from "../../hooks/useTextAnimation";
import { useWeb3Context } from "../../hooks/web3Context";
import { formatNumber } from "../../utils/text";
import moment from "moment";
import { switchChains } from "../../utils/switch";
import { CHAINID } from "../../utils/env";
import { NETWORK_ALT_DESCRIPTION } from "../../constants/constants";

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const WrongNetworkDescriptionContainer = styled.div`
  font-size: 14px;
  height: 130px;
  display: flex;
  color: #8e8e8e;
  justify-content: center;
  align-items: center;
`;

const ChangeNetworkButton = styled.button`
  font-family: VCR;
  font-size: 15px;
  color: #ffffff;
  background-color: #424242;
  border-radius: 5px;
  border: none;
  padding: 9px 20px 10px 20px;
  line-height: 20px;
  vertical-align: text-top;

  &:hover {
    opacity: ${theme.hover.opacity};
  }
`;

const WrongNetworkText = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  margin-bottom: 5px;
`;

const Description = styled.div`
  font-family: VCR;
  font-size: 14px;
  display: flex;
  color: #8e8e8e;
  height: 70px;
  justify-content: center;
  align-items: center;
`;

const ClaimButton = styled.button<{ color: string }>`
  font-family: VCR;
  font-size: 15px;
  color: ${(props) => props.color};
  background-color: #424242;
  border-radius: 5px;
  border: none;
  padding: 9px 20px 10px 20px;
  line-height: 20px;
  vertical-align: text-top;
  height: 41px;
  width: 100%;

  &:hover {
    opacity: ${theme.hover.opacity};
  }

  &:disabled {
    opacity: ${theme.hover.opacity};
  }
`;

const BiddingModal = styled.div`
  display: block;
  width: 400px;
`;

const Caption = styled.div`
  font-family: VCR;
  text-align: center;
  margin-bottom: 13px;
  color: #464646;
  font-size: 20px;
  line-height: 16px;
  font-weight: 600;
`;

const InputBlock = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding: 25px 30px 30px 30px;

  & > * {
    &:nth-last-child(2) {
      margin-top: 25px;
    }
  }
`;

const InputCaption = styled.div`
  display: flex;
  font-family: VCR;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 5px;
  color: #464646;
  font-size: 14px;
  font-weight: 600;
`;

const Input = styled.input<{ green: Boolean }>`
  font-family: VCR;
  border-radius: 5px;
  border: none;
  color: ${(props) => {
    return props.green ? "#167a6e" : "#00000080";
  }};
  width: 100%;
  height: 50px;
  padding: 10px;
  font-size: 24px;
  text-align: center;

  &:disabled {
    background-color: ${(props) => {
      return props.green ? "#16CEB9" : "black";
    }}20;
  }

  &::placeholder {
    opacity: 0.2;
  }
`;

const SettlementDescriptionContainer = styled.div`
  font-size: 14px;
  display: flex;
  height: 140px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

const ClaimModule: React.FC<{
  auctionData: AugmentedAuctionData;
  bidData: AugmentedBidData[];
}> = ({ auctionData, bidData }) => {
  const { provider } = useWeb3Context();
  const { account: acc, chainId: currentChainId, library } = useWeb3React();
  const account = impersonateAddress ? impersonateAddress : acc;

  const [settling, setSettling] = useState(false);

  const timeLive = useMemo(() => {
    return Number(auctionData?.end) > Number(moment().unix());
  }, [auctionData]);

  const subgraphLive = useMemo(() => {
    return auctionData?.live;
  }, [auctionData]);

  useEffect(() => {
    setSettling(!timeLive && subgraphLive);
  }, [timeLive, subgraphLive]);

  const settlementText = useTextAnimation(settling, {
    texts: [
      "Auction settlement in progress",
      "Auction settlement in progress.",
      "Auction settlement in progress..",
      "Auction settlement in progress...",
    ],
    interval: 250,
  });

  const gnosisContract = useAuction(false);

  let clearingPrice: BigNumber = BigNumber.from(0);
  try {
    const clearingOrder = decodeOrder(auctionData.clearing);
    clearingPrice = clearingOrder.sellAmount
      .mul(10 ** 8)
      .div(clearingOrder.buyAmount);
  } catch {
    const clearingOrder = findClearingPrice(auctionData, bidData);
    clearingPrice = BigNumber.from(clearingOrder.clearing);
  }

  const bidsWithPrice = bidData.map((value) => {
    const implied_price = BigNumber.from(value.payable)
      .mul(10 ** 8)
      .div(value.size);
    const win = implied_price >= clearingPrice;
    return { ...value, win: win, price: implied_price };
  });

  bidsWithPrice.sort((a, b) => {
    return parseInt(ethers.utils.formatUnits(b.price.sub(a.price), 8));
  });

  const [waitingClaim, setWaitingClaim] = useState(false);
  const claimLoadingText = useTextAnimation(waitingClaim, {
    texts: ["CLAIMING", "CLAIMING.", "CLAIMING..", "CLAIMING..."],
    interval: 250,
  });

  const winningBids = evaluateBids(
    auctionData,
    bidData,
    clearingPrice.toString()
  );

  const accountBids = winningBids.bids.filter((value) => {
    return value.account.address === account?.toLowerCase();
  });

  const unclaimedBids = accountBids.filter((value) => {
    return !value.claimtx && !value.canceltx;
  });

  const oTokenClaimable = accountBids.reduce(
    (sum: BigNumber, a) => sum.add(a.allocation),
    BigNumber.from(0)
  );
  const biddingClaimable = accountBids.reduce(
    (sum: BigNumber, a) => sum.add(a.reimburse),
    BigNumber.from(0)
  );

  const enable = accountBids.length > 0;
  const claimed = unclaimedBids.length === 0;

  const handleClaimOrder = useCallback(async () => {
    if (gnosisContract) {
      setWaitingClaim(true);
      const bidBytes = unclaimedBids.map((value) => {
        return value.bytes;
      });
      try {
        const tx = await gnosisContract.claimFromParticipantOrder(
          auctionData.id.split("_")[0],
          bidBytes
        );
        await provider.waitForTransaction(tx.hash, 5);
      } catch {
      } finally {
        setWaitingClaim(false);
      }
    }
  }, [gnosisContract, unclaimedBids, auctionData.id, provider]);

  const handleSwitchChain = useCallback(
    async (chainId: number) => {
      if (library && currentChainId !== chainId) {
        await switchChains(library, chainId);
      }
    },
    [library, currentChainId]
  );

  return enable && !settling && currentChainId === auctionData.chainId ? (
    <BiddingModal>
      <Caption>CLAIM</Caption>
      <InputBlock>
        <InputCaption>OTOKEN WON</InputCaption>
        <Input
          disabled={true}
          green={!claimed && !oTokenClaimable.isZero()}
          type="text"
          className="form-control"
          aria-label="payable"
          value={formatNumber(ethers.utils.formatUnits(oTokenClaimable, 8))}
          placeholder="0"
        ></Input>
        <InputCaption>{auctionData.bidding.symbol} REFUND</InputCaption>
        <Input
          disabled={true}
          green={!claimed && !biddingClaimable.isZero()}
          type="text"
          className="form-control"
          aria-label="payable"
          value={formatNumber(
            ethers.utils.formatUnits(
              biddingClaimable,
              auctionData.bidding.decimals
            )
          )}
          placeholder="0"
        ></Input>
      </InputBlock>
      <ButtonContainer>
        <ClaimButton
          color={claimed ? "#16CEB9" : "#FFFFFF"}
          disabled={claimed || waitingClaim}
          onClick={handleClaimOrder}
        >
          {claimed ? "CLAIMED" : waitingClaim ? claimLoadingText : "CLAIM"}
        </ClaimButton>
      </ButtonContainer>
    </BiddingModal>
  ) : (
    <BiddingModal>
      <Caption>CLAIM</Caption>
      <InputBlock>
        <Description>
          {settling ? (
            <SettlementDescriptionContainer>
              {settlementText}
            </SettlementDescriptionContainer>
          ) : acc ? (
            currentChainId === auctionData.chainId ? (
              "NO BIDS TO CLAIM"
            ) : (
              <WrongNetworkDescriptionContainer>
                <div>
                  <WrongNetworkText>Wrong Network</WrongNetworkText>
                  <ChangeNetworkButton
                    onClick={() =>
                      handleSwitchChain(auctionData.chainId as number)
                    }
                  >
                    CONNECT TO{" "}
                    {NETWORK_ALT_DESCRIPTION[auctionData.chainId as CHAINID]}
                  </ChangeNetworkButton>
                </div>
              </WrongNetworkDescriptionContainer>
            )
          ) : (
            "CONNECT WALLET"
          )}
        </Description>
      </InputBlock>
    </BiddingModal>
  );
};

export default ClaimModule;
