import React from "react";
import styled from "styled-components";

import { SecondaryText } from "../../design";
import colors from "../../design/colors";
import theme from "../../design/theme";

const HelpContainer = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
  margin-top: 1px;
  border: 1.8px ${theme.border.style}
    ${(props) => (props.color ? props.color : colors.borderLight)};
  border-radius: 100px;
  margin-left: 4px;
  z-index: 1;
  text-transform: none;
`;

interface HelpInfoProps {
  containerRef: React.Ref<any>;
  color?: string;
}

const HelpInfo: React.FC<
  React.HTMLAttributes<HTMLDivElement> & HelpInfoProps
> = ({ children, containerRef, color, ...props }) => {
  return (
    <HelpContainer color={color} {...props} ref={containerRef}>
      <SecondaryText fontSize={9} lineHeight={12} color={color || colors.text}>
        {children}
      </SecondaryText>
    </HelpContainer>
  );
};

export default HelpInfo;
