import { BigNumber } from "ethers";
import { AuctionData, BidData, ProcessedBidData } from "../models/auction";

export function findClearingPrice(
  auctionData: AuctionData,
  bidData: BidData[]
) {
  // Source: EasyAuction.sol
  // https://github.com/gnosis/ido-contracts/blob/main/contracts/EasyAuction.sol
  const minAuctionedBuyAmount = BigNumber.from(auctionData.minimum);
  const fullAuctionedAmount = BigNumber.from(auctionData.size);
  const validOrderedBids = bidData
    .sort((a, b) => {
      return smallerThan(a, b) ? -1 : 1;
    })
    .filter((value) => !value.canceltx);

  let i = 0;
  let currentBidSum = BigNumber.from(0);
  let buyAmountOfIter = BigNumber.from(0);
  let sellAmountOfIter = BigNumber.from(0);
  let fillVolumeOfAuctioneerOrder = fullAuctionedAmount;
  let clearingOrder: {
    buyAmount: BigNumber;
    sellAmount: BigNumber;
  };

  do {
    if (i === validOrderedBids.length) {
      break;
    }
    const currentOrder = validOrderedBids[i];

    buyAmountOfIter = BigNumber.from(currentOrder.size);
    sellAmountOfIter = BigNumber.from(currentOrder.payable);
    currentBidSum = currentBidSum.add(sellAmountOfIter);
    i += 1;
  } while (
    currentBidSum
      .mul(buyAmountOfIter)
      .lt(fullAuctionedAmount.mul(sellAmountOfIter))
  );

  if (
    currentBidSum.gt(0) &&
    currentBidSum
      .mul(buyAmountOfIter)
      .gte(fullAuctionedAmount.mul(sellAmountOfIter))
  ) {
    // All considered/summed orders are sufficient to close the auction fully
    // at price between current and previous orders.
    const uncoveredBids = currentBidSum.sub(
      fullAuctionedAmount.mul(sellAmountOfIter).div(buyAmountOfIter)
    );

    if (sellAmountOfIter.gte(uncoveredBids)) {
      //[13]
      // Auction fully filled via partial match of currentOrder
      // const sellAmountClearingOrder =
      //     sellAmountOfIter.sub(uncoveredBids);

      currentBidSum = currentBidSum.sub(uncoveredBids);
      clearingOrder = {
        buyAmount: buyAmountOfIter,
        sellAmount: sellAmountOfIter,
      };
    } else {
      //[14]
      // Auction fully filled via price strictly between currentOrder and the order
      // immediately before. For a proof, see the security-considerations.md
      currentBidSum = currentBidSum.sub(sellAmountOfIter);
      clearingOrder = {
        buyAmount: fullAuctionedAmount,
        sellAmount: currentBidSum,
      };
    }
  } else {
    // All considered/summed orders are not sufficient to close the auction fully at price of last order //[18]
    // Either a higher price must be used or auction is only partially filled

    if (currentBidSum.gt(minAuctionedBuyAmount)) {
      //[15]
      // Price higher than last order would fill the auction
      clearingOrder = {
        buyAmount: fullAuctionedAmount,
        sellAmount: currentBidSum,
      };
    } else {
      //[16]
      // Even at the initial auction price, the auction is partially filled
      clearingOrder = {
        buyAmount: fullAuctionedAmount,
        sellAmount: minAuctionedBuyAmount,
      };

      fillVolumeOfAuctioneerOrder = currentBidSum
        .mul(fullAuctionedAmount)
        .div(minAuctionedBuyAmount);
    }
  }

  return {
    filled: fillVolumeOfAuctioneerOrder,
    clearing: clearingOrder.sellAmount
      .mul(10 ** 8)
      .div(clearingOrder.buyAmount)
      .toString(),
  };
}

export function smallerThan(orderLeft: BidData, orderRight: BidData) {
  // Source: IterableOrderedOrderSet.sol
  // https://github.com/gnosis/ido-contracts/blob/main/contracts/libraries/IterableOrderedOrderSet.sol
  const userIdLeft = Number(orderLeft.account.id);
  const priceNumeratorLeft = BigNumber.from(orderLeft.size);
  const priceDenominatorLeft = BigNumber.from(orderLeft.payable);
  const userIdRight = Number(orderRight.account.id);
  const priceNumeratorRight = BigNumber.from(orderRight.size);
  const priceDenominatorRight = BigNumber.from(orderRight.payable);

  if (
    priceNumeratorLeft
      .mul(priceDenominatorRight)
      .lt(priceNumeratorRight.mul(priceDenominatorLeft))
  )
    return true;
  if (
    priceNumeratorLeft
      .mul(priceDenominatorRight)
      .gt(priceNumeratorRight.mul(priceDenominatorLeft))
  )
    return false;

  if (priceNumeratorLeft.lt(priceNumeratorRight)) return true;
  if (priceNumeratorLeft.gt(priceNumeratorRight)) return false;

  if (userIdLeft < userIdRight) {
    return true;
  }
  return false;
}

export function evaluateBids(
  auctionData: AuctionData,
  bidData: BidData[],
  clearing: string
) {
  const size = BigNumber.from(auctionData.size);
  const clearingPrice = BigNumber.from(clearing);
  let remainingSize = size;

  const orderedBids = bidData.sort((a, b) => {
    return smallerThan(a, b) ? -1 : 1;
  });

  const adjusted: ProcessedBidData[] = orderedBids.map((value) => {
    const price = BigNumber.from(value.payable)
      .mul(10 ** 8)
      .div(value.size);

    const newSize = clearingPrice.lte(price)
      ? BigNumber.from(value.payable)
          .mul(10 ** 8)
          .div(clearingPrice)
      : BigNumber.from(0);

    const finalOtokenSize = value.canceltx
      ? BigNumber.from(0)
      : newSize.lt(remainingSize)
      ? newSize
      : remainingSize;

    remainingSize = remainingSize.sub(finalOtokenSize);

    const reimburse = finalOtokenSize.isZero()
      ? BigNumber.from(value.payable)
      : BigNumber.from(value.payable)
          .mul(10 ** 8)
          .div(clearingPrice)
          .sub(finalOtokenSize)
          .mul(clearingPrice)
          .div(10 ** 8);

    return {
      ...value,
      price: price,
      allocation: finalOtokenSize.toString(),
      reimburse: reimburse.toString(),
    };
  });

  return {
    filled: size.sub(remainingSize),
    clearing: clearing,
    bids: adjusted,
  };
}
