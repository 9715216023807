import { useCallback, useEffect, useState } from "react";
import { BigNumber } from "ethers";

import { useWeb3React } from "@web3-react/core";
import { impersonateAddress } from "../utils/development";
import { getERC20Token } from "./useERC20Token";
import { CHAINID, isDevelopment, isProduction } from "../utils/env";
import { usePendingTransactions } from "./pendingTransactionsContext";
import {
  Assets,
  AssetsList,
  getAssetChain,
  getAssetNetwork,
} from "../utils/asset";
import {
  getGnosisAuction,
  getSwapContract,
  getREarnContract,
  getREarnstEthContract,
} from "../constants/constants";
import { useWeb3Context } from "./web3Context";
import { AuctionContracts } from "../utils/auction";

const FullBalances = [...AssetsList, "native"];

export type AssetsAndNative = typeof FullBalances[number];

export type UserAssetBalanceResponses = {
  [asset in AssetsAndNative]: {
    balance: BigNumber;
    allowance: Record<AuctionContracts, BigNumber>;
  };
};
export type UserAssetBalanceData = {
  data: UserAssetBalanceResponses;
  loading: boolean;
};
export const defaultUserAssetBalanceData: UserAssetBalanceData = {
  data: Object.fromEntries(
    AssetsList.map((asset) => [
      asset,
      {
        balance: BigNumber.from(0),
        allowance: {
          GNOSIS: BigNumber.from(0),
          PARADIGM: BigNumber.from(0),
          "R-EARN": BigNumber.from(0),
          "R-EARN-STETH": BigNumber.from(0),
        },
      },
    ])
  ) as UserAssetBalanceResponses,
  loading: true,
};

const useFetchAssetBalanceData = (
  {
    poll,
    pollingFrequency,
  }: {
    poll: boolean;
    pollingFrequency: number;
  } = { poll: true, pollingFrequency: 20000 }
) => {
  const [data, setData] = useState<UserAssetBalanceData>(
    defaultUserAssetBalanceData
  );
  const { library, chainId, active, account: web3Account } = useWeb3React();
  const { provider: avaxProvider } = useWeb3Context(
    isDevelopment() ? CHAINID.AVAX_FUJI : CHAINID.AVAX_MAINNET
  );
  const { provider: binanceProvider } = useWeb3Context(CHAINID.BINANCE_MAINNET);
  const { provider: ethProvider } = useWeb3Context(
    isDevelopment() ? CHAINID.ETH_KOVAN : CHAINID.ETH_MAINNET
  );
  const account = impersonateAddress ? impersonateAddress : web3Account;
  const { transactionsCounter } = usePendingTransactions();

  const doMulticall = useCallback(async () => {
    if (!isProduction()) {
      console.time("Asset Balance Data Fetch");
    }

    if (!active || !chainId) {
      setData({ ...defaultUserAssetBalanceData, loading: true });
      return;
    }

    let loading = true;
    const responsesPartial = await Promise.all(
      AssetsList.map(async (asset) => {
        const network =
          getAssetNetwork(asset) === "eth"
            ? ethProvider
            : getAssetNetwork(asset) === "binance"
            ? binanceProvider
            : avaxProvider;

        const chainId = getAssetChain(asset);

        const swapAddress = getSwapContract(chainId);
        const gnosisAddress = getGnosisAuction(chainId);
        const rEarnAddress = getREarnContract(chainId);
        const rEarnstEthAddress = getREarnstEthContract(chainId);

        const token = getERC20Token(
          network,
          asset.toLowerCase() as Assets,
          chainId,
          false
        );

        if (!token) {
          return { asset, balance: undefined, allowance: undefined };
        }

        const balance = await token.balanceOf(account!);

        const swapAllowance =
          swapAddress !== ""
            ? await token.allowance(account!, swapAddress)
            : BigNumber.from(0);
        const gnosisAllowance =
          gnosisAddress !== ""
            ? await token.allowance(account!, gnosisAddress)
            : BigNumber.from(0);
        const rEarnAllowance =
          rEarnAddress !== ""
            ? await token.allowance(account!, rEarnAddress)
            : BigNumber.from(0);
        const rEarnstEthAllowance =
          rEarnstEthAddress !== ""
            ? await token.allowance(account!, rEarnstEthAddress)
            : BigNumber.from(0);

        loading = false;
        return {
          asset,
          balance,
          allowance: {
            GNOSIS: gnosisAllowance,
            PARADIGM: swapAllowance,
            "R-EARN": rEarnAllowance,
            "R-EARN-STETH": rEarnstEthAllowance,
          },
        };
      })
    );

    const nativeBalance = await library.getBalance(account!);

    const responses = [
      ...responsesPartial,
      {
        asset: "native",
        balance: nativeBalance,
        allowance: {
          GNOSIS: BigNumber.from(0),
          PARADIGM: BigNumber.from(0),
          "R-EARN": BigNumber.from(0),
          "R-EARN-STETH": BigNumber.from(0),
        },
      },
    ];

    setData({
      data: Object.fromEntries(
        responses.map((response) => [
          response.asset,
          { balance: response.balance, allowance: response.allowance },
        ])
      ) as UserAssetBalanceResponses,
      loading: loading,
    });

    if (!isProduction()) {
      console.timeEnd("Asset Balance Data Fetch");
    }
  }, [active, chainId, library, account, ethProvider, binanceProvider, avaxProvider]);

  /**
   * Fetch on first load and transaction success
   */
  useEffect(() => {
    doMulticall();
  }, [doMulticall, transactionsCounter]);

  /**
   * Schedule polling
   * We still need polling as user might perform transaction that changes their asset balance
   * (buy/sell) outside of app
   */
  useEffect(() => {
    let pollInterval: any = undefined;

    if (poll) {
      pollInterval = setInterval(doMulticall, pollingFrequency);
    }

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [doMulticall, poll, pollingFrequency]);

  return data;
};

export default useFetchAssetBalanceData;
