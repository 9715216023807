import { useCallback } from "react";
import styled from "styled-components";
import theme from "../../design/theme";
import useAuction from "../../hooks/useAuction";

const AdminFunctionContainer = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 7px;
  border: solid 2px #fc0a54;
  padding: 10px;
  min-height: 100px;
  margin-top: -18px;
  margin-bottom: 40px;
`;

const AdminFunctionDescriptionContainer = styled.div`
  font-size: 14px;
  display: flex;
  color: #8e8e8e;
  justify-content: center;
  align-items: center;
`;

const AdminText = styled.div`
  font-family: VCR;
  font-size: 20px;
  color: #fc0a54;
  margin-top: -5px;
  margin-left: 5px;
  font-weight: 700px;
`;

const SettleButton = styled.button`
  font-family: VCR;
  font-size: 15px;
  color: #fc0a54;
  background-color: #424242;
  border-radius: 5px;
  border: none;
  padding: 9px 20px 10px 20px;
  line-height: 20px;
  vertical-align: text-top;
  margin-top: -9px;

  &:hover {
    opacity: ${theme.hover.opacity};
  }
`;

const SettleModule: React.FC<{
  auctionId: string;
}> = ({ auctionId }) => {
  const gnosisContract = useAuction(false);

  const handleSettleAuction = useCallback(async () => {
    if (gnosisContract) {
      try {
        await gnosisContract.settleAuction(auctionId);
      } catch {}
    }
  }, [gnosisContract, auctionId]);

  return (
    <AdminFunctionContainer>
      <AdminText>ADMIN FUNCTION</AdminText>
      <AdminFunctionDescriptionContainer>
        <div>
          <SettleButton onClick={() => handleSettleAuction()}>
            SETTLE AUCTION
          </SettleButton>
        </div>
      </AdminFunctionDescriptionContainer>
    </AdminFunctionContainer>
  );
};

export default SettleModule;
