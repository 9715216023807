/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from "ethers";
import { Provider } from "@ethersproject/providers";

import type { StrikeSelection } from "./StrikeSelection";

export class StrikeSelectionFactory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): StrikeSelection {
    return new Contract(address, _abi, signerOrProvider) as StrikeSelection;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_optionsPremiumPricer",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_delta",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_step",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "oldDelta",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newDelta",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "DeltaSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "oldStep",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newStep",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "StepSet",
    type: "event",
  },
  {
    inputs: [],
    name: "delta",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "expiryTimestamp",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "isPut",
        type: "bool",
      },
    ],
    name: "getStrikePrice",
    outputs: [
      {
        internalType: "uint256",
        name: "newStrikePrice",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "newDelta",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "optionsPremiumPricer",
    outputs: [
      {
        internalType: "contract IOptionsPremiumPricer",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "newDelta",
        type: "uint256",
      },
    ],
    name: "setDelta",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "newStep",
        type: "uint256",
      },
    ],
    name: "setStep",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "step",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "volatilityOracle",
    outputs: [
      {
        internalType: "contract IVolatilityOracle",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
