import React from "react";
import styled, { StyledComponent } from "styled-components";
import {
  AAVELogo,
  UNILogo,
  STETHLogo,
  USDCLogo,
  WBTCLogo,
  WETHLogo,
  WAVAXLogo,
  YVUSDcLogo,
  PERPLogo,
  SAVAXLogo,
  APELogo,
  SolanaLogo,
  RETHLogo,
  BALLogo,
  BNBLogo,
} from "../assets/icons/tokens";
import colors from "../design/colors";
import { CHAINID, isDevelopment } from "./env";

export const AssetsList = [
  "AAVE",
  "UNI",
  "WAVAX",
  "WETH",
  "USDC",
  "WBTC",
  "stETH",
  "yvUSDC",
  "wstETH",
  "PERP",
  "sAVAX",
  "USDC.e",
  "APE",
  "WBNB",
  "SOL",
  "rETH",
  "BAL",
  "SPELL",
  "BADGER",
  "SAMB",
] as const;

export type Assets = typeof AssetsList[number];

export const getAssetNetwork = (asset: Assets): string => {
  switch (asset) {
    case "USDC":
    case "yvUSDC":
    case "WBTC":
    case "AAVE":
    case "UNI":
    case "PERP":
    case "APE":
    case "rETH":
    case "stETH":
    case "wstETH":
    case "WETH":
    case "BAL":
    case "SPELL":
    case "BADGER":
    case "SAMB":
      return "eth";
    case "USDC.e":
    case "sAVAX":
    case "WAVAX":
      return "avax";
    case "WBNB":
      return "binance";
    case "SOL":
      return "sol";
  }
};

export const getAssetChain = (asset: Assets): CHAINID => {
  switch (asset) {
    case "USDC":
    case "yvUSDC":
    case "WBTC":
    case "AAVE":
    case "UNI":
    case "PERP":
    case "APE":
    case "stETH":
    case "rETH":
    case "wstETH":
    case "WETH":
    case "BAL":
    case "SPELL":
    case "BADGER":
    case "SAMB":
      return isDevelopment() ? CHAINID.ETH_KOVAN : CHAINID.ETH_MAINNET;
    case "USDC.e":
    case "sAVAX":
    case "WAVAX":
      return isDevelopment() ? CHAINID.AVAX_FUJI : CHAINID.AVAX_MAINNET;
    case "WBNB":
      return CHAINID.BINANCE_MAINNET;
    case "SOL":
      return CHAINID.SOL;
  }
};

export const getAssetDisplay = (asset: Assets): string => {
  switch (asset) {
    case "WETH":
      return "ETH";
    case "WAVAX":
      return "AVAX";
    case "WBNB":
      return "BNB";
    default:
      return asset;
  }
};

export const getAssetDecimals = (asset: Assets): number => {
  switch (asset) {
    case "WBTC":
      return 8;
    case "USDC.e":
    case "USDC":
    case "yvUSDC":
      return 6;
    case "SOL":
      return 9;
    default:
      return 18;
  }
};

export const getDefaultSignificantDecimalsFromAssetDecimals = (
  decimals: number
) => {
  switch (decimals) {
    case 18:
      return 6;
    case 8:
      return 5;
    case 6:
    default:
      return 2;
  }
};

export const getAssetDefaultSignificantDecimals = (asset: Assets): number => {
  return getDefaultSignificantDecimalsFromAssetDecimals(
    getAssetDecimals(asset)
  );
};

export const getAssetColor = (asset: Assets): string => colors.asset[asset];

const ColoredWBTCLogo = styled(WBTCLogo)`
  width: 100%;
  && * {
    fill: ${colors.asset.WBTC};
  }
`;

const ColoredUSDCLogo = styled(USDCLogo)<{ backgroundColor?: string }>`
  margin: -8px;
  width: 100%;

  && .background {
    fill: ${(props) =>
      props.backgroundColor ? props.backgroundColor : `${colors.asset.USDC}29`};
  }

  && .content {
    fill: ${colors.asset.USDC};
  }
`;

const ColoredYVUSDCLogo = styled(YVUSDcLogo)<{ backgroundColor?: string }>`
  margin: -8px;
  width: 100%;

  && .background {
    fill: ${(props) =>
      props.backgroundColor ? props.backgroundColor : `${colors.asset.USDC}29`};
  }

  && .content {
    fill: ${colors.asset.USDC};
  }
`;

export const LidoThemedETHLogo = styled(WETHLogo)`
  path {
    fill: ${colors.asset.stETH};
  }
`;

export const getAssetLogoSize = (asset: Assets, type: string) => {
  switch (asset) {
    case "USDC":
    case "USDC.e":
    case "yvUSDC":
    case "WBTC":
    case "AAVE":
    case "UNI":
    case "PERP":
    case "APE":
    case "SOL":
    case "rETH":
      switch (type) {
        case "xs":
          return "15px";
        case "s":
          return "25px";
        case "m":
          return "40px";
        default:
          return "90px";
      }
    case "sAVAX":
    case "stETH":
    case "wstETH":
    case "WETH":
    case "WAVAX":
      switch (type) {
        case "xs":
          return "10px";
        case "s":
          return "17.5px";
        case "m":
          return "30px";
        default:
          return "70px";
      }
  }
};

export const getAssetLogo: (asset: Assets) =>
  | StyledComponent<
      React.FC<React.SVGAttributes<SVGElement>>,
      any,
      { backgroundColor?: string },
      never
    >
  | React.FC<React.SVGAttributes<SVGElement>>
  | React.FC<
      React.SVGAttributes<SVGElement> & {
        markerConfig?: {
          height?: number;
          width?: number;
          right?: string;
          bottom?: string;
          border?: string;
        };
      }
    >
  | React.FC<React.SVGAttributes<SVGElement> & { showBackground?: boolean }> = (
  asset
) => {
  switch (asset) {
    case "USDC.e":
    case "USDC":
      return ColoredUSDCLogo;
    case "yvUSDC":
      return ColoredYVUSDCLogo;
    case "WBTC":
      return ColoredWBTCLogo;
    case "rETH":
      return RETHLogo;
    case "WETH":
      return WETHLogo;
    case "stETH":
      return STETHLogo;
    case "wstETH":
      return STETHLogo;
    case "AAVE":
      return AAVELogo;
    case "UNI":
      return UNILogo;
    case "WAVAX":
      return WAVAXLogo;
    case "PERP":
      return PERPLogo;
    case "sAVAX":
      return SAVAXLogo;
    case "APE":
      return APELogo;
    case "WBNB":
      return BNBLogo;
    case "SOL":
      return SolanaLogo;
    case "BAL":
      return BALLogo;
    case "SPELL":
      return BALLogo;
    case "BADGER":
      return BALLogo;
    case "SAMB":
      return BALLogo;
  }
};
