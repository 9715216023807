import { createGlobalState } from "react-hooks-global-state";
import { PendingTransaction } from "./types";
import { Assets } from "../utils/asset";
import { AuctionContracts } from "../utils/auction";

interface GlobalStore {
  pendingTransactions: PendingTransaction[];
  showConnectWallet: boolean;
  gasPrice: string;
  auctionId: string;
  refreshTime: number;
  page: number;
}

export const initialState: GlobalStore = {
  pendingTransactions: [],
  showConnectWallet: false,
  gasPrice: "",
  auctionId: "",
  refreshTime: 0,
  page: 1,
};

interface BidGlobalStore {
  bidActionForm: {
    auctionId: string;
    quantity: string;
    price: string;
    payable: string;
    asset?: Assets;
    lastAction: string;
    prevAction?: string;
  };
}

export const initialBidActionForm = {
  auctionId: "",
  quantity: "",
  price: "",
  payable: "",
  asset: undefined,
  lastAction: "",
  prevAction: "",
};

export const initialFormState: BidGlobalStore = {
  bidActionForm: initialBidActionForm,
};

interface ApprovalGlobalStore {
  approvalForm: {
    auctionContract: AuctionContracts | undefined;
    asset: Assets | undefined;
    delegate: string | undefined;
  };
}

export const initialApprovalForm: ApprovalGlobalStore = {
  approvalForm: {
    auctionContract: undefined,
    asset: undefined,
    delegate: undefined,
  },
};

export const { useGlobalState: useWebappGlobalState } =
  createGlobalState(initialFormState);

export const { useGlobalState } = createGlobalState(initialState);

export const { useGlobalState: useApprovalGlobalState } =
  createGlobalState(initialApprovalForm);
