import { RibbonEarnVaultFactory } from "../codegen/RibbonEarnVaultFactory";
import deployments from "../constants/deployments.json";

export const getRibbonEarnContract = (library: any) => {
  const address = deployments.mainnet.RibbonEarnContract;

  if (library && address) {
    const provider = library.getSigner();
    return RibbonEarnVaultFactory.connect(address, provider);
  }
  return undefined;
};
