import styled from "styled-components";
import { formatUnits } from "ethers/lib/utils";
import { BigNumber } from "ethers";
import { AugmentedAuctionData, AugmentedBidData } from "../../models/auction";
import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { BaseLink } from "../../design";
import { ExternalIcon } from "../../assets/icons/icons";
import { getEtherscanURI } from "../../constants/constants";
import { resolveAuctionData } from "../../utils/auction";
import { formatNumber } from "../../utils/text";

const EmptybidDataText = styled.div`
  font-family: VCR;
  font-size: 14px;
  display: flex;
  color: #8e8e8e;
  height: 100px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

const BidHistoryCaption = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-align: left;
`;

const BidHistoryLogCaption = styled.div<{ owned?: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.owned ? "#212529" : "#8D8D8D")};
  font-family: VCR;
  font-size: 12px;
  font-weight: 300;
`;

const BidHistoryTitle = styled.div<{ ratio: string }>`
  display: block;
  flex: ${(props) => props.ratio} 1 0;
  width: auto;
  padding: 15px 0px 15px 0px;
`;

const BidHistoryLogItem = styled.div<{ ratio: string }>`
  display: block;
  flex: ${(props) => props.ratio} 1 0;
  width: auto;
  padding: 0px 0px 0px 0px;
`;

const BidInformation = styled.div`
  display: block;
  flex: 1 1 0;
  max-width: 600px;
  overflow: hidden;
`;

const OuterDiv = styled.div`
  overflow: hidden;
  display: inline-block;
  border-radius: 5px 0px;
  width: 600px;
`;

const BidsList = styled.div`
  display: block;
  border-radius: 5px;
  max-height: 403px;
  overflow-y: overlay;
  padding-bottom: 5px;
  & > * {
      &:first-child {
        margin-top: 5px;
      }
    }
  }
`;

const BidHistoryBlock = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  max-height: 453px;
`;

const EmptyLink = styled.div`
  margin-left: 2px;
`;

const BidInformationCaption = styled.div`
  font-family: VCR;
  text-align: center;
  margin-bottom: 13px;
  color: #464646;
  font-size: 20px;
  line-height: 16px;
  font-weight: 600;
`;

const BidHistoryTitleColumn = styled.div`
  display: flex;
  border-bottom: solid 1px #d6d6d6;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
`;

const BidHistoryLogs = styled.div<{ highlight?: string }>`
  background-color: ${(props) =>
    props.highlight ? props.highlight + "32" : "none"};
  padding: 5px 0px;
  color: #8d8d8d;
  display: flex;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
`;

export const BidInformationPage: React.FC<{
  auctionData: AugmentedAuctionData;
  bidData: AugmentedBidData[];
}> = ({ auctionData, bidData }) => {
  const { account } = useWeb3React();

  const { biddingTokenColor } = resolveAuctionData(auctionData);

  const bids = useMemo(() => {
    const sortSize = bidData.sort((a, b) => {
      const first = BigNumber.from(a.size);
      const second = BigNumber.from(b.size);
      return parseFloat(
        formatUnits(second.sub(first), auctionData.bidding.decimals.toString())
      );
    });

    const sortPrice = sortSize.sort((a, b) => {
      const first = BigNumber.from(a.payable)
        .mul(10 ** 8)
        .div(a.size);
      const second = BigNumber.from(b.payable)
        .mul(10 ** 8)
        .div(b.size);
      return parseFloat(
        formatUnits(second.sub(first), auctionData.bidding.decimals.toString())
      );
    });

    return sortPrice;
  }, [bidData, auctionData.bidding.decimals]);

  const renderBid = (
    index: number,
    quantity: string,
    price: string,
    total: string,
    symbol: string,
    hash: string,
    accountOwned: boolean
  ) => {
    return (
      <BidHistoryLogs highlight={accountOwned ? biddingTokenColor : undefined}>
        <BidHistoryLogItem ratio="5">
          <BidHistoryLogCaption owned={accountOwned}>
            {index}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="10">
          <BidHistoryLogCaption owned={accountOwned}>
            {quantity}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="12">
          <BidHistoryLogCaption owned={accountOwned}>
            {price} {symbol}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="12">
          <BidHistoryLogCaption owned={accountOwned}>
            {total} {symbol}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="2">
          <BidHistoryLogCaption owned={accountOwned}>
            {accountOwned ? (
              <BaseLink
                to={
                  getEtherscanURI(auctionData.chainId as number) + "/tx/" + hash
                }
                target="_blank"
                rel="noreferrer noopener"
                className="w-100"
              >
                <ExternalIcon
                  color={accountOwned ? "#212529" : "#8D8D8D"}
                  height="15px"
                  width="15px"
                ></ExternalIcon>
              </BaseLink>
            ) : (
              <EmptyLink>-</EmptyLink>
            )}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
      </BidHistoryLogs>
    );
  };
  return (
    <BidInformation>
      <BidInformationCaption>BIDS</BidInformationCaption>
      <BidHistoryBlock>
        <BidHistoryTitleColumn>
          <BidHistoryTitle ratio="5">
            <BidHistoryCaption>RANK</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="10">
            <BidHistoryCaption>QUANTITY</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="12">
            <BidHistoryCaption>PRICE PER OTOKEN</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="12">
            <BidHistoryCaption>TOTAL PAYABLE</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="2">
            <BidHistoryCaption>TX</BidHistoryCaption>
          </BidHistoryTitle>
        </BidHistoryTitleColumn>
        <OuterDiv>
          <BidsList>
            {bids.length > 0 ? (
              bids.map((bid, index) => {
                const owned = bid.account.address === account?.toLowerCase();
                const quantity = formatNumber(
                  formatUnits(BigNumber.from(bid.size), 8)
                );
                const payable = formatNumber(
                  formatUnits(
                    BigNumber.from(bid.payable),
                    auctionData.bidding.decimals.toString()
                  )
                );
                const price = formatNumber(
                  formatUnits(
                    BigNumber.from(bid.payable)
                      .mul(10 ** 8)
                      .div(bid.size),
                    auctionData.bidding.decimals.toString()
                  )
                );

                return renderBid(
                  index + 1,
                  quantity,
                  price,
                  payable,
                  auctionData.bidding.symbol,
                  bid.createtx,
                  owned
                );
              })
            ) : (
              <EmptybidDataText>No bids submitted</EmptybidDataText>
            )}
          </BidsList>
        </OuterDiv>
      </BidHistoryBlock>
    </BidInformation>
  );
};

export default BidInformationPage;
