import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import AuctionInformation from "../../components/Auction/AuctionInformation";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import useTextAnimation from "../../hooks/useTextAnimation";
import useAuctionOption from "../../hooks/useAuctionOption";
import moment from "moment";
import {
  useAuctionData,
  useBidsData,
  useMultiAuctionsData,
} from "../../hooks/subgraphDataContext";
import {
  AuctionAddressMap,
  AuctionOptions,
  getAuctionChain,
  getRelevantAuction,
  isParadigmAuction,
  KEEPERS,
} from "../../constants/constants";
import BidModule from "../../components/Bid/BidModule";
import BidInformationPage from "../../components/Bid/BidInformation";
import ClaimModule from "../../components/Claim/ClaimModule";
import LiveIndicator from "../../components/Indicator/Live";
import { formatUnits } from "ethers/lib/utils";
import SettleModule from "../../components/Admin/SettleModule";
import {
  constructUpcomingAuction,
  getAuctionOption,
  isUpcomingAuction,
} from "../../utils/auction";
import {
  useAssetsPrice,
  useAssetsPriceHistory,
} from "../../hooks/fetchAssetPrice";
import AllBidsInformation from "../../components/Admin/AllBidsInformation";
import { useVaultData } from "../../hooks/web3DataContext";
import { Assets } from "../../utils/asset";
import { useGlobalState } from "../../store/store";
import { CHAINID } from "../../utils/env";
import theme from "../../design/theme";
import { BaseLink } from "../../design";
import { ExternalIcon } from "../../assets/icons/icons";

const StatusText = styled.span`
  font-size: 20px;
  font-weight: 300;
`;

const OuterContainer = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 7px;
  padding: 30px;
  min-height: 200px;
  margin-bottom: 40px;
`;

const Description = styled.div`
  font-family: VCR;
  font-size: 14px;
  display: flex;
  color: #8e8e8e;
  height: 140px;
  justify-content: center;
  align-items: center;
`;

const LoadingContainer = styled.div`
  font-family: VCR;
  text-transform: uppercase;
  font-size: 40px;
  display: flex;
  color: #8e8e8e;
  height: 300px;
  justify-content: center;
  align-items: center;
`;

const StatusTitle = styled.div`
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 500;
`;

const StatusTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
`;

const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3px;
  position: relative;
  width: 35px;
  height: 35px;
`;

const WalletModule = styled.div`
  display: flex;
  justify-content: center;
  z-index: 1000;

  & > * {
    margin-left: 30px;
    &:first-child {
      margin-left: unset;
    }
  }
`;

const ParadigmLabel = styled.div`
  display: flex;
  font-family: VCR;
  font-size: 12px;
  height: 25px;
  background-color: #4d6bf016;
  color: #4d6bf0;
  padding: 2px 6px;
  border-radius: 4px;
  align-items: center;
  margin-left: 5px;
`;

const ZetaflexLabel = styled.div`
  display: flex;
  font-family: VCR;
  font-size: 12px;
  height: 25px;
  background-color: #ce08fa16;
  color: #ce08fa;
  padding: 2px 6px;
  border-radius: 4px;
  align-items: center;
  margin-left: 5px;
`;

const ContractLinkButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ContractLinkButton = styled.button<{
  color: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: VCR;
  font-size: 15px;
  background-color: ${(props) => `${props.color}16`};
  color: ${(props) => `${props.color}`};
  border-radius: 10px;
  border: none;
  padding: 9px 20px 10px 20px;
  line-height: 20px;
  vertical-align: text-top;
  height: 41px;
  width: 100%;

  &:hover {
    opacity: ${theme.hover.opacity};
  }

  &:disabled {
    opacity: ${theme.hover.opacity};
  }
`;

const AuctionPage = () => {
  const { active, chainId: currentChainId, account } = useWeb3React();

  // Effect to refresh page every 2 seconds
  const time = moment().unix();

  // Get auction ID
  const [auctionId, setAuctionId] = useGlobalState("auctionId");
  const { auctionTitle } = useAuctionOption();

  const [underlying, , optionType] = auctionTitle!.split("-");
  const { data: similarAuctions, loading: similarAuctionLoading } =
    useMultiAuctionsData(auctionTitle!);

  useEffect(() => {
    if (similarAuctions[0] && !similarAuctionLoading) {
      setAuctionId(similarAuctions[0].id.split("_")[0]);
    } else {
      setAuctionId("");
    }
  }, [similarAuctions, similarAuctionLoading, setAuctionId]);

  const vault =
    underlying + "-" + (optionType.split("&")[0] === "C" ? "call" : "put");
  const auctionChain = getAuctionChain(vault as AuctionOptions);

  // Fetch data
  const { data: vaultData, loading: vaultLoading } = useVaultData();
  const { data: auctions, loading: auctionsLoading } = useAuctionData(
    auctionId!
  );

  const { data: bids, loading: bidsLoading } = useBidsData(
    Number(auctionId!) as number,
    auctionChain
  );
  const { prices } = useAssetsPrice();
  const priceHistory = useAssetsPriceHistory();

  const data = useMemo(() => {
    // We use the underlying asset to determine the chain from the auctions array
    // This is to prevent clashing existing auction Ids from the subgraph
    const underlyingAsset = getRelevantAuction(underlying as Assets)[0];
    const selectedAuction = auctions.find(
      (auction) =>
        auction?.chainId === AuctionAddressMap[underlyingAsset]?.chainId ||
        auction.bidding.name === underlying
    );
    return selectedAuction || auctions[0];
  }, [auctions, underlying]);

  const [starting, setStarting] = useState(false);

  // Determine whether auction is upcoming
  const upcomingAuctionName = isUpcomingAuction(auctionTitle!);
  const upcoming = useMemo(() => {
    return upcomingAuctionName && !data ? true : false;
  }, [upcomingAuctionName, data]);

  // Set auction data
  const auctionData = useMemo(() => {
    return upcoming && upcomingAuctionName
      ? constructUpcomingAuction(
          upcomingAuctionName as AuctionOptions,
          vaultData
        )
      : data;
  }, [upcoming, upcomingAuctionName, data, vaultData]);

  // Set live status
  const timeLive = useMemo(() => {
    return Number(auctionData?.end) > Number(moment().unix());
  }, [auctionData]);

  const subgraphLive = useMemo(() => {
    return auctionData?.live;
  }, [auctionData]);

  const live = useMemo(() => {
    return timeLive && subgraphLive;
  }, [timeLive, subgraphLive]);

  useEffect(() => {
    setStarting(upcoming && timeLive);
  }, [timeLive, upcoming]);

  const startingText = useTextAnimation(starting, {
    texts: ["STARTING", "STARTING.", "STARTING..", "STARTING..."],
    interval: 250,
  });

  const auctionOption = getAuctionOption(auctionTitle!);

  const renderStatus = useCallback(() => {
    const getStatus = () => {
      if (live) {
        return (
          <>
            LIVE
            <IndicatorContainer>
              <LiveIndicator></LiveIndicator>
            </IndicatorContainer>
          </>
        );
      } else if (upcoming) {
        const timeLeft = Number(auctionData.start) - time;
        const days = Math.floor(timeLeft / 86400);
        const hours = Math.floor((timeLeft % 86400) / 3600);
        const minute = Math.ceil((timeLeft % 3600) / 60);

        return timeLeft > 0
          ? `STARTING IN ${days.toString().padStart(2, "0")}D ${hours
              .toString()
              .padStart(2, "0")}H ${minute.toString().padStart(2, "0")}M`
          : startingText;
      } else {
        return (
          "CONCLUDED ON " +
          moment
            .unix(Number(auctionData.end))
            .format("DD MMM YY, HH:mm UTC")
            .toUpperCase()
        );
      }
    };

    return (
      <StatusTitleContainer>
        <StatusText>{"STATUS: "}</StatusText>
        {auctionChain === CHAINID.SOL && upcoming ? (
          <ZetaflexLabel className="mr-5">VIA ZETAFLEX</ZetaflexLabel>
        ) : isParadigmAuction(auctionOption as AuctionOptions) && upcoming ? (
          <ParadigmLabel className="mr-5">VIA PARADIGM</ParadigmLabel>
        ) : (
          <StatusTitle>{getStatus()}</StatusTitle>
        )}
      </StatusTitleContainer>
    );
  }, [
    live,
    upcoming,
    time,
    auctionData,
    startingText,
    auctionChain,
    auctionOption,
  ]);

  const renderWalletModule = useCallback(() => {
    const getModule = () => {
      if (upcoming) {
        return auctionChain === CHAINID.SOL ? (
          <Description>
            <ContractLinkButtonContainer>
              <BaseLink
                to={"https://flex.zeta.markets/"}
                target="_blank"
                rel="noreferrer noopener"
                className="w-100"
              >
                <ContractLinkButton color={"#ce08fa"}>
                  {"LINK TO ZETAFLEX"}
                  <ExternalIcon
                    className="ml-1"
                    color="#ce08fa"
                    height="20px"
                    width="20px"
                  ></ExternalIcon>
                </ContractLinkButton>
              </BaseLink>
            </ContractLinkButtonContainer>
          </Description>
        ) : isParadigmAuction(auctionOption as AuctionOptions) ? (
          <Description>
            <ContractLinkButtonContainer>
              <BaseLink
                to={"https://fe.chat.paradigm.co/vrfqs"}
                target="_blank"
                rel="noreferrer noopener"
                className="w-100"
              >
                <ContractLinkButton color={"#4D6BF0"}>
                  {"LINK TO PARADIGM"}
                  <ExternalIcon
                    className="ml-1"
                    color="#4D6BF0"
                    height="20px"
                    width="20px"
                  ></ExternalIcon>
                </ContractLinkButton>
              </BaseLink>
            </ContractLinkButtonContainer>
          </Description>
        ) : (
          <Description>{"AUCTION HAS NOT STARTED"}</Description>
        );
      } else if (live) {
        return (
          <>
            <BidModule auctionData={data}></BidModule>
            <BidInformationPage
              auctionData={data}
              bidData={bids}
            ></BidInformationPage>
          </>
        );
      } else {
        return (
          <>
            <ClaimModule auctionData={auctionData} bidData={bids}></ClaimModule>
            <BidInformationPage
              auctionData={data}
              bidData={bids}
            ></BidInformationPage>
          </>
        );
      }
    };

    return <WalletModule>{getModule()}</WalletModule>;
  }, [upcoming, auctionData, data, bids, live, auctionChain, auctionOption]);

  const loading =
    auctionsLoading || bidsLoading || similarAuctionLoading || vaultLoading;

  const loadingText = useTextAnimation();

  const additionalInfo = useMemo(() => {
    const info = vaultData[auctionOption! as AuctionOptions].additionalInfo;
    if (!loading && underlying === "wstETH" && info) {
      return `1 wstETH = ${parseFloat(formatUnits(info, 18)).toFixed(4)} ETH`;
    } else if (!loading && underlying === "rETH" && info) {
      return `1 rETH = ${parseFloat(formatUnits(info, 18)).toFixed(4)} ETH`;
    } else if (!loading && underlying === "yvUSDC" && info) {
      return `1 yvUSDC = ${parseFloat(formatUnits(info, 6)).toFixed(4)} USDC`;
    } else if (!loading && underlying === "sAVAX" && info) {
      return `1 sAVAX = ${parseFloat(formatUnits(info, 18)).toFixed(4)} AVAX`;
    } else {
      return;
    }
  }, [vaultData, underlying, auctionOption, loading]);

  if (!loading) {
    if (!data && !upcomingAuctionName) {
      return <Redirect to="/" />;
    } else {
      const type = auctionData.option.put ? "P" : "C";
      const expiry = moment
        .unix(Number(auctionData.option.expiry))
        .format("DDMMMYY")
        .toUpperCase();
      const index = auctionData.index;
      if (auctionTitle !== `${underlying}-${expiry}-${type}&${index}`) {
        return <Redirect to="/" />;
      }

      return (
        <>
          {renderStatus()}

          <AuctionInformation
            auctionData={auctionData}
            bidData={bids}
            priceData={prices}
            priceHistoricalData={priceHistory}
            additionalInfo={additionalInfo}
            upcoming={upcoming!}
          ></AuctionInformation>

          <OuterContainer>{renderWalletModule()}</OuterContainer>
          {active &&
          currentChainId === auctionData.chainId &&
          !live &&
          subgraphLive &&
          KEEPERS.includes(account!) ? (
            <SettleModule
              auctionId={auctionData.id.split("_")[0]}
            ></SettleModule>
          ) : (
            <></>
          )}
          {KEEPERS.includes(account!) ? (
            <AllBidsInformation
              auctionData={auctionData}
              bidData={bids}
              priceData={prices}
              priceHistoricalData={priceHistory}
            ></AllBidsInformation>
          ) : (
            <></>
          )}
        </>
      );
    }
  } else {
    return <LoadingContainer>{loadingText}</LoadingContainer>;
  }
};

export default AuctionPage;
