import { Connection, PublicKey } from "@solana/web3.js";
import { Flex, Network, utils, Vault } from "@zetamarkets/flex-sdk";

const flexProgramId = new PublicKey(process.env.REACT_APP_FLEX_PROGRAM_ID!);
const vaultProgramId = new PublicKey(process.env.REACT_APP_VAULT_PROGRAM_ID!);
const network = Network.MAINNET;

let loadedFlex = false;

export const loadFlex = async () => {
  if (loadedFlex || Flex.isInitialized || Vault.isInitialized) return;

  const connection: Connection = new Connection(
    "https://ribbon.rpcpool.com/35d538c8-ad7a-4a53-a861-06b709fb680f",
    utils.defaultCommitment()
  );

  await Flex.load(flexProgramId, network, connection);

  await Vault.load(vaultProgramId, network, connection);

  await Flex.updateState();

  await Vault.updateVaults();
};
