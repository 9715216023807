import { useCallback, useEffect, useState } from "react";
import { BigNumber, Contract, ethers } from "ethers";
import {
  isAvaxAuction,
  AuctionList,
  getVaultAssets,
  isEthAuction,
} from "../constants/constants";
import { CHAINID, isDevelopment, isProduction } from "../utils/env";
import { useWeb3Context } from "./web3Context";
import {
  defaultVaultData,
  VaultData,
  VaultDataResponses,
} from "../models/auction";
import { getReth, getSAVAX, getSteth, getVault, getYvusdc } from "./useVault";
import { getAssetDecimals } from "../utils/asset";
import moment from "moment";
import { parseEther } from "ethers/lib/utils";
import { StrikeSelectionFactory } from "../codegen/StrikeSelectionFactory";
import { vaultUtils } from "@zetamarkets/flex-sdk";
import { PublicKey } from "@solana/web3.js";
import { Vault } from "@zetamarkets/flex-sdk";
import { loadFlex } from "../utils/flex";

const useFetchVaultData = (): VaultData => {
  const { provider: avaxProvider } = useWeb3Context(
    isDevelopment() ? CHAINID.AVAX_FUJI : CHAINID.AVAX_MAINNET
  );
  const { provider: ethProvider } = useWeb3Context(
    isDevelopment() ? CHAINID.ETH_KOVAN : CHAINID.ETH_MAINNET
  );

  const [data, setData] = useState<VaultData>(defaultVaultData);
  const [, setMulticallCounter] = useState(0);

  const doMulticall = useCallback(async () => {
    if (!isProduction()) {
      console.time("Data Fetch");
    }

    let currentCounter: number;
    setMulticallCounter((counter) => {
      currentCounter = counter + 1;
      return currentCounter;
    });

    const responses = await Promise.all(
      AuctionList.map(async (auction) => {
        const expiry = moment()
          .add(1, "week")
          .day(5)
          .utc()
          .hour(8)
          .minute(0)
          .second(0)
          .unix();

        if (!isAvaxAuction(auction) && !isEthAuction(auction)) {
          try {
            await loadFlex();

            const [vaultAddress] = await vaultUtils.getVaultAddress(
              "rSOL-THETA"
            );
            const solVault = Vault.getVault(new PublicKey(vaultAddress));

            const currentExpiry = solVault.option?.expiry;
            const strike =
              currentExpiry && currentExpiry === expiry
                ? BigNumber.from(solVault.option?.strike.toString()).mul(100)
                : BigNumber.from("0");
            const size = BigNumber.from(solVault.startEpochVaultBalance)
              .add(solVault.totalQueuedDeposits)
              .sub(solVault.totalQueuedWithdrawals);

            return {
              auction,
              totalBalance: BigNumber.from(0),
              queued: BigNumber.from(0),
              strike: strike,
              pricePerShare: BigNumber.from(0),
              estimatedSize: size,
              additionalInfo: BigNumber.from(0),
            };
          } catch {
            return {
              auction,
              totalBalance: BigNumber.from(0),
              queued: BigNumber.from(0),
              strike: BigNumber.from(0),
              pricePerShare: BigNumber.from(0),
              estimatedSize: BigNumber.from(0),
              additionalInfo: BigNumber.from(0),
            };
          }
        }

        const inferredProviderFromVault = isAvaxAuction(auction)
          ? avaxProvider
          : ethProvider;

        const contract = getVault(inferredProviderFromVault, auction, false);

        let extraContract: Contract;

        if (auction === "wstETH-call") {
          try {
            extraContract = getSteth(ethProvider);
          } catch {}
        } else if (auction === "yvUSDC-put") {
          try {
            extraContract = getYvusdc(ethProvider);
          } catch {}
        } else if (auction === "rETH-call") {
          try {
            extraContract = getReth(ethProvider);
          } catch {}
        } else if (auction === "sAVAX-call") {
          try {
            extraContract = getSAVAX(avaxProvider);
          } catch {}
        }

        if (!contract) {
          return { auction };
        }
        const strikeSelection = StrikeSelectionFactory.connect(
          await contract.strikeSelection(),
          inferredProviderFromVault
        );

        const isPut = auction.split("-")[1] === "put" ? true : false;

        const getPricePerShares = () => {
          switch (auction) {
            case "wstETH-call":
              return extraContract!.stEthPerToken();
            case "rETH-call":
              return extraContract!.getExchangeRate();
            case "yvUSDC-put":
              return extraContract!.pricePerShare();
            case "sAVAX-call":
              return extraContract!.getPooledAvaxByShares(parseEther("1"));
            default:
              return contract.totalBalance();
          }
        };

        const promises: Promise<
          | BigNumber
          | { queuedWithdrawShares: BigNumber }
          | { newStrikePrice: BigNumber }
        >[] = [
          contract.totalBalance(),
          contract.vaultState(),
          contract.pricePerShare(),
          contract.lastQueuedWithdrawAmount(),
          contract.currentQueuedWithdrawShares(),
          strikeSelection.getStrikePrice(expiry, isPut),
          getPricePerShares(),
        ];

        const [
          totalBalance,
          _vaultState,
          pricePerShare,
          lastQueuedWithdrawAmount,
          queuedWithdrawShares,
          _strike,
          additionalInfo,
        ] = await Promise.all(
          promises.map((p) => p.catch((e) => BigNumber.from(0)))
        );

        const vaultState = (
          (_vaultState as { queuedWithdrawShares?: BigNumber })
            .queuedWithdrawShares
            ? _vaultState
            : { queuedWithdrawShares: 0 }
        ) as { queuedWithdrawShares: BigNumber };

        const strike = (
          (_strike as { newStrikePrice?: BigNumber }).newStrikePrice
            ? _strike
            : { newStrikePrice: 0 }
        ) as { newStrikePrice: BigNumber };

        const vaultToken = getVaultAssets(auction);
        const decimals = getAssetDecimals(vaultToken);
        const divider = ethers.utils.parseUnits("1", decimals);

        let estimatedSize = BigNumber.from(totalBalance).sub(
          BigNumber.from(vaultState.queuedWithdrawShares)
            .mul(pricePerShare as BigNumber)
            .div(divider)
        );

        if (lastQueuedWithdrawAmount) {
          estimatedSize = BigNumber.from(totalBalance)
            .sub(BigNumber.from(lastQueuedWithdrawAmount))
            .sub(
              BigNumber.from(queuedWithdrawShares)
                .mul(pricePerShare as BigNumber)
                .div(divider)
            );
        }

        if (auction === "wstETH-call") {
          try {
            estimatedSize = estimatedSize
              .mul(divider)
              .div(additionalInfo as BigNumber);
          } catch {}
        } else if (auction === "yvUSDC-put") {
          try {
            estimatedSize = estimatedSize
              .mul(10 ** 6)
              .div(additionalInfo as BigNumber)
              .mul(10 ** 8)
              .div(strike.newStrikePrice);
          } catch {}
        } else if (auction === "USDC.e-put") {
          try {
            estimatedSize = estimatedSize
              .mul(10 ** 8)
              .div(strike.newStrikePrice);
          } catch {}
        }

        return {
          auction,
          totalBalance,
          queued: vaultState.queuedWithdrawShares,
          strike: strike.newStrikePrice,
          pricePerShare,
          estimatedSize,
          additionalInfo,
        };
      })
    );

    setMulticallCounter((counter) => {
      if (counter === currentCounter) {
        setData((prev) => ({
          responses: Object.fromEntries(
            responses.map(({ auction, ...response }) => [
              auction,
              {
                ...prev.responses[auction],
                ...response,
              },
            ])
          ) as VaultDataResponses,
          loading: false,
        }));
      }

      return counter;
    });

    if (!isProduction()) {
      console.timeEnd("Data Fetch");
    }
  }, [avaxProvider, ethProvider]);

  useEffect(() => {
    doMulticall();
  }, [doMulticall]);
  // console.log(data) // FOR LOGGING ONLY
  return data;
};

export default useFetchVaultData;
