/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from "ethers";
import { Provider } from "@ethersproject/providers";

import type { DepositAndPlaceOrder } from "./DepositAndPlaceOrder";

export class DepositAndPlaceOrderFactory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): DepositAndPlaceOrder {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as DepositAndPlaceOrder;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "easyAuctionAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_nativeTokenWrapper",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "auctionId",
        type: "uint256",
      },
      {
        internalType: "uint96[]",
        name: "_minBuyAmounts",
        type: "uint96[]",
      },
      {
        internalType: "bytes32[]",
        name: "_prevSellOrders",
        type: "bytes32[]",
      },
      {
        internalType: "bytes",
        name: "allowListCallData",
        type: "bytes",
      },
    ],
    name: "depositAndPlaceOrder",
    outputs: [
      {
        internalType: "uint64",
        name: "userId",
        type: "uint64",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "easyAuction",
    outputs: [
      {
        internalType: "contract EasyAuction",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "nativeTokenWrapper",
    outputs: [
      {
        internalType: "contract IWETH",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
