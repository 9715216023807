import React from "react";

type SVGProps = React.SVGAttributes<SVGElement & { color?: string }>;

const Logo: React.FC<SVGProps> = ({ color, ...props }) => (
  <svg
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <circle cx="48" cy="48" r="48" fill={color || "#FC0A54"} />
      <path
        d="M3 71.9068L47.8065 25L71.61 49.5036L47.5 75.5"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="96" height="96" rx="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const EasyAuctionLogo: React.FC<SVGProps> = ({ color, ...props }) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.9991 3.00005C19.9661 3.00005 22.8661 3.88005 25.3331 5.52805C27.8001 7.17605 29.7222 9.51904 30.8582 12.26C31.9932 15.001 32.2902 18.017 31.7112 20.927C31.1322 23.836 29.7042 26.509 27.6062 28.607C26.2162 30.004 24.5632 31.112 22.7422 31.866C20.9222 32.62 18.9691 33.006 16.9991 33V3.00005ZM14.9991 30C12.0321 30 9.13215 29.12 6.66615 27.472C4.19915 25.823 2.27715 23.4811 1.14215 20.7401C0.00715232 17.9991 -0.290852 14.9841 0.288148 12.0741C0.867148 9.16405 2.29516 6.49205 4.39316 4.39405C5.78216 2.99705 7.43515 1.88904 9.25615 1.13504C11.0762 0.380043 13.0281 -0.00495192 14.9991 4.80829e-05V30Z"
      fill="black"
    />
  </svg>
);

export const GnosisLogo: React.FC<SVGProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" {...props}>
    <g fill="#00a6c4">
      <path d="M1809.4 500l-73.6 73.6c58.5 92 76.9 207.4 36.8 317.7-66.9 187.3-274.2 286-463.2 219.1-26.8-10-51.8-21.7-73.6-36.8L1000 1309.4 794.3 1102c-95.3 63.5-217.4 81.9-332.8 41.8-194-70.2-294.3-282.6-224.1-474.9 10-30.1 25.1-56.9 41.8-81.9l-88.6-88.6-16.7 28.4c-92 150.5-142.1 324.4-142.1 503.3-1.7 533.4 433.1 969.9 966.5 969.9h1.7c533.4 0 966.6-433.1 968.2-966.6 0-177.3-48.5-351.2-140.5-503.3l-18.3-30.1" />
      <path d="M388 695.7c-25.1 33.4-40.1 75.3-40.1 120.4 0 108.7 88.6 197.3 197.3 197.3 45.2 0 87-15.1 120.4-41.8L388 695.7M1356.2 954.8c31.8 21.7 68.6 33.4 110.4 33.4 108.7 0 197.3-88.6 197.3-197.3 0-40.1-11.7-78.6-33.4-110.4l-274.3 274.3M1003.3 1162.2L204 359.5l31.8-33.4C433.1 117.1 702.3 0 991.6 0h1.7c292.6 0 573.6 125.4 769.2 342.8l30.1 33.4-789.3 786M334.5 359.5l668.9 670.6 660.5-657.2C1486.6 195.7 1245.8 93.6 995 93.6h-1.7c-249.2 0-481.6 93.7-658.8 265.9" />
    </g>
  </svg>
);

export const ParadigmLogo: React.FC<SVGProps> = (props) => (
  <svg
    viewBox="0 0 182 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M67.6 185.9C104.934 185.9 135.2 155.142 135.2 117.2C135.2 79.258 104.934 48.5 67.6 48.5C30.2655 48.5 0 79.258 0 117.2C0 155.142 30.2655 185.9 67.6 185.9Z"
      fill="url(#paint0_linear_501_3)"
    />
    <mask
      id="mask0_501_3"
      // style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="48"
      width="136"
      height="138"
    >
      <path
        d="M67.6 185.905C104.934 185.905 135.2 155.147 135.2 117.205C135.2 79.2634 104.934 48.5054 67.6 48.5054C30.2655 48.5054 0 79.2634 0 117.205C0 155.147 30.2655 185.905 67.6 185.905Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_501_3)">
      <path
        d="M181.4 91.3054C181.4 140.205 142.6 179.805 94.7 179.805C46.8 179.805 8 140.205 8 91.3054C8 42.4054 46.8 2.80542 94.7 2.80542C142.6 2.80542 181.4 42.4054 181.4 91.3054Z"
        fill="url(#paint1_radial_501_3)"
        fill-opacity="0.5"
      />
    </g>
    <path
      d="M181.3 68.7C181.3 106.7 150.5 137.4 112.6 137.4C74.6 137.4 43.9 106.6 43.9 68.7C43.9 30.7 74.7 0 112.6 0C150.6 0 181.3 30.8 181.3 68.7Z"
      fill="url(#paint2_linear_501_3)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_501_3"
        x1="8.88262"
        y1="111.022"
        x2="163.436"
        y2="126.797"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3D89FB" />
        <stop offset="1" stop-color="#5F49E4" stop-opacity="0.62" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_501_3"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(94.6852 91.3392) rotate(90) scale(88.5281)"
      >
        <stop stop-color="#051323" />
        <stop offset="1" stop-color="#051323" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_501_3"
        x1="51.1673"
        y1="62.4063"
        x2="213.695"
        y2="79.0906"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3D89FB" />
        <stop offset="1" stop-color="#5F49E4" stop-opacity="0.62" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;

export const AppLogo: React.FC<SVGProps> = ({ color, ...props }) => (
  <svg
    viewBox="0 0 400 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M296 80C309.255 80 320 90.7452 320 104C320 117.255 309.255 128 296 128C282.745 128 272 117.255 272 104C272 90.7452 282.745 80 296 80ZM296 271.999C309.255 271.999 320 282.745 320 295.999C320 309.254 309.255 319.999 296 319.999C282.745 319.999 272 309.254 272 295.999C272 282.745 282.745 271.999 296 271.999ZM320 200C320 186.745 309.255 176 296 176C282.745 176 272 186.745 272 200C272 213.255 282.745 224 296 224C309.255 224 320 213.255 320 200ZM199.999 271.999C213.254 271.999 223.999 282.745 223.999 295.999C223.999 309.254 213.254 319.999 199.999 319.999C186.744 319.999 175.999 309.254 175.999 295.999C175.999 282.745 186.744 271.999 199.999 271.999ZM223.999 104C223.999 90.7452 213.254 80 199.999 80C186.744 80 175.999 90.7452 175.999 104C175.999 117.255 186.744 128 199.999 128C213.254 128 223.999 117.255 223.999 104ZM199.999 176C213.254 176 223.999 186.745 223.999 200C223.999 213.255 213.254 224 199.999 224C186.744 224 175.999 213.255 175.999 200C175.999 186.745 186.744 176 199.999 176ZM127.998 295.999C127.998 282.745 117.253 271.999 103.998 271.999C90.7432 271.999 79.998 282.745 79.998 295.999C79.998 309.254 90.7432 319.999 103.998 319.999C117.253 319.999 127.998 309.254 127.998 295.999ZM103.998 80C117.253 80 127.998 90.7452 127.998 104C127.998 117.255 117.253 128 103.998 128C90.7432 128 79.998 117.255 79.998 104C79.998 90.7452 90.7432 80 103.998 80ZM127.998 200C127.998 186.745 117.253 176 103.998 176C90.7432 176 79.998 186.745 79.998 200C79.998 213.255 90.7432 224 103.998 224C117.253 224 127.998 213.255 127.998 200Z"
      fill="#FC0A54"
    />
  </svg>
);
