import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export interface SVGPropsWithColor extends SVGProps {
  color?: string;
}

export interface IconProps extends SVGPropsWithColor {
  containerStyle?: React.CSSProperties;
}

export const TimerIcon: React.FC<SVGProps> = ({ color, ...props }) => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 29C23.4036 29 29 23.4036 29 16.5C29 9.59644 23.4036 4 16.5 4C9.59644 4 4 9.59644 4 16.5C4 23.4036 9.59644 29 16.5 29ZM16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0221 15.1525C15.7663 14.3363 17.0313 14.2779 17.8475 15.0221L22.2823 19.0656C23.0986 19.8098 23.1569 21.0748 22.4127 21.891C21.6685 22.7072 20.4035 22.7656 19.5873 22.0214L15.1525 17.9779C14.3363 17.2337 14.2779 15.9687 15.0221 15.1525Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 18C14.8954 18 14 17.1046 14 16L14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8L18 16C18 17.1046 17.1046 18 16 18Z"
      fill={color}
    />
  </svg>
);

export const ArrowIcon: React.FC<SVGProps> = ({ color, ...props }) => (
  <svg
    width="12"
    height="32"
    viewBox="0 0 12 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.46967 31.5303C5.76256 31.8232 6.23744 31.8232 6.53033 31.5303L11.3033 26.7574C11.5962 26.4645 11.5962 25.9896 11.3033 25.6967C11.0104 25.4038 10.5355 25.4038 10.2426 25.6967L6 29.9393L1.75736 25.6967C1.46446 25.4038 0.989591 25.4038 0.696698 25.6967C0.403805 25.9896 0.403805 26.4645 0.696698 26.7574L5.46967 31.5303ZM5.25 -3.27835e-08L5.25 31L6.75 31L6.75 3.27835e-08L5.25 -3.27835e-08Z"
      fill={color}
    />
  </svg>
);

export const ExternalIcon: React.FC<IconProps> = ({
  containerStyle = { display: "flex" },
  color = "#FFFFFF7A",
  onClick = () => {},
  ...props
}) => (
  <span style={containerStyle}>
    <svg
      height="24"
      width="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      {...props}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.75 4.5C13.75 4.08579 14.0858 3.75 14.5 3.75H19.5C19.6017 3.75 19.6987 3.77024 19.7871 3.80691C19.8755 3.84351 19.9584 3.89776 20.0303 3.96967C20.1022 4.04158 20.1565 4.12445 20.1931 4.21291C20.2251 4.29009 20.2446 4.37376 20.249 4.46141C20.2497 4.47419 20.25 4.48706 20.25 4.5V9.5C20.25 9.91421 19.9142 10.25 19.5 10.25C19.0858 10.25 18.75 9.91421 18.75 9.5V6.31066L10.8637 14.197C10.5708 14.4899 10.0959 14.4899 9.803 14.197C9.51011 13.9041 9.51011 13.4292 9.803 13.1363L17.6893 5.25H14.5C14.0858 5.25 13.75 4.91421 13.75 4.5ZM6.16667 7.75C5.92355 7.75 5.69039 7.84658 5.51849 8.01849C5.34658 8.19039 5.25 8.42355 5.25 8.66667V17.8333C5.25 18.0764 5.34658 18.3096 5.51849 18.4815C5.69039 18.6534 5.92355 18.75 6.16667 18.75H15.3333C15.5764 18.75 15.8096 18.6534 15.9815 18.4815C16.1534 18.3096 16.25 18.0764 16.25 17.8333V12.8333C16.25 12.4191 16.5858 12.0833 17 12.0833C17.4142 12.0833 17.75 12.4191 17.75 12.8333V17.8333C17.75 18.4743 17.4954 19.089 17.0422 19.5422C16.589 19.9954 15.9743 20.25 15.3333 20.25H6.16667C5.52573 20.25 4.91104 19.9954 4.45783 19.5422C4.00461 19.089 3.75 18.4743 3.75 17.8333V8.66667C3.75 8.02573 4.00461 7.41104 4.45783 6.95783C4.91104 6.50461 5.52573 6.25 6.16667 6.25H11.1667C11.5809 6.25 11.9167 6.58579 11.9167 7C11.9167 7.41421 11.5809 7.75 11.1667 7.75H6.16667Z"
          fill={color}
        />
      </g>
    </svg>
  </span>
);
