import { ethers } from "ethers";
import moment from "moment";
import { useMemo } from "react";
import styled from "styled-components";
import theme from "../../design/theme";
import { useAssetsPriceHistory } from "../../hooks/fetchAssetPrice";
import { useSubgraphData } from "../../hooks/subgraphDataContext";
import { Assets } from "../../utils/asset";
import csvDownload from "../../utils/csv";
import { decodeOrder } from "../../utils/order";

const AdminFunctionContainer = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 7px;
  border: solid 2px #fc0a54;
  padding: 10px;
  min-height: 100px;
  margin-bottom: 40px;
`;

const AdminFunctionDescriptionContainer = styled.div`
  font-size: 14px;
  display: flex;
  color: #8e8e8e;
  justify-content: center;
  align-items: center;
`;

const AdminText = styled.div`
  font-family: VCR;
  font-size: 20px;
  color: #fc0a54;
  margin-top: -5px;
  margin-left: 5px;
  font-weight: 700px;
`;

const SettleButton = styled.button`
  font-family: VCR;
  font-size: 15px;
  color: #fc0a54;
  background-color: #424242;
  border-radius: 5px;
  border: none;
  padding: 9px 20px 10px 20px;
  line-height: 20px;
  vertical-align: text-top;
  margin-top: -9px;

  &:hover {
    opacity: ${theme.hover.opacity};
  }
`;

const PremiumsExporter: React.FC<{}> = () => {
  const { data: responses, loading: subgraphLoading } = useSubgraphData();
  const { histories, loading } = useAssetsPriceHistory();

  const recentFriday = moment()
    .day(moment().day() >= 5 ? 5 : -2)
    .utc()
    .hour(0)
    .minute(0)
    .second(0);
  const recentFridayUnix = recentFriday.unix() * 1000;
  const recentFridayText = recentFriday.format("MM-DD-YYYY");

  const recentAuctions = useMemo(() => {
    const auctions = responses.auctions.filter((value) => {
      return (
        moment
          .unix(Number(value.option.expiry))
          .utc()
          .hour(0)
          .minute(0)
          .second(0)
          .toString() === recentFriday.toString()
      );
    });

    return auctions.map((value) => {
      try {
        const clearingOrder = decodeOrder(value.clearing);

        const clearing = ethers.utils.formatUnits(
          clearingOrder.sellAmount.mul(10 ** 8).div(clearingOrder.buyAmount),
          value.bidding.decimals
        );

        return {
          option: value.option.name,
          size: ethers.utils.formatUnits(value.size, 8),
          clearingPrice: clearing,
          filled: ethers.utils.formatUnits(value.filled, 8),
          spotPrice:
            histories[value.bidding.symbol as Assets][recentFridayUnix],
        };
      } catch {
        return {
          option: value.option.name,
          size: ethers.utils.formatUnits(value.size, 8),
          clearingPrice: "0",
          filled: ethers.utils.formatUnits(value.filled, 8),
          spotPrice:
            histories[value.bidding.symbol as Assets][recentFridayUnix],
        };
      }
    });
  }, [responses, histories, recentFridayUnix, recentFriday]);

  return (
    <>
      {!subgraphLoading && !loading && (
        <AdminFunctionContainer>
          <AdminText>ADMIN FUNCTION</AdminText>
          <AdminFunctionDescriptionContainer>
            <div>
              <SettleButton
                onClick={() =>
                  csvDownload(recentAuctions, recentFridayText + ".csv")
                }
              >
                EXPORT DATA
              </SettleButton>
            </div>
          </AdminFunctionDescriptionContainer>
        </AdminFunctionContainer>
      )}
    </>
  );
};

export default PremiumsExporter;
