import { BigNumber } from "ethers";
import { AuctionList, AuctionOptions } from "../constants/constants";

export type AuctionData = {
  id: string;
  bidding: {
    id: string;
    name: string;
    symbol: string;
    decimals: number;
  };
  option: {
    id: string;
    name: string;
    symbol: string;
    decimals: string;
    expiry: string;
    strike: string;
    underlying: {
      id: string;
      name: string;
      symbol: string;
      decimals: Number;
    };
    put: Boolean;
  };
  minimum: string;
  size: string;
  start: string;
  end: string;
  filled: string;
  clearing: string;
  live: boolean;
};

export type BidData = {
  id: number;
  index: string;
  account: {
    id: string;
    address: string;
  };
  auction: number;
  size: string;
  payable: string;
  createtx: string;
  claimtx: string;
  canceltx: string;
  bytes: string;
};

export type ProcessedBidData = {
  id: number;
  index: string;
  account: {
    id: string;
    address: string;
  };
  auction: number;
  size: string;
  payable: string;
  createtx: string;
  claimtx: string;
  canceltx: string;
  bytes: string;
  reimburse: string;
  allocation: string;
  price: BigNumber;
};

export interface AugmentedAuctionData extends AuctionData {
  chainId: number;
  index: number;
}

export interface AugmentedBidData extends BidData {
  chainId: Number;
}

export interface VaultData {
  responses: VaultDataResponses;
  loading: boolean;
}

export type VaultDataResponses = {
  [vault in AuctionOptions]: VaultDataResponse;
};

export type VaultDataResponse = {
  totalBalance: BigNumber;
  queuedWithdrawShares: BigNumber;
  pricePerShare: BigNumber;
  estimatedSize: BigNumber;
  strike: BigNumber;
  additionalInfo: BigNumber;
};

export const defaultVaultData: VaultData = {
  responses: Object.fromEntries(
    AuctionList.map((auction) => [
      auction,
      {
        totalBalance: BigNumber.from(0),
        queuedWithdrawShares: BigNumber.from(0),
        pricePerShare: BigNumber.from(0),
        estimatedSize: BigNumber.from(0),
      },
    ])
  ) as VaultDataResponses,
  loading: true,
};
