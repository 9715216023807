import styled from "styled-components";
import useTextAnimation from "../../hooks/useTextAnimation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useApprovalGlobalState } from "../../store/store";
import {
  AUCTION_CONTRACT,
  BiddingAssets,
  CHAINID_TO_NATIVE_TOKENS,
  getEtherscanURI,
  getGnosisAuction,
  getREarnContract,
  getREarnstEthContract,
  getSwapContract,
  READABLE_NETWORK_NAMES,
  isEthMainnet,
} from "../../constants/constants";
import { CHAINID, ENABLED_CHAINID, SHOW_MORE_INFO } from "../../utils/env";
import { Assets, getAssetColor, getAssetLogo } from "../../utils/asset";
import { useWeb3React } from "@web3-react/core";
import { switchChains } from "../../utils/switch";
import { BaseIndicator, BaseLink, Subtitle } from "../../design";
import theme from "../../design/theme";
import TokenItem from "../../components/Approval/TokenItem";
import { ExternalIcon } from "../../assets/icons/icons";
import { getERC20Token } from "../../hooks/useERC20Token";
import { useWeb3Context } from "../../hooks/web3Context";
import { useUserBalance } from "../../hooks/web3DataContext";
import { parseUnits } from "ethers/lib/utils";
import {
  AuctionContracts,
  getAuctionContractColor,
  getAuctionContractLogo,
} from "../../utils/auction";
import TooltipExplanation from "../../components/Common/TooltipExplanation";
import HelpInfo from "../../components/Common/HelpInfo";
import { Placement } from "react-bootstrap/esm/Overlay";
import { ethers } from "ethers";
import useSwap from "../../hooks/useSwap";
import { getRibbonEarnContract } from "../../hooks/useRibbonEarnContract";
import { getRibbonEarnSTETHContract } from "../../hooks/useRibbonEarnSTETHContract";

const ListContainer = styled.div`
  background-color: #f6f6f6;
  border-radius: 7px;
  padding: 30px;
  min-height: 105px;
  margin-bottom: 30px;

  & > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: unset;
    }
  }
`;

const RequiredLabel = styled.div`
  display: flex;
  font-family: VCR;
  font-size: 12px;
  height: 25px;
  background-color: #fc0a5430;
  color: #fc0a54;
  padding: 2px 9px;
  border-radius: 4px;
  align-items: center;
  margin-left: 10px;
`;

const Input = styled.input`
  font-family: VCR;
  border-radius: 5px;
  border: solid 1px #c1c1c1;
  width: 100%;
  height: 50px;
  font-size: 16px;

  &::placeholder {
    opacity: 0.2;
  }
`;

const Description = styled.div<{
  height: string;
}>`
  font-family: VCR;
  font-size: 14px;
  display: flex;
  color: #8e8e8e;
  height: ${(props) => props.height};
  justify-content: center;
  align-items: center;
`;

const SelectNetworkContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 7px;
  padding: 30px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  max-width: 800px;

  & > * {
    margin-right: 15px;

    &:last-child {
      margin-right: unset;
    }
  }
`;

const ErrorMessage = styled.span`
  font-family: VCR;
  margin-left: 10px;
  color: red;
`;

const LogoContainer = styled.div`
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListTitle = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #646464;
  font-family: VCR;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  transition: 0.5s linear;
  max-width: 800px;
`;

const LoadingContainer = styled.div`
  font-family: VCR;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  color: #8e8e8e;
  height: 75px;
  justify-content: center;
  align-items: center;
`;

const NetworkName = styled(Subtitle)`
  font-size: 16px;
  text-transform: uppercase;
  line-height: 24px;
  margin-left: 10px;
  color: #000000;
  max-width: 78px;
`;

const NetworkContainer = styled.div<{
  borderColor: string;
  active?: boolean;
}>`
  flex: 1;
  display: flex;
  border-radius: 3px;
  padding: 12px 8px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  max-width: 320.5px;

  ${(props) =>
    props.active ? `border: 1px solid ${props.borderColor};` : "border: none;"}
`;

const StyledHelpInfo = styled(HelpInfo)`
  margin-left: 15px;
`;

const InputCaption = styled.div`
  display: flex;
  font-family: VCR;
  margin-bottom: 5px;
  color: #464646;
  font-size: 14px;
  font-weight: 600;
`;

const NetworkNameContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

const ApprovalModuleContainer = styled.div`
  display: flex;
  justify-content: center;
  & > * {
    margin-right: 30px;

    &:last-child {
      margin-right: unset;
    }
  }
`;

const ContractContainer = styled.div`
  flex: 1;
  max-width: 800px;
`;

const ApproveButton = styled.button`
  font-family: VCR;
  font-size: 15px;
  color: #ffffff;
  background-color: #424242;
  border-radius: 5px;
  border: none;
  padding: 9px 20px 10px 20px;
  line-height: 20px;
  vertical-align: text-top;
  height: 41px;
  width: 800px;

  &:hover {
    opacity: ${theme.hover.opacity};
  }

  &:disabled {
    opacity: ${theme.hover.opacity};
  }
`;

const Approval = () => {
  const { chainId: currentChainId, active, library } = useWeb3React();
  const [approvalForm, setApprovalForm] =
    useApprovalGlobalState("approvalForm");
  const { provider } = useWeb3Context();
  const { data: balances, loading: balanceLoading } = useUserBalance();

  const handleSwitchChain = useCallback(
    async (chainId: number) => {
      if (library && currentChainId !== chainId) {
        await switchChains(library, chainId);
      }
    },
    [library, currentChainId]
  );

  const handleSelectAuctionContract = useCallback(
    (auctionContract: AuctionContracts) => {
      setApprovalForm({
        auctionContract: auctionContract,
        asset: undefined,
        delegate: undefined,
      });
    },
    [setApprovalForm]
  );

  useEffect(() => {
    setApprovalForm((approvalForm) => {
      return { ...approvalForm, asset: undefined };
    });
  }, [currentChainId, setApprovalForm]);

  const biddingAssets = useMemo(() => {
    return currentChainId && approvalForm.auctionContract
      ? BiddingAssets[currentChainId][approvalForm.auctionContract]
      : [];
  }, [currentChainId, approvalForm]);

  const loadingText = useTextAnimation();

  const [waitingApproval, setWaitingApproval] = useState(false);
  const approveLoadingText = useTextAnimation(waitingApproval, {
    texts: ["APPROVING", "APPROVING.", "APPROVING..", "APPROVING..."],
    interval: 250,
  });

  const getContractAddress = (
    auctionContractType: "GNOSIS" | "PARADIGM" | "R-EARN" | "R-EARN-STETH",
    currentChainId: number
  ) => {
    switch (auctionContractType) {
      case "GNOSIS":
        return getGnosisAuction(currentChainId);
      case "PARADIGM":
        return getSwapContract(currentChainId);
      case "R-EARN":
        return getREarnContract(currentChainId);
      case "R-EARN-STETH":
        return getREarnstEthContract(currentChainId);
      default:
        throw new Error("Error getting auction contract type");
    }
  };

  const handleApprove = useCallback(async () => {
    setWaitingApproval(true);

    if (approvalForm) {
      let tokenContract;
      try {
        tokenContract = getERC20Token(
          library,
          approvalForm.asset!.toLowerCase() as Assets,
          currentChainId!
        );
      } catch (err) {}

      if (tokenContract) {
        const amount =
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

        try {
          const contractAddress = getContractAddress(
            approvalForm.auctionContract!,
            currentChainId!
          );
          const tx = await tokenContract.approve(contractAddress, amount);

          await provider.waitForTransaction(tx.hash, 5);
          setApprovalForm((approvalForm) => {
            return { ...approvalForm, asset: undefined };
          });
        } catch (err) {
        } finally {
          setWaitingApproval(false);
        }
      }
    }
  }, [
    approvalForm,
    library,
    currentChainId,
    setWaitingApproval,
    setApprovalForm,
    provider,
  ]);

  const [waitingAuthorize, setWaitingAuthorize] = useState(false);
  const authorizeLoadingText = useTextAnimation(waitingAuthorize, {
    texts: ["AUTHORIZING", "AUTHORIZING.", "AUTHORIZING..", "AUTHORIZING..."],
    interval: 250,
  });

  const swapContract = useSwap();

  const handleAuthorize = useCallback(async () => {
    setWaitingAuthorize(true);

    if (swapContract) {
      if (approvalForm.delegate) {
        try {
          const tx = await swapContract.authorize(approvalForm.delegate);

          await provider.waitForTransaction(tx.hash, 5);
        } catch (err) {
        } finally {
          setWaitingAuthorize(false);
        }
      }
    }
  }, [approvalForm, provider, swapContract]);

  const handleDelegateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawInput = e.target.value;

      setApprovalForm((approvalForm) => ({
        ...approvalForm,
        delegate: rawInput,
      }));
    },
    [setApprovalForm]
  );

  const addressError = useMemo(() => {
    if (
      !ethers.utils.isAddress(approvalForm.delegate!) &&
      approvalForm.delegate
    ) {
      return {
        error: true,
        msg: "INVALID ADDRESS",
      };
    } else {
      return {
        error: false,
        msg: "",
      };
    }
  }, [approvalForm.delegate]);

  const renderTokens = useCallback(() => {
    const auctionContract = approvalForm.auctionContract;
    try {
      return biddingAssets.map((value) => {
        const allowance = balances[value].allowance;
        const digit = value === "UNI" ? 28 : 30; // UNI max approval value is uint96 which has 29 digits, hence we parseUnits below with arg 28
        const approved = allowance[auctionContract as AuctionContracts].gte(
          parseUnits("1", digit)
        );
        return (
          <TokenItem
            asset={value}
            approved={approved}
            showMoreInfo={
              auctionContract === "R-EARN" || auctionContract === "R-EARN-STETH"
                ? false
                : SHOW_MORE_INFO
            }
          ></TokenItem>
        );
      });
    } catch {
      return <LoadingContainer>{loadingText}</LoadingContainer>;
    }
  }, [biddingAssets, balances, loadingText, approvalForm]);

  return (
    <>
      <ApprovalModuleContainer>
        <ContractContainer>
          <ListTitle>Network</ListTitle>
          <SelectNetworkContainer>
            {ENABLED_CHAINID.map((chainId: CHAINID) => {
              const Logo = getAssetLogo(CHAINID_TO_NATIVE_TOKENS[chainId]);
              const color = getAssetColor(CHAINID_TO_NATIVE_TOKENS[chainId]);
              const active = currentChainId === chainId;

              return (
                <NetworkContainer
                  key={chainId}
                  onClick={() => handleSwitchChain(chainId)}
                  borderColor={color}
                  active={active}
                >
                  <NetworkNameContainer>
                    <Logo height={28} width={28}></Logo>

                    <NetworkName>{READABLE_NETWORK_NAMES[chainId]}</NetworkName>
                  </NetworkNameContainer>

                  {active && (
                    <BaseIndicator size={8} color={color}></BaseIndicator>
                  )}
                </NetworkContainer>
              );
            })}
          </SelectNetworkContainer>
        </ContractContainer>
      </ApprovalModuleContainer>

      <ApprovalModuleContainer>
        <ContractContainer>
          <ListTitle>PRODUCT</ListTitle>
          <SelectNetworkContainer>
            {active ? (
              currentChainId &&
              AUCTION_CONTRACT[currentChainId as CHAINID].map(
                (auctionContract) => {
                  const Logo = getAuctionContractLogo(auctionContract);
                  const color = getAuctionContractColor(auctionContract);
                  const active =
                    approvalForm.auctionContract === auctionContract;
                  const contractAddress = getContractAddress(
                    auctionContract,
                    currentChainId
                  );
                  return (
                    <NetworkContainer
                      onClick={() =>
                        handleSelectAuctionContract(auctionContract)
                      }
                      borderColor={color}
                      active={active}
                    >
                      <NetworkNameContainer>
                        <LogoContainer>
                          <Logo height={24} width={24}></Logo>
                        </LogoContainer>

                        <NetworkName>{auctionContract}</NetworkName>
                        {auctionContract === "PARADIGM" && (
                          <TooltipExplanation
                            placement={"bottom-end" as Placement}
                            explanation={
                              <>
                                <span>
                                  <b>
                                    Paradigm Auction settles through Ribbon Swap
                                    Contract.
                                  </b>
                                </span>
                                <br />
                                <br />
                                <span>
                                  By selecting this option, you will be
                                  approving the Ribbon Swap Contract to spend
                                  your tokens.
                                </span>
                              </>
                            }
                            renderContent={({ ref, ...triggerHandler }) => (
                              <StyledHelpInfo
                                containerRef={ref}
                                {...triggerHandler}
                                color="#000000"
                              >
                                i
                              </StyledHelpInfo>
                            )}
                          />
                        )}
                        {
                          <BaseLink
                            to={
                              getEtherscanURI(currentChainId!) +
                              "/address/" +
                              contractAddress
                            }
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{ marginBottom: "1px", marginLeft: "10px" }}
                          >
                            <ExternalIcon
                              color="#646464"
                              height="20px"
                              width="20px"
                            ></ExternalIcon>
                          </BaseLink>
                        }
                      </NetworkNameContainer>

                      {active && (
                        <BaseIndicator size={8} color={color}></BaseIndicator>
                      )}
                    </NetworkContainer>
                  );
                }
              )
            ) : (
              <Description height="50px">CONNECT YOUR WALLET</Description>
            )}
          </SelectNetworkContainer>
        </ContractContainer>
      </ApprovalModuleContainer>

      <ApprovalModuleContainer>
        <ContractContainer>
          <ListTitle>ASSETS</ListTitle>
          <ListContainer>
            {active ? (
              approvalForm.auctionContract ? (
                !balanceLoading ? (
                  renderTokens()
                ) : (
                  <LoadingContainer>{loadingText}</LoadingContainer>
                )
              ) : (
                <Description height="140px">SELECT AUCTION</Description>
              )
            ) : (
              <Description height="140px">CONNECT YOUR WALLET</Description>
            )}
          </ListContainer>
        </ContractContainer>
      </ApprovalModuleContainer>
      <ApprovalModuleContainer
        style={{ marginTop: "-15px", marginBottom: "40px" }}
      >
        <ApproveButton
          onClick={handleApprove}
          disabled={!approvalForm.asset || waitingApproval}
        >
          {waitingApproval ? approveLoadingText : `APPROVE`}
        </ApproveButton>
      </ApprovalModuleContainer>
      {approvalForm.auctionContract === "PARADIGM" && (
        <>
          <ApprovalModuleContainer>
            <ContractContainer>
              <ListTitle>
                DELEGATION
                <TooltipExplanation
                  placement={"bottom-start" as Placement}
                  explanation={
                    <>
                      <span>
                        An authorized delegate can submit signed bids on your
                        behalf.
                      </span>
                    </>
                  }
                  renderContent={({ ref, ...triggerHandler }) => (
                    <HelpInfo
                      containerRef={ref}
                      {...triggerHandler}
                      color="#646464"
                    >
                      i
                    </HelpInfo>
                  )}
                />
                <RequiredLabel>REQUIRED FOR MULTI-SIG</RequiredLabel>
              </ListTitle>

              <ListContainer style={{ minHeight: "90px" }}>
                <InputCaption style={{ marginTop: "-5px" }}>
                  ADDRESS
                  <ErrorMessage>
                    {addressError.error ? addressError.msg : ""}
                  </ErrorMessage>
                </InputCaption>
                <Input
                  type="string"
                  className="form-control"
                  aria-label="quantity"
                  placeholder="0x..."
                  value={approvalForm.delegate}
                  onChange={handleDelegateChange}
                  style={{ marginBottom: "0px" }}
                ></Input>
              </ListContainer>
            </ContractContainer>
          </ApprovalModuleContainer>
          <ApprovalModuleContainer
            style={{ marginTop: "-15px", marginBottom: "70px" }}
          >
            <ApproveButton
              onClick={handleAuthorize}
              disabled={
                !approvalForm.delegate || waitingAuthorize || addressError.error
              }
            >
              {waitingAuthorize ? authorizeLoadingText : `AUTHORIZE`}
            </ApproveButton>
          </ApprovalModuleContainer>
        </>
      )}
      {(approvalForm.auctionContract === "R-EARN" ||
        approvalForm.auctionContract === "R-EARN-STETH") &&
        isEthMainnet(currentChainId!) && (
          <>
            <ActionContainer
              listTitle="PAY OPTION YIELD"
              onClickFunction="payOptionYield(uint256)"
              type={approvalForm.auctionContract}
            />
            <ActionContainer
              listTitle="RETURN FUNDS"
              onClickFunction="returnLentFunds(uint256)"
              type={approvalForm.auctionContract}
            />
          </>
        )}
    </>
  );
};

interface ActionContainerProps {
  listTitle: "PAY OPTION YIELD" | "RETURN FUNDS";
  onClickFunction: "payOptionYield(uint256)" | "returnLentFunds(uint256)";
  type: "R-EARN" | "R-EARN-STETH";
}

const ActionContainer: React.FC<ActionContainerProps> = ({
  listTitle,
  onClickFunction,
  type,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [waitingSubmit, setWaitingSubmit] = useState(false);
  const submitLoadingText = useTextAnimation(waitingSubmit, {
    texts: ["SUBMIT", "SUBMITTING.", "SUBMITTING..", "SUBMITTING..."],
    interval: 250,
  });
  const { provider } = useWeb3Context();
  const { library, account } = useWeb3React();
  const RibbonEarnContract =
    type === "R-EARN"
      ? getRibbonEarnContract(library)
      : getRibbonEarnSTETHContract(library);
  const checkAccountValidity = useCallback(async () => {
    try {
      if (RibbonEarnContract) {
        if (onClickFunction === "payOptionYield(uint256)") {
          const optionSellerAddress = await RibbonEarnContract.optionSeller();
          const isNotOptionSeller = optionSellerAddress !== account;
          setIsDisabled(isNotOptionSeller);
        } else if (onClickFunction === "returnLentFunds(uint256)") {
          const isBorrower = await RibbonEarnContract.borrowerWeights(account!);
          setIsDisabled(!isBorrower?.[0]);
        }
      } else {
        setSubmitErrorMessage("ERROR GETTING RIBBON EARN CONTRACT");
        setIsDisabled(true);
      }
    } catch (err) {
      setSubmitErrorMessage("ERROR CHECKING ACCOUNT VALIDITY");
      setIsDisabled(true);
    }
  }, [account, listTitle, isDisabled]);

  useEffect(() => {
    if (isDisabled && submitErrorMessage === "") {
      // If button is disabled but there is another error message we don't want to overwrite it
      setSubmitErrorMessage("INVALID ACCOUNT");
    } else if (!isDisabled) {
      setSubmitErrorMessage("");
    }
  }, [isDisabled]);

  const handleSubmit = useCallback(async () => {
    if (parseInt(inputValue) < 0) {
      setSubmitErrorMessage("PLEASE INPUT A POSITIVE NUMBER");
      return;
    }
    setWaitingSubmit(true);
    if (RibbonEarnContract) {
      try {
        const decimals = type === "R-EARN" ? 6 : 18;
        const tx = await RibbonEarnContract[onClickFunction](
          ethers.utils.parseUnits(inputValue, decimals)
        );
        await provider.waitForTransaction(tx.hash, 5);
      } catch (err) {
        setSubmitErrorMessage("ERROR CALLING CONTRACT FUNCTION");
      } finally {
        setWaitingSubmit(false);
      }
    }
  }, [provider, onClickFunction, inputValue]);

  useEffect(() => {
    checkAccountValidity();
  }, [checkAccountValidity]);

  return (
    <>
      <ApprovalModuleContainer>
        <ContractContainer>
          <ListTitle>{listTitle}</ListTitle>
          <ListContainer style={{ minHeight: "90px" }}>
            <InputCaption>
              AMOUNT
              <ErrorMessage>
                {submitErrorMessage.length > 0 ? submitErrorMessage : ""}
              </ErrorMessage>
            </InputCaption>
            <Input
              type="number"
              className="form-control"
              aria-label="quantity"
              placeholder="0"
              min="0"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              style={{ marginBottom: "0px" }}
            ></Input>
          </ListContainer>
        </ContractContainer>
      </ApprovalModuleContainer>
      <ApprovalModuleContainer
        style={{ marginTop: "-15px", marginBottom: "70px" }}
      >
        <ApproveButton onClick={handleSubmit} disabled={isDisabled}>
          {waitingSubmit ? submitLoadingText : `SUBMIT`}
        </ApproveButton>
      </ApprovalModuleContainer>
    </>
  );
};

export default Approval;
