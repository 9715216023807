import { RibbonEarnSTETHVaultFactory } from "../codegen/RibbonEarnSTETHVaultFactory";
import deployments from "../constants/deployments.json";

export const getRibbonEarnSTETHContract = (library: any) => {
  const address = deployments.mainnet.RibbonEarnSTETHContract;

  if (library && address) {
    const provider = library.getSigner();
    return RibbonEarnSTETHVaultFactory.connect(address, provider);
  }
  return undefined;
};
