import styled from "styled-components";
import { AugmentedAuctionData, AugmentedBidData } from "../../models/auction";
import moment from "moment";
import { ExternalIcon, TimerIcon } from "../../assets/icons/icons";
import {
  formatNumber,
  formatPercent,
  formatStrikeNumber,
} from "../../utils/text";
import { resolveAuctionData, resolveClearingStatus } from "../../utils/auction";
import { useMemo } from "react";
import { getEtherscanURI, isNativeToken } from "../../constants/constants";
import { BaseLink } from "../../design";

const AuctionInformationContainer = styled.div`
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 0.5px #d1d1d1;
  margin-bottom: 20px;
`;

const MainInformation = styled.div`
  display: flex;
  height: 125px;
  padding: 17px;
  width: 100%;
  border-bottom: solid 1px #d1d1d1;
`;

const LogoContainer = styled.div<{ color: string }>`
  height: 91px;
  width: 91px;
  margin-right: 20px;
  border: solid 1px ${(props) => props.color};
  border-radius: 50%;
  display: flex;
  background-color: ${(props) => props.color}16;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Description = styled.div`
  display: block;
  margin-right: 20px;
`;

const Schedule = styled.div`
  font-size: 20px;
  font-weight: 300;
`;

const TopCaption = styled.div`
  font-size: 16px;
  font-weight: 300;
  text-align: center;
`;

const TopValue = styled.div<{ color: string }>`
  font-family: VCR;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: ${(props) => props.color};
`;

const Caption = styled.div`
  font-size: 15px;
  font-weight: 300;
  text-align: center;
`;

const Value = styled.div`
  font-family: VCR;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-top: -7px;
`;

const Timer = styled.div`
  display: flex;
  height: 100px;
  font-family: VCR;
  font-size: 30px;
  text-align: center;
  align-items: center;
  margin-top: 6px;
`;

const TimerText = styled.div<{ color: string }>`
  margin-top: -2px;
  margin-left: 10px;
  color: ${(props) => props.color};
`;

const TopAuctionInformation = styled.div`
  display: flex;
  padding: 3px;
  align-items: center;
  margin-left: auto;
`;

const TopInformation = styled.div`
  display: block;
  min-width: 90px;
  margin-bottom: 6px;
  margin-left: 60px;
`;

const DetailItem = styled.div`
  display: block;
  flex: 1 1 0;
  width: auto;
  padding: 10px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-family: VCR;
  font-size: 30px;
  margin-top: 4px;
  margin-bottom: -5px;
`;

const DetailsContainer = styled.div`
  display: flex;
  & > * {
    border-right: solid 1px #d1d1d1;

    &:last-child {
      border-right: unset;
    }
  }
`;

const AdditionalCapsule = styled.div<{ color: string }>`
  display: table;
  font-family: VCR;
  border-radius: 10px;
  background-color: ${(props) => props.color}20;
  margin: 7px auto 0px auto;
  font-size: 12px;
  color: ${(props) => props.color};
  padding: 3px 12px;
  font-weight: 500;
`;

const AuctionInformation: React.FC<{
  auctionData: AugmentedAuctionData;
  bidData: AugmentedBidData[];
  priceData: any;
  priceHistoricalData: any;
  additionalInfo: string | undefined;
  upcoming: Boolean;
}> = ({
  auctionData,
  bidData,
  priceData,
  priceHistoricalData,
  additionalInfo,
  upcoming,
}) => {
  const {
    biddingToken,
    Logo,
    logoSizeLarge,
    color,
    title,
    size,
    filled,
    clearing,
    expiry,
    strike,
    minBid,
    underlyingToken,
    oTokenAddress,
  } = resolveAuctionData(auctionData);

  const isNative = isNativeToken(biddingToken);

  const { clearingPrice, filledSize } = resolveClearingStatus(
    auctionData,
    bidData
  );

  const optimisticClear = clearing || clearingPrice;

  const optimisticFill = filled || filledSize;

  const live =
    auctionData.live && Number(auctionData.end) > Number(moment().unix());
  const now = moment().unix();

  const price =
    live || upcoming
      ? priceData[underlyingToken]
      : priceHistoricalData.searchAssetPriceFromTimestamp(
          underlyingToken,
          Number(auctionData.end) * 1000
        );

  const clock = useMemo(() => {
    const timeLeft = (Number(auctionData.end) - now) % (60 * 60);
    const minute = Math.floor(timeLeft / 60);
    const second = timeLeft % 60;
    return `${minute.toString().padStart(2, "0")}:${second
      .toString()
      .padStart(2, "0")}`;
  }, [auctionData, now]);

  const topInformationItem = (
    caption: string,
    value: string,
    color: string,
    info?: string
  ) => {
    return (
      <TopInformation>
        <TopCaption>{caption} </TopCaption>
        <TopValue color={color}>{value}</TopValue>
        {info ? (
          <AdditionalCapsule color={color}>{info}</AdditionalCapsule>
        ) : (
          <></>
        )}
      </TopInformation>
    );
  };

  const DetailComponent: React.FC<{
    caption: string;
    value: string;
  }> = ({ caption, value }) => {
    return (
      <DetailItem>
        <Caption>{caption}</Caption>
        <Value>{value}</Value>
      </DetailItem>
    );
  };

  return (
    <>
      <AuctionInformationContainer>
        <MainInformation>
          <LogoContainer color={color}>
            <Logo
              height={logoSizeLarge}
              width={logoSizeLarge}
              backgroundColor="none"
            ></Logo>
          </LogoContainer>
          <Description>
            <Title>
              {title}{" "}
              {!upcoming ? (
                <BaseLink
                  to={
                    getEtherscanURI(auctionData.chainId as number) +
                    "/address/" +
                    oTokenAddress
                  }
                  target="_blank"
                  rel="noreferrer noopener"
                  className="w-10 ml-1"
                >
                  <ExternalIcon
                    color="#383838"
                    height="25px"
                    width="25px"
                  ></ExternalIcon>
                </BaseLink>
              ) : (
                <></>
              )}
            </Title>
            <Schedule>Expiry: {expiry}</Schedule>
          </Description>
          <TopAuctionInformation>
            {
              <>
                {topInformationItem(
                  "Clearing Price (per oToken):",
                  optimisticClear
                    ? optimisticClear === "-"
                      ? "-"
                      : `${formatNumber(optimisticClear)} ${biddingToken}`
                    : "-",
                  color,
                  additionalInfo
                )}
                {topInformationItem(
                  "Filled:",
                  optimisticFill ? formatPercent(optimisticFill) + "%" : "-",
                  color
                )}
              </>
            }
            <TopInformation>
              <Timer>
                <TimerIcon color={color} height="25px" width="25px"></TimerIcon>
                <TimerText color={color}>
                  {live ? clock : !upcoming ? "CLOSED" : "--:--"}
                </TimerText>
              </Timer>
            </TopInformation>
          </TopAuctionInformation>
        </MainInformation>
        <DetailsContainer>
          <DetailComponent
            caption={upcoming ? "Est. Size (oTokens):" : "Size (oTokens):"}
            value={formatNumber(size)}
          ></DetailComponent>
          <DetailComponent
            caption="Min. Bid (per oToken):"
            value={
              parseFloat(minBid) !== 0
                ? formatNumber(minBid) + " " + biddingToken
                : "-"
            }
          ></DetailComponent>
          <DetailComponent
            caption="Strike Price:"
            value={
              parseFloat(strike) !== 0 ? "$" + formatStrikeNumber(strike) : "-"
            }
          ></DetailComponent>
          <DetailComponent
            caption={
              live || upcoming ? "Current Spot Price:" : "Closing Spot Price:"
            }
            value={"$" + formatNumber(price)}
          ></DetailComponent>
          <DetailComponent
            caption="Underlying Token:"
            value={underlyingToken}
          ></DetailComponent>
          <DetailComponent
            caption="Bidding Token:"
            value={
              isNative && biddingToken !== "WAVAX"
                ? `${biddingToken.slice(1)} / ${biddingToken}`
                : biddingToken
            }
          ></DetailComponent>
        </DetailsContainer>
      </AuctionInformationContainer>
    </>
  );
};

export default AuctionInformation;
