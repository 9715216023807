import { useCallback, useEffect, useState } from "react";
import axios from "axios";

import {
  defaultSubgraphData,
  SubgraphDataContextType,
} from "./subgraphDataContext";
import { BidsGraphql, AuctionGraphql } from "./useAuctionSubgraph";
import { usePendingTransactions } from "./pendingTransactionsContext";
import { SUBGRAPHS } from "../constants/constants";
import { isProduction, SUBGRAPH_URI } from "../utils/env";
import {
  AuctionData,
  AugmentedAuctionData,
  AugmentedBidData,
  BidData,
} from "../models/auction";
import { useGlobalState } from "../store/store";
import { getAuctionName } from "../utils/auction";
import moment from "moment";

const useFetchSubgraphData = (
  {
    poll,
    pollingFrequency,
  }: {
    poll: boolean;
    pollingFrequency: number;
  } = { poll: true, pollingFrequency: 5000 }
) => {
  const [globalAuctionId] = useGlobalState("auctionId");

  const [refreshTime] = useGlobalState("refreshTime");

  const [data, setData] =
    useState<SubgraphDataContextType>(defaultSubgraphData);
  const { transactionsCounter } = usePendingTransactions();

  const [, setMulticallCounter] = useState(0);

  const doMulticall = useCallback(async () => {
    if (!isProduction()) {
      console.time("Subgraph Data Fetch");
    }

    /**
     * We keep track with counter so to make sure we always only update with the latest info
     */
    let currentCounter: number;
    setMulticallCounter((counter) => {
      currentCounter = counter + 1;
      return currentCounter;
    });

    const allSubgraphResponses = await Promise.all(
      SUBGRAPHS.map(async (chainId) => {
        const response = await axios.post(SUBGRAPH_URI[chainId], {
          query: `{
                ${
                  globalAuctionId !== ""
                    ? `
                      ${AuctionGraphql()}
                      ${BidsGraphql(globalAuctionId)}
                    `
                    : `
                      ${AuctionGraphql()}
                      ${BidsGraphql()}
                    `
                }
            }`,
        });

        return { chainId: chainId, data: response.data.data };
      })
    );

    const updateTime = moment().unix();

    const auctions = allSubgraphResponses
      .map((data) => {
        const counter = Object();
        return data.data.auctions.map((auction: AuctionData) => {
          const augmentedAuction: any = auction;
          augmentedAuction.chainId = data.chainId;

          counter[getAuctionName(auction)]
            ? (counter[getAuctionName(auction)] += 1)
            : (counter[getAuctionName(auction)] = 1);

          augmentedAuction.index = counter[getAuctionName(auction)];
          return augmentedAuction as AugmentedAuctionData;
        });
      })
      .flat();

    let bids = [];

    bids = allSubgraphResponses
      .map((data) => {
        return data.data.bids.map((bid: BidData) => {
          const augmentedBid: any = bid;
          augmentedBid.chainId = data.chainId;
          return augmentedBid as AugmentedBidData;
        });
      })
      .flat();

    const responses = {
      auctions: auctions,
      bids: bids,
    };

    setMulticallCounter((counter) => {
      if (counter === currentCounter) {
        setData((prev) => ({
          ...prev,
          responses: responses,
          lastUpdated: updateTime,
          loading: false,
        }));
      }

      return counter;
    });

    if (!isProduction()) {
      console.timeEnd("Subgraph Data Fetch");
    }
  }, [globalAuctionId]);

  useEffect(() => {
    doMulticall();
  }, [doMulticall, globalAuctionId, transactionsCounter, refreshTime]);

  /**
   * Schedule polling
   */
  useEffect(() => {
    let pollInterval: any = undefined;

    if (poll) {
      pollInterval = setInterval(doMulticall, pollingFrequency);
    }

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [doMulticall, poll, pollingFrequency]);

  return data;
};

export default useFetchSubgraphData;
