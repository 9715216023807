import React from "react";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import styled from "styled-components";
import { ExternalIcon } from "../../assets/icons/icons";
import { BaseLink, PrimaryText, SecondaryText, Title } from "../../design";

import colors from "../../design/colors";
import theme from "../../design/theme";

const Tooltip = styled.div`
  background: ${colors.background.five};
  padding: 16px;
  border-radius: ${theme.border.radius};
  display: flex;
  flex-direction: column;
  z-index: 1100;
  // margin-top: 10px;
  // margin-left: 107px;
`;

const ExplanationTitle = styled(Title)`
  text-transform: uppercase;
`;

const Explanation = styled(SecondaryText)`
  max-width: 240px;
`;

const LearnMoreLink = styled(BaseLink)`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

interface OverlayTooltipExplanationProps {
  title?: string;
  explanation: React.ReactNode;
  learnMoreURL?: string;
  overlayInjectedProps: OverlayInjectedProps;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const OverlayTooltipExplanation: React.FC<OverlayTooltipExplanationProps> = ({
  title,
  explanation,
  learnMoreURL,
  overlayInjectedProps,
  setShow,
}) => (
  <Tooltip
    {...overlayInjectedProps}
    onMouseOver={() => setShow(true)}
    onMouseOut={() => setShow(false)}
  >
    {title && <ExplanationTitle>{title}</ExplanationTitle>}
    <Explanation>{explanation}</Explanation>
    {learnMoreURL && (
      <LearnMoreLink
        to={learnMoreURL}
        target="_blank"
        rel="noreferrer noopener"
      >
        <PrimaryText fontSize={14} className="mr-2">
          Learn More
        </PrimaryText>
        <ExternalIcon containerStyle={{ display: "flex" }} color="white" />
      </LearnMoreLink>
    )}
  </Tooltip>
);

export default OverlayTooltipExplanation;
