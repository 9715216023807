import styled from "styled-components";
import { formatUnits } from "ethers/lib/utils";
import { BigNumber } from "ethers";
import { AugmentedAuctionData, AugmentedBidData } from "../../models/auction";
import { BaseLink } from "../../design";
import { ExternalIcon } from "../../assets/icons/icons";
import { getEtherscanURI } from "../../constants/constants";
import { resolveClearingStatus } from "../../utils/auction";
import { truncateAddress } from "../../utils/address";
import colors from "../../design/colors";
import { formatNumber } from "../../utils/text";
import { getImpliedVolatility } from "../../utils/options";

const AdminFunctionContainer = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 7px;
  border: solid 2px #fc0a54;
  padding: 10px;
  min-height: 100px;
  margin-top: -18px;
  margin-bottom: 40px;
`;

const AdminFunctionDescriptionContainer = styled.div`
  font-size: 14px;
  display: flex;
  color: #8e8e8e;
  justify-content: center;
  align-items: center;
`;

const AdminText = styled.div`
  font-family: VCR;
  font-size: 20px;
  color: #fc0a54;
  margin-top: -5px;
  margin-left: 5px;
  font-weight: 700px;
`;

const EmptybidDataText = styled.div`
  font-size: 14px;
  display: flex;
  color: #8e8e8e;
  height: 40px;
  justify-content: center;
  align-items: center;
`;

const BidHistoryCaption = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-align: left;
`;

const BidHistoryLogCaption = styled.div<{ owned?: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.owned ? "#212529" : "#8D8D8D")};
  font-family: VCR;
  font-size: 12px;
  font-weight: 300;
`;

const BidHistoryTitle = styled.div<{ ratio: string }>`
  display: block;
  flex: ${(props) => props.ratio} 1 0;
  width: auto;
  padding: 15px 0px 15px 0px;
`;

const BidHistoryLogItem = styled.div<{ ratio: string }>`
  display: block;
  flex: ${(props) => props.ratio} 1 0;
  width: auto;
  padding: 0px 0px 0px 0px;
`;

const BidInformation = styled.div`
  display: block;
  flex: 1 1 0;
  width: 500px;
  margin: 20px;
`;

const BidHistoryBlock = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding-bottom: 10px;
`;

const EmptyLink = styled.div`
  margin-left: 2px;
`;

const BidHistoryTitleColumn = styled.div`
  display: flex;
  border-bottom: solid 1px #d6d6d6;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
`;

const BidHistoryLogs = styled.div<{ highlight?: string }>`
  background-color: ${(props) =>
    props.highlight ? props.highlight + "32" : "none"};
  padding: 5px 0px;
  color: #8d8d8d;
  display: flex;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
`;

export const BidInformationPage: React.FC<{
  auctionData: AugmentedAuctionData;
  bidData: AugmentedBidData[];
  priceData: any;
  priceHistoricalData: any;
}> = ({ auctionData, bidData, priceData, priceHistoricalData }) => {
  const { processedBids } = resolveClearingStatus(auctionData, bidData);

  const renderBid = (
    index: number,
    account: string,
    quantity: string,
    price: string,
    total: string,
    allocation: string,
    reimburse: string,
    symbol: string,
    create: string,
    cancel: string,
    claim: string,
    iv: string,
    accountOwned: boolean
  ) => {
    return (
      <BidHistoryLogs
        highlight={parseFloat(allocation) > 0 ? colors.green : undefined}
      >
        <BidHistoryLogItem ratio="5">
          <BidHistoryLogCaption owned={accountOwned}>
            {index}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="11">
          <BidHistoryLogCaption owned={accountOwned}>
            {truncateAddress(account)}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="8">
          <BidHistoryLogCaption owned={accountOwned}>
            {quantity}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="12">
          <BidHistoryLogCaption owned={accountOwned}>
            {price} {symbol}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="12">
          <BidHistoryLogCaption owned={accountOwned}>
            {total} {symbol}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="12">
          <BidHistoryLogCaption owned={accountOwned}>
            {allocation}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="12">
          <BidHistoryLogCaption owned={accountOwned}>
            {reimburse} {symbol}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="7">
          <BidHistoryLogCaption owned={accountOwned}>
            {iv}%
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="5">
          <BidHistoryLogCaption owned={accountOwned}>
            {create ? (
              <BaseLink
                to={
                  getEtherscanURI(auctionData.chainId as number) +
                  "/tx/" +
                  create
                }
                target="_blank"
                rel="noreferrer noopener"
                className="w-100"
              >
                <ExternalIcon
                  color={accountOwned ? "#212529" : "#8D8D8D"}
                  height="15px"
                  width="15px"
                ></ExternalIcon>
              </BaseLink>
            ) : (
              <EmptyLink>-</EmptyLink>
            )}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="5">
          <BidHistoryLogCaption owned={accountOwned}>
            {cancel ? (
              <BaseLink
                to={
                  getEtherscanURI(auctionData.chainId as number) +
                  "/tx/" +
                  cancel
                }
                target="_blank"
                rel="noreferrer noopener"
                className="w-100"
              >
                <ExternalIcon
                  color={accountOwned ? "#212529" : "#8D8D8D"}
                  height="15px"
                  width="15px"
                ></ExternalIcon>
              </BaseLink>
            ) : (
              <EmptyLink>-</EmptyLink>
            )}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
        <BidHistoryLogItem ratio="5">
          <BidHistoryLogCaption owned={accountOwned}>
            {claim ? (
              <BaseLink
                to={
                  getEtherscanURI(auctionData.chainId as number) +
                  "/tx/" +
                  claim
                }
                target="_blank"
                rel="noreferrer noopener"
                className="w-100"
              >
                <ExternalIcon
                  color={accountOwned ? "#212529" : "#8D8D8D"}
                  height="15px"
                  width="15px"
                ></ExternalIcon>
              </BaseLink>
            ) : (
              <EmptyLink>-</EmptyLink>
            )}
          </BidHistoryLogCaption>
        </BidHistoryLogItem>
      </BidHistoryLogs>
    );
  };
  return (
    <BidInformation>
      <BidHistoryBlock>
        <BidHistoryTitleColumn>
          <BidHistoryTitle ratio="5">
            <BidHistoryCaption>RANK</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="11">
            <BidHistoryCaption>BIDDER</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="8">
            <BidHistoryCaption>QUANTITY</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="12">
            <BidHistoryCaption>PRICE PER OTOKEN</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="12">
            <BidHistoryCaption>TOTAL PAYABLE</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="12">
            <BidHistoryCaption>OTOKEN QUANTITY</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="12">
            <BidHistoryCaption>REFUND</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="7">
            <BidHistoryCaption>IV</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="5">
            <BidHistoryCaption>CREATE</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="5">
            <BidHistoryCaption>CANCEL</BidHistoryCaption>
          </BidHistoryTitle>
          <BidHistoryTitle ratio="5">
            <BidHistoryCaption>CLAIM</BidHistoryCaption>
          </BidHistoryTitle>
        </BidHistoryTitleColumn>
        {processedBids.length > 0 ? (
          processedBids.map((bid, index) => {
            const quantity = formatNumber(
              formatUnits(BigNumber.from(bid.size), 8)
            );
            const payable = formatNumber(
              formatUnits(
                BigNumber.from(bid.payable),
                auctionData.bidding.decimals.toString()
              )
            );

            const allocation = formatNumber(
              formatUnits(BigNumber.from(bid.allocation), 8)
            );
            const reimburse = formatNumber(
              formatUnits(
                BigNumber.from(bid.reimburse),
                auctionData.bidding.decimals.toString()
              )
            );

            const price = formatUnits(
              BigNumber.from(bid.payable)
                .mul(10 ** 8)
                .div(bid.size),
              auctionData.bidding.decimals.toString()
            );

            const optionType = auctionData.option.put ? "put" : "call";
            const optionPriceUsd =
              optionType === "call"
                ? parseFloat(price) *
                  priceData[auctionData.option.underlying.symbol]
                : parseFloat(price);
            const optionStrikePrice = parseFloat(
              formatUnits(auctionData.option.strike, 8)
            );

            const underlyingPriceUsd =
              priceHistoricalData.searchAssetPriceFromTimestamp(
                auctionData.option.underlying.symbol,
                Number(auctionData.end) * 1000
              );

            const timeLeft = 0.019178;
            const initialEst = 0.1;
            const riskFreeRate = 0;
            const iv =
              getImpliedVolatility(
                optionPriceUsd,
                underlyingPriceUsd,
                optionStrikePrice,
                timeLeft,
                riskFreeRate,
                optionType,
                initialEst
              ) * 100;

            return renderBid(
              index + 1,
              bid.account.address,
              quantity,
              formatNumber(price),
              payable,
              allocation,
              reimburse,
              auctionData.bidding.symbol,
              bid.createtx,
              bid.canceltx,
              bid.claimtx,
              formatNumber(iv.toString()),
              true
            );
          })
        ) : (
          <EmptybidDataText>There are no bids.</EmptybidDataText>
        )}
      </BidHistoryBlock>
    </BidInformation>
  );
};

const AllBidsInformation: React.FC<{
  auctionData: AugmentedAuctionData;
  bidData: AugmentedBidData[];
  priceData: any;
  priceHistoricalData: any;
}> = ({ auctionData, bidData, priceData, priceHistoricalData }) => {
  return (
    <AdminFunctionContainer>
      <AdminText>BID INFORMATION</AdminText>
      <AdminFunctionDescriptionContainer>
        <BidInformationPage
          auctionData={auctionData}
          bidData={bidData}
          priceData={priceData}
          priceHistoricalData={priceHistoricalData}
        ></BidInformationPage>
      </AdminFunctionDescriptionContainer>
    </AdminFunctionContainer>
  );
};

export default AllBidsInformation;
