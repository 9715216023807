import { RibbonThetaVaultFactory } from "../codegen/RibbonThetaVaultFactory";
import { RibbonThetaVaultSTETHFactory } from "../codegen/RibbonThetaVaultSTETHFactory";
import {
  AuctionAddressMap,
  AuctionOptions,
  getERC20TokenAddress,
} from "../constants/constants";
import { YvUSDCFactory } from "../codegen/YvUSDCFactory";
import { StETHFactory } from "../codegen/StETHFactory";
import { StrikeSelectionFactory } from "../codegen/StrikeSelectionFactory";
import { Assets } from "../utils/asset";
import { CHAINID } from "../utils/env";
import { StakedAvaxFactory } from "../codegen/StakedAvaxFactory";
import { RETHFactory } from "../codegen/RETHFactory";

export const getVault = (
  library: any,
  auctionOption: AuctionOptions,
  useSigner: boolean = true
) => {
  if (!AuctionAddressMap[auctionOption].vault) {
    return null;
  }

  const provider = useSigner ? library.getSigner() : library;

  switch (auctionOption) {
    case "wstETH-call":
      return RibbonThetaVaultSTETHFactory.connect(
        AuctionAddressMap[auctionOption].vault!,
        provider
      );
    default:
      return RibbonThetaVaultFactory.connect(
        AuctionAddressMap[auctionOption].vault!,
        provider
      );
  }
};

export const getSteth = (library: any) => {
  return StETHFactory.connect(
    getERC20TokenAddress("wsteth" as Assets, 1),
    library
  );
};

export const getReth = (library: any) => {
  return RETHFactory.connect(
    getERC20TokenAddress("reth" as Assets, 1),
    library
  );
};

export const getYvusdc = (library: any) => {
  return YvUSDCFactory.connect(
    getERC20TokenAddress("yvusdc" as Assets, 1),
    library
  );
};

export const getSAVAX = (library: any) => {
  return StakedAvaxFactory.connect(
    getERC20TokenAddress("savax" as Assets, CHAINID.AVAX_MAINNET),
    library
  );
};

export const getStrikeSelection = (
  library: any,
  auctionOption: AuctionOptions
) => {
  return StrikeSelectionFactory.connect(
    AuctionAddressMap[auctionOption].strikeSelection,
    library
  );
};
