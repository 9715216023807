export const copyTextToClipboard = (text: string) => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  if (window.navigator.platform === "iPhone") {
    textField.setSelectionRange(0, 99999);
  } else {
    textField.select();
  }
  document.execCommand("copy");
  textField.remove();
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.substring(1);

const numberWithCommas = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatStrikeNumber = (x: string) => {
  const num = parseFloat(x);
  if (num < 1) {
    return num.toFixed(4);
  } else if (num % 1 !== 0) {
    return num.toFixed(1);
  } else if (num > 1000) {
    return numberWithCommas(num.toFixed(0));
  } else {
    return num.toFixed(0);
  }
};

export const formatStrikeTitle = (x: string) => {
  const num = parseFloat(x);
  if (num < 1) {
    return num.toFixed(2);
  } else if (num % 1 !== 0) {
    return num.toFixed(1);
  } else {
    return num.toFixed(0);
  }
};

export const formatNumber = (x: string) => {
  const num = parseFloat(x);
  if (num === 0) {
    return num.toFixed(0);
  } else if (num < 0.0001) {
    return num.toFixed(8);
  } else if (num < 0.01) {
    return num.toFixed(6);
  } else if (num < 1) {
    return num.toFixed(4);
  } else if (num < 1000 && num < 100000) {
    return num.toFixed(2);
  } else if (num >= 1000 && num < 100000) {
    return numberWithCommas(num.toFixed(2));
  } else if (num >= 100000000000) {
    return "Inf";
  } else {
    return numberWithCommas(num.toFixed(0));
  }
};

export const formatPercent = (x: string) => {
  const num = parseFloat(x);
  if (num < 0.01) {
    return "~0";
  } else if (num < 1) {
    return num.toFixed(2);
  } else if (num < 100) {
    return num.toFixed(1);
  } else {
    return num.toFixed(0);
  }
};
