import AuctionItem from "../../components/Auction/AuctionItem";
import styled from "styled-components";
import useTextAnimation from "../../hooks/useTextAnimation";
import LiveAuctionItem from "../../components/Auction/LiveAuctionItem";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useSubgraphData } from "../../hooks/subgraphDataContext";
import {
  AuctionOptions,
  getAuctionChain,
  isParadigmAuction,
  KEEPERS,
  SCHEDULE,
} from "../../constants/constants";
import {
  constructUpcomingAuction,
  getAuctionName,
  getAuctionOption,
  getScheduledTime,
} from "../../utils/auction";
import Pagination from "../../components/Common/Pagination";
import { useVaultData } from "../../hooks/web3DataContext";
import { useHistory } from "react-router-dom";
import { useGlobalState } from "../../store/store";
import LiveIndicator from "../../components/Indicator/Live";
import { AnimatePresence, motion } from "framer-motion";
import { useWeb3React } from "@web3-react/core";
import PremiumsExporter from "../../components/Admin/PremiumsExporter";
import { CHAINID } from "../../utils/env";

const LiveAuctionOuterContainer = styled(motion.div)`
  background-color: #e9fff6;
  width: calc(100% + 2 * 30px);
  border-radius: 7px;
  padding: 15px 30px 30px 30px;
  margin: 0px -30px;
  min-height: 165px;
  margin-bottom: 40px;
  & > * {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: unset;
    }
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
`;

const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  position: relative;
  width: 35px;
  height: 35px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const ListContainer = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 7px;
  padding: 30px;
  min-height: 135px;
  margin-bottom: 40px;

  & > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: unset;
    }
  }
`;

const ListTitle = styled.div`
  text-transform: uppercase;
  color: #646464;
  font-family: VCR;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
`;

const LoadingContainer = styled.div`
  font-family: VCR;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  color: #8e8e8e;
  height: 75px;
  justify-content: center;
  align-items: center;
`;

const Homepage = () => {
  const history = useHistory();
  const { active, account } = useWeb3React();
  const { data: responses, loading: subgraphLoading } = useSubgraphData();
  const { data: vaultData, loading: vaultLoading } = useVaultData();
  const [page, setPage] = useGlobalState("page");
  const loading = subgraphLoading || vaultLoading;

  const time = moment().utc().unix();

  const populateUpcomingAuctions = useMemo(() => {
    return Object.keys(SCHEDULE).map((auction) => {
      return constructUpcomingAuction(auction as AuctionOptions, vaultData);
    });
  }, [vaultData]);

  const renderLiveAuction = useCallback(() => {
    const liveAuction = responses.auctions.filter((value) => {
      const auctionOption = getAuctionOption(
        getAuctionName(value)
      ) as AuctionOptions;
      var scheduledTime = [0, 0];
      try {
        scheduledTime = getScheduledTime(auctionOption);
      } catch {}
      
      return (
        value.live &&
        (Number(value.end) >= Number(moment().unix()) ||
          (isParadigmAuction(auctionOption) &&
            Number(scheduledTime[1]) >= Number(moment().unix()) &&
            Number(scheduledTime[0]) <= Number(moment().unix())))
      );
    });

    return liveAuction.length > 0 ? (
      <AnimatePresence>
        <LiveAuctionOuterContainer
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 0, opacity: 0 }}
          transition={{
            duration: 0.4,
            type: "keyframes",
            ease: "easeInOut",
          }}
        >
          <TitleContainer>
            <Title>LIVE</Title>
            <IndicatorContainer>
              <LiveIndicator></LiveIndicator>
            </IndicatorContainer>
          </TitleContainer>
          {liveAuction.map((auction) => {
            return (
              <LiveAuctionItem
                auctionData={auction}
                bidData={responses.bids.filter((value) => {
                  return (
                    value.auction ===
                      (Number(auction.id.split("_")[0]) as number) &&
                    value.chainId === auction.chainId
                  );
                })}
                onClick={(link: string) => {
                  history.push(link);
                }}
              ></LiveAuctionItem>
            );
          })}
        </LiveAuctionOuterContainer>
      </AnimatePresence>
    ) : (
      <></>
    );
  }, [responses, history]);

  const renderRecentAuction = useCallback(() => {
    const recentAuctions = responses.auctions.filter((value) => {
      return !value.live || Number(value.end) < Number(moment().unix());
    });

    recentAuctions.sort((a, b) => {
      return Number(a.end) - Number(b.end);
    });

    const dates = recentAuctions
      .reverse()
      .map((value) => {
        const endtime = Number(value.end);
        return endtime - (endtime % (7 * 24 * 60 * 60)) + 1 * 24 * 60 * 60;
      })
      .filter((value, index, array) => {
        return array.indexOf(value) === index;
      });

    return recentAuctions.length > 0 ? (
      <>
        {recentAuctions
          .filter((value) => {
            const endtime = Number(value.end);
            const date =
              endtime - (endtime % (7 * 24 * 60 * 60)) + 1 * 24 * 60 * 60;
            return date === dates[page - 1];
          })
          .map((data) => {
            return (
              <AuctionItem
                variant="concluded"
                key={data.id + data.chainId + data.option.symbol}
                auctionData={data}
                bidData={responses.bids.filter((value) => {
                  return value.auction === Number(data.id);
                })}
                onClick={(link: string) => {
                  history.push(link);
                }}
                account={account!}
              ></AuctionItem>
            );
          })}
        <PaginationContainer>
          <Pagination page={page} total={dates.length} setPage={setPage} />
        </PaginationContainer>
      </>
    ) : (
      <LoadingContainer>{"There are no recent auctions"}</LoadingContainer>
    );
  }, [responses, page, setPage, history, account]);

  const renderUpcomingAuction = useCallback(() => {
    const allAuctionTitles = responses.auctions.map((auction) => {
      return getAuctionName(auction);
    });

    const upcomingAuctions = populateUpcomingAuctions.filter((auction) => {
      const startUnix = moment
        .unix(parseInt(auction.start))
        .utc()
        .hour(23)
        .minute(59)
        .unix();

      const endUnix = moment.unix(parseInt(auction.end)).utc().unix();
      const auctionOption = getAuctionOption(getAuctionName(auction));

      return (
        !allAuctionTitles.includes(getAuctionName(auction)) &&
        startUnix - time < 7 * 86400 &&
        !(auctionOption === "SOL-call" && endUnix <= time)
      );
    });

    upcomingAuctions.sort((a, b) => {
      return Number(a.start) - Number(b.start);
    });

    return upcomingAuctions.length > 0 ? (
      upcomingAuctions.map((data) => {
        const auctionOption = getAuctionOption(
          getAuctionName(data)
        ) as AuctionOptions;

        let label = isParadigmAuction(auctionOption) ? "paradigm" : undefined;
        label =
          getAuctionChain(auctionOption) === CHAINID.SOL ? "zetaflex" : label;

        return (
          <AuctionItem
            key={data.option.symbol}
            variant="upcoming"
            auctionData={data}
            bidData={[]}
            onClick={(link: string) => {
              history.push(link);
            }}
            label={label}
          ></AuctionItem>
        );
      })
    ) : (
      <LoadingContainer>{"There are no upcoming auctions"}</LoadingContainer>
    );
  }, [populateUpcomingAuctions, time, responses.auctions, history]);

  const loadingText = useTextAnimation();
  return (
    <>
      {!loading && renderLiveAuction()}
      <>
        <ListTitle>Upcoming Auctions</ListTitle>
        <ListContainer>
          {!loading ? (
            renderUpcomingAuction()
          ) : (
            <LoadingContainer>{loadingText}</LoadingContainer>
          )}
        </ListContainer>
      </>

      <>
        <ListTitle>Recent Auctions</ListTitle>
        <ListContainer>
          {!loading ? (
            renderRecentAuction()
          ) : (
            <LoadingContainer>{loadingText}</LoadingContainer>
          )}
        </ListContainer>
      </>
      {active && KEEPERS.includes(account!) ? (
        <PremiumsExporter></PremiumsExporter>
      ) : (
        <></>
      )}
    </>
  );
};

export default Homepage;
