import { useWeb3React } from "@web3-react/core";
import React, { useCallback, useMemo, useState } from "react";
import { ArrowIcon, ExternalIcon } from "../../assets/icons/icons";
import styled from "styled-components";
import theme from "../../design/theme";
import useTextAnimation from "../../hooks/useTextAnimation";
import { Assets } from "../../utils/asset";
import { formatUnits } from "ethers/lib/utils";
import { BigNumber, ethers } from "ethers";
import {
  gasLimit,
  getEtherscanURI,
  getGnosisAuction,
  isEthNetwork,
  isNativeToken,
  NETWORK_ALT_DESCRIPTION,
} from "../../constants/constants";
import { useUserBalance } from "../../hooks/web3DataContext";
import useVaultActionForm from "../../hooks/useVaultActionForm";
import useTokenAllowance from "../../hooks/useTokenAllowance";
import { usePendingTransactions } from "../../hooks/pendingTransactionsContext";
import { getERC20Token } from "../../hooks/useERC20Token";
import { useWeb3Context } from "../../hooks/web3Context";
import useAuction from "../../hooks/useAuction";
import { AugmentedAuctionData } from "../../models/auction";
import { resolveAuctionData } from "../../utils/auction";
import { BaseLink, Title } from "../../design";
import { truncateAddress } from "../../utils/address";
import { AnimatePresence, motion } from "framer";
import ButtonArrow from "../Common/ButtonArrow";
import useGasPrice from "../../hooks/useGasPrice";
import { switchChains } from "../../utils/switch";
import { CHAINID } from "../../utils/env";

const BidButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const WrongNetworkDescriptionContainer = styled.div`
  font-size: 14px;
  height: 130px;
  display: flex;
  color: #8e8e8e;
  justify-content: center;
  align-items: center;
`;

const ChangeNetworkButton = styled.button`
  font-family: VCR;
  font-size: 15px;
  color: #ffffff;
  background-color: #424242;
  border-radius: 5px;
  border: none;
  padding: 9px 20px 10px 20px;
  line-height: 20px;
  vertical-align: text-top;

  &:hover {
    opacity: ${theme.hover.opacity};
  }
`;

const WrongNetworkText = styled.div`
  font-family: VCR;
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  margin-bottom: 5px;
`;

const BidButton = styled.button`
  font-family: VCR;
  font-size: 15px;
  color: #ffffff;
  background-color: #424242;
  border-radius: 5px;
  border: none;
  padding: 9px 20px 10px 20px;
  line-height: 20px;
  vertical-align: text-top;
  height: 41px;
  width: 100%;

  &:hover {
    opacity: ${theme.hover.opacity};
  }

  &:disabled {
    opacity: ${theme.hover.opacity};
  }
`;

const ContractLinkButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Description = styled.div`
  font-family: VCR;
  font-size: 14px;
  display: flex;
  color: #8e8e8e;
  height: 70px;
  justify-content: center;
  align-items: center;
`;

const ContractLinkButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: VCR;
  font-size: 15px;
  background-color: #42424216;
  color: #424242;
  border-radius: 10px;
  border: none;
  padding: 9px 20px 10px 20px;
  line-height: 20px;
  vertical-align: text-top;
  height: 41px;
  width: 100%;

  &:hover {
    opacity: ${theme.hover.opacity};
  }

  &:disabled {
    opacity: ${theme.hover.opacity};
  }
`;

const BiddingModal = styled.div`
  display: block;
  width: 400px;
`;
const TopInputBlock = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding: 25px 30px 30px 30px;

  & > * {
    &:nth-child(3) {
      margin-top: 15px;
    }
  }
`;

const ApprovalBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 25px 30px 30px 30px;
  height: 393.5px;
`;

const WrongNetworkBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 25px 30px 30px 30px;
  height: 130px;
`;

const BottomInputBlock = styled.div`
  margin-top: 25px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 25px 30px 30px 30px;
`;

const InputCaption = styled.div`
  display: flex;
  font-family: VCR;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 5px;
  color: #464646;
  font-size: 14px;
  font-weight: 600;
`;

const Input = styled.input`
  font-family: VCR;
  border-radius: 5px;
  border: solid 1px #c1c1c1;
  width: 100%;
  height: 50px;
  padding: 10px;
  font-size: 24px;

  &::placeholder {
    opacity: 0.2;
  }
`;

const ApprovalText = styled.div`
  text-align: center;
`;

const InputDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TradeSymbol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  background-color: #ffffff;
  border: solid 1px #d3d3d3;
  height: 50px;
  width: 50px;
  margin-top: -17px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -40px;
  z-index: 1000;
`;

const BalanceText = styled.div`
  margin-top: 3px;
  color: #8e8e8e;
  font-size: 13px;
`;

const MaxButton = styled.button`
  font-family: VCR;
  font-size: 14px;
  position: absolute;
  background-color: unset;
  border: unset;
  right: 0;
  height: 50px;
  color: #8d8d8d;
  padding: 0px 15px;
`;

const Error = styled.span`
  margin-left: auto;
  color: red;
`;

const LogoContainer = styled.div<{ color: string }>`
  height: 91px;
  min-width: 91px;
  border-radius: 50%;
  display: flex;
  background-color: ${(props) => props.color}16;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const BiddingAssetContainer = styled.div`
  display: flex;
  position: relative;
  height: 50px;
  width: 100px;
  align-items: center;
  justify-content: center;
`;

const DepositAssetButton = styled.div<{
  color: string;
  position: string;
}>`
  position: ${(props) => props.position};
  right: 0;
  height: 35px;
  background-color: ${(props) => props.color}08;
  border-radius: 20px;
  margin: 7.5px;
`;

const Caption = styled.div`
  font-family: VCR;
  margin-bottom: 13px;
  text-align: center;
  color: #464646;
  font-size: 20px;
  line-height: 16px;
  font-weight: 600;
`;

const DepositAssetsDropdown = styled(motion.div)<{
  isOpen: boolean;
}>`
  ${(props) =>
    props.isOpen
      ? `
          position: absolute;
          z-index: 2000;
          width: fit-content;
          border-radius: 20px;
          background-color: #ffffff;
          top: 36px;
          left: 3px;
        `
      : `
          display: none;
        `}
`;

const DepositAssetsDropdownItem = styled.div<{
  color: string;
  active: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  opacity: 1;
  border-radius: 100px;
  background: ${(props) => `${props.color}20`};
  color: ${(props) => `${props.color}`};
  font-weight: 700;
  border: ${theme.border.width} ${theme.border.style} transparent;
  transition: border 150ms;

  &:last-child {
    margin-bottom: 0px;
  }

  ${(props) => {
    if (props.active) {
      return `
        opacity: 1;
        border: ${theme.border.width} ${theme.border.style} ${props.color};
      `;
    }
    return `
      &:hover {
        opacity: 1;
        border: ${theme.border.width} ${theme.border.style} ${props.color}50;
      }
    `;
  }}
`;

const BidModule: React.FC<{
  auctionData: AugmentedAuctionData;
}> = ({ auctionData }) => {
  const { account, chainId: currentChainId, library } = useWeb3React();

  const {
    biddingToken,
    biddingTokenDecimals,
    logoSizeLarge,
    BiddingLogo,
    biddingTokenColor,
    minBid,
  } = resolveAuctionData(auctionData);

  const {
    handlePayableChange,
    handlePriceChange,
    handleSizeChange,
    handleMaxClick,
    auctionActionForm,
    resetActionForm,
  } = useVaultActionForm(auctionData);

  const { data: balances, loading: balanceLoading } = useUserBalance();
  const isNative = isNativeToken(biddingToken);
  const [useNative, setUseNative] = useState(isNative);
  const [showDepositAssetMenu, setShowDepositAssetMenu] = useState(false);
  const gasPrice = useGasPrice();
  const loading = balanceLoading;

  const { addPendingTransaction } = usePendingTransactions();
  const { provider } = useWeb3Context();

  const tokenContract = useMemo(() => {
    try {
      return getERC20Token(
        library,
        biddingToken.toLowerCase() as Assets,
        auctionData.chainId
      );
    } catch {
      return undefined;
    }
  }, [auctionData.chainId, library, biddingToken]);

  const gnosisContract = useAuction(useNative);

  const [waitingApproval, setWaitingApproval] = useState(false);
  const approveLoadingText = useTextAnimation(waitingApproval, {
    texts: ["APPROVING", "APPROVING.", "APPROVING..", "APPROVING..."],
    interval: 250,
  });

  const [waitingPlaceBid, setWaitingPlaceBid] = useState(false);
  const placeBidLoadingText = useTextAnimation(waitingPlaceBid, {
    texts: ["PLACING BID", "PLACING BID.", "PLACING BID..", "PLACING BID..."],
    interval: 250,
  });

  const handleApproveToken = useCallback(async () => {
    setWaitingApproval(true);

    if (tokenContract) {
      const amount =
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

      try {
        const tx = await tokenContract.approve(
          getGnosisAuction(auctionData.chainId),
          amount
        );
        await provider.waitForTransaction(tx.hash, 5);
      } catch (err) {
      } finally {
        setWaitingApproval(false);
      }
    }
  }, [provider, tokenContract, auctionData.chainId]);

  const renderBiddingAsset = useCallback(
    (selector: boolean, position: string) => {
      return (
        <>
          <DepositAssetButton
            color={selector ? "#000000" : "#ffffff"}
            role={selector ? "button" : ""}
            position={position}
            onClick={() =>
              selector ? setShowDepositAssetMenu((show) => !show) : {}
            }
          >
            <div className="d-flex w-100 h-100 align-items-center position-relative p-1">
              <DepositAssetsDropdownItem
                color={biddingTokenColor}
                active={true}
              >
                <Title fontSize={11} lineHeight={16} lowercase={true}>
                  {useNative ? biddingToken.slice(1) : biddingToken}
                </Title>
              </DepositAssetsDropdownItem>

              {selector ? (
                <>
                  <div className="d-flex flex-grow-1 justify-content-center ml-1 mr-1">
                    <ButtonArrow
                      color={biddingTokenColor}
                      isOpen={showDepositAssetMenu}
                      fontSize={10}
                    />
                  </div>
                  <AnimatePresence>
                    <DepositAssetsDropdown
                      key={showDepositAssetMenu.toString()}
                      isOpen={showDepositAssetMenu}
                      initial={{
                        opacity: 0,
                        y: 20,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                      }}
                      exit={{
                        opacity: 0,
                        y: 20,
                      }}
                      transition={{
                        type: "keyframes",
                        duration: 0.2,
                      }}
                    >
                      {
                        <DepositAssetsDropdownItem
                          color={biddingTokenColor}
                          active={false}
                          onClick={() => setUseNative((show) => !show)}
                        >
                          <Title fontSize={11} lineHeight={16}>
                            {!useNative ? biddingToken.slice(1) : biddingToken}
                          </Title>
                        </DepositAssetsDropdownItem>
                      }
                    </DepositAssetsDropdown>
                  </AnimatePresence>
                </>
              ) : (
                <></>
              )}
            </div>
          </DepositAssetButton>
        </>
      );
    },
    [biddingTokenColor, biddingToken, useNative, showDepositAssetMenu]
  );

  const handleSwitchChain = useCallback(
    async (chainId: number) => {
      if (library && currentChainId !== chainId) {
        await switchChains(library, chainId);
      }
    },
    [library, currentChainId]
  );

  const priceError = useMemo(() => {
    if (
      auctionActionForm.price !== "" &&
      Number(auctionActionForm.price) <= Number(minBid)
    ) {
      return {
        error: true,
        msg: "MIN. BID NOT MET",
      };
    } else {
      return {
        error: false,
        msg: "",
      };
    }
  }, [auctionActionForm, minBid]);

  const quantityError = useMemo(() => {
    if (
      Number(auctionActionForm.quantity) * 10 ** 8 >
      Number(auctionData.size.toString())
    ) {
      return {
        error: true,
        msg: "EXCEEDS AMOUNT AVAILABLE",
      };
    } else {
      return {
        error: false,
        msg: "",
      };
    }
  }, [auctionActionForm, auctionData]);

  const payableError = useMemo(() => {
    if (!loading && balances[biddingToken] && balances["native"]) {
      const userBalance = useNative
        ? balances["native"].balance
        : balances[biddingToken].balance;
      if (
        Number(auctionActionForm.payable) >
        Number(ethers.utils.formatUnits(userBalance, biddingTokenDecimals))
      ) {
        return {
          error: true,
          msg: "INSUFFICIENT BALANCE",
        };
      } else {
        return {
          error: false,
          msg: "",
        };
      }
    } else {
      return {
        error: false,
        msg: "",
      };
    }
  }, [
    auctionActionForm,
    balances,
    biddingToken,
    loading,
    useNative,
    biddingTokenDecimals,
  ]);

  const completeError = useMemo(() => {
    if (!loading) {
      if (
        Number(auctionActionForm.price) === 0 ||
        Number(auctionActionForm.payable) === 0 ||
        Number(auctionActionForm.quantity) === 0
      ) {
        return {
          error: true,
          msg: "",
        };
      } else {
        return {
          error: false,
          msg: "",
        };
      }
    } else {
      return {
        error: false,
        msg: "",
      };
    }
  }, [auctionActionForm, loading]);

  const error = useMemo(() => {
    return (
      payableError.error ||
      completeError.error ||
      quantityError.error ||
      priceError.error
    );
  }, [payableError, completeError, quantityError, priceError]);

  const handlePlaceOrder = useCallback(async () => {
    if (gnosisContract && !loading) {
      setWaitingPlaceBid(true);

      const options =
        gasPrice !== "0" && isEthNetwork(auctionData.chainId)
          ? {
              gasPrice: BigNumber.from(gasPrice).add(
                ethers.utils.parseUnits("10", "gwei")
              ),
              gasLimit: gasLimit[biddingToken],
            }
          : {
              gasLimit: gasLimit[biddingToken],
            };

      try {
        const tx = !useNative
          ? await gnosisContract.placeSellOrders(
              auctionData.id.split("_")[0],
              [ethers.utils.parseUnits(auctionActionForm.quantity, 8)],
              [
                ethers.utils.parseUnits(
                  auctionActionForm.payable,
                  biddingTokenDecimals
                ),
              ],
              [
                "0x0000000000000000000000000000000000000000000000000000000000000001",
              ],
              "0x",
              options
            )
          : await gnosisContract.depositAndPlaceOrder(
              auctionData.id.split("_")[0],
              [ethers.utils.parseUnits(auctionActionForm.quantity, 8)],
              [
                "0x0000000000000000000000000000000000000000000000000000000000000001",
              ],
              "0x",
              {
                ...options,
                value: ethers.utils.parseUnits(
                  auctionActionForm.payable,
                  biddingTokenDecimals
                ),
              }
            );

        const txhash = tx.hash;

        addPendingTransaction({
          txhash,
          type: "approval",
        });
        resetActionForm();
      } catch (err) {
        console.log(err);
      } finally {
        setWaitingPlaceBid(false);
      }
    }
  }, [
    loading,
    gnosisContract,
    gasPrice,
    auctionActionForm,
    biddingToken,
    auctionData,
    addPendingTransaction,
    biddingTokenDecimals,
    resetActionForm,
    useNative,
  ]);

  const allowance = useTokenAllowance(
    biddingToken,
    getGnosisAuction(auctionData.chainId)
  );
  const allowed = useMemo(() => {
    return useNative || Number(allowance) > 1e30;
  }, [allowance, useNative]);

  const walletBalance = useMemo(() => {
    return !loading && balances[biddingToken] && balances["native"]
      ? useNative
        ? `${parseFloat(
            formatUnits(balances["native"].balance, biddingTokenDecimals)
          ).toFixed(4)} ${biddingToken.slice(1)}`
        : `${parseFloat(
            formatUnits(balances[biddingToken].balance, biddingTokenDecimals)
          ).toFixed(4)} ${biddingToken}`
      : "LOADING...";
  }, [balances, loading, useNative, biddingToken, biddingTokenDecimals]);

  return account && currentChainId === auctionData.chainId ? (
    <BiddingModal>
      <Caption>SUBMIT BID</Caption>
      {!allowed ? (
        <ApprovalBlock>
          <LogoContainer
            color={biddingTokenColor}
            className={isNative ? "mb-1" : "mb-5"}
          >
            <BiddingLogo
              height={logoSizeLarge}
              width={logoSizeLarge}
              backgroundColor="none"
            ></BiddingLogo>
          </LogoContainer>
          {isNative ? (
            <BiddingAssetContainer className="mb-4">
              {renderBiddingAsset(true, "")}
            </BiddingAssetContainer>
          ) : (
            <></>
          )}
          <ApprovalText className="mt-2 mb-3">
            Before you place a bid, Gnosis Auction requires your permission to
            spend your {biddingToken}.
          </ApprovalText>
          <ContractLinkButtonContainer>
            <BaseLink
              to={
                getEtherscanURI(auctionData.chainId) +
                "/address/" +
                getGnosisAuction(auctionData.chainId)
              }
              target="_blank"
              rel="noreferrer noopener"
              className="w-100"
            >
              <ContractLinkButton>
                {"GNOSIS CONTRACT:" +
                  truncateAddress(
                    getGnosisAuction(auctionData.chainId).toUpperCase()
                  )}
                <ExternalIcon
                  className="ml-1"
                  color="black"
                  height="20px"
                  width="20px"
                ></ExternalIcon>
              </ContractLinkButton>
            </BaseLink>
          </ContractLinkButtonContainer>
        </ApprovalBlock>
      ) : (
        <>
          {biddingToken === "sAVAX" && (
            <div
              style={{
                marginBottom: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#11BAF0",
                }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://traderjoexyz.com/trade?inputCurrency=AVAX&outputCurrency=0x2b2c81e08f1af8835a78bb2a90ae924ace0ea4be#/"
              >
                Swap your AVAX to sAVAX on Trader JOE to bid
                <ExternalIcon
                  style={{ marginLeft: 6 }}
                  color="#11BAF0"
                ></ExternalIcon>
              </a>
            </div>
          )}

          <TopInputBlock>
            <InputCaption>
              OTOKEN QUANTITY
              <Error>{quantityError.error ? quantityError.msg : ""}</Error>
            </InputCaption>
            <InputDiv>
              <Input
                disabled={!allowed}
                type="number"
                className="form-control"
                aria-label="quantity"
                placeholder="0"
                value={auctionActionForm.quantity}
                onChange={handleSizeChange}
              ></Input>
              <MaxButton onClick={handleMaxClick} disabled={!allowed}>
                MAX
              </MaxButton>
            </InputDiv>
            <InputCaption>
              PRICE PER OTOKEN
              <Error>{priceError.error ? priceError.msg : ""}</Error>
            </InputCaption>
            <InputDiv>
              <Input
                disabled={!allowed}
                type="number"
                className="form-control"
                aria-label="price"
                placeholder="0"
                value={auctionActionForm.price}
                onChange={handlePriceChange}
              ></Input>
              {renderBiddingAsset(false, "absolute")}
            </InputDiv>
          </TopInputBlock>
          <TradeSymbol>
            <ArrowIcon color="#2e2e2e"></ArrowIcon>
          </TradeSymbol>
          <BottomInputBlock>
            <InputCaption>
              TOTAL PAYABLE{" "}
              <Error>{payableError.error ? payableError.msg : ""}</Error>
            </InputCaption>
            <InputDiv>
              <Input
                disabled={!allowed}
                type="number"
                className="form-control"
                aria-label="payable"
                placeholder="0"
                value={auctionActionForm.payable}
                onChange={handlePayableChange}
              ></Input>

              {renderBiddingAsset(isNative, "absolute")}
            </InputDiv>
            <BalanceText>{"Wallet Balance: " + walletBalance}</BalanceText>
          </BottomInputBlock>
        </>
      )}
      <BidButtonContainer>
        <BidButton
          onClick={allowed ? handlePlaceOrder : handleApproveToken}
          disabled={
            waitingApproval || waitingPlaceBid || (allowed && error) || loading
          }
        >
          {allowed
            ? waitingPlaceBid
              ? placeBidLoadingText
              : "PLACE BID"
            : waitingApproval
            ? approveLoadingText
            : `APPROVE ${biddingToken}`}
        </BidButton>
      </BidButtonContainer>
    </BiddingModal>
  ) : (
    <BiddingModal>
      <Caption>SUBMIT BID</Caption>
      <WrongNetworkBlock>
        {!currentChainId ? (
          <Description>CONNECT WALLET</Description>
        ) : (
          <WrongNetworkDescriptionContainer>
            <div>
              <WrongNetworkText>Wrong Network</WrongNetworkText>
              <ChangeNetworkButton
                onClick={() => handleSwitchChain(auctionData.chainId as number)}
              >
                CONNECT TO{" "}
                {NETWORK_ALT_DESCRIPTION[auctionData.chainId as CHAINID]}
              </ChangeNetworkButton>
            </div>
          </WrongNetworkDescriptionContainer>
        )}
      </WrongNetworkBlock>
    </BiddingModal>
  );
};

export default BidModule;
