import { CHAINID, isDevelopment } from "../utils/env";
import deployment from "./deployments.json";
import addresses from "./externals.json";
import schedule from "./schedule.json";
import { Assets } from "../utils/asset";
import { AuctionContracts } from "../utils/auction";

export const SCHEDULE: Partial<{
  [auction in AuctionOptions]: {
    frequency: string;
    start: string;
    end: string;
  };
}> = {
  "SOL-call": schedule["sol-call"],
  "rETH-call": schedule["reth-call"],
  "wstETH-call": schedule["steth-call"],
  "WETH-call": schedule["eth-call"],
  "USDC-put": schedule["eth-put"],
  "WAVAX-call": schedule["avax-call"],
  "sAVAX-call": schedule["savax-call"],
  // "USDC.e-put": schedule["avax-put"],
  "PERP-call": schedule["perp-call"],
  "WBTC-call": schedule["wbtc-call"],
  "AAVE-call": schedule["aave-call"],
  "UNI-call": schedule["uni-call"],
  "BAL-call": schedule["bal-call"],
  "SPELL-call": schedule["spell-call"],
  "SPELLTWO-call": schedule["spelltwo-call"],
  "BADGER-call": schedule["badger-call"],
  "SAMB-call": schedule["samb-call"],
};

export type NETWORK_NAMES =
  | "mainnet"
  | "kovan"
  | "fuji"
  | "avax"
  | "binance"
  | "binanceTestnet"
  | "sol";

export const NETWORKS: Record<number, NETWORK_NAMES> = {
  [CHAINID.ETH_MAINNET]: "mainnet",
  [CHAINID.ETH_KOVAN]: "kovan",
  [CHAINID.AVAX_FUJI]: "fuji",
  [CHAINID.AVAX_MAINNET]: "avax",
  [CHAINID.BINANCE_MAINNET]: "binance",
  [CHAINID.BINANCE_TESTNET]: "binanceTestnet",
  [CHAINID.SOL]: "sol",
};

export const READABLE_NETWORK_NAMES: Record<CHAINID, string> = {
  [CHAINID.ETH_MAINNET]: "Ethereum",
  [CHAINID.ETH_KOVAN]: "Kovan Testnet",
  [CHAINID.AVAX_MAINNET]: "Avalanche",
  [CHAINID.AVAX_FUJI]: "Fuji Testnet",
  [CHAINID.BINANCE_MAINNET]: "Binance",
  [CHAINID.BINANCE_TESTNET]: "Binance Testnet",
  [CHAINID.SOL]: "Solana",
};

export const NETWORK_ALT_DESCRIPTION: Record<CHAINID, string> = {
  [CHAINID.ETH_MAINNET]: "ETHEREUM MAINNET",
  [CHAINID.ETH_KOVAN]: "KOVAN TESTNET",
  [CHAINID.AVAX_MAINNET]: "AVALANCHE MAINNET",
  [CHAINID.AVAX_FUJI]: "FUJI TESTNET",
  [CHAINID.BINANCE_MAINNET]: "BINANCE MAINNET",
  [CHAINID.BINANCE_TESTNET]: "BINANCE TESTNET",
  [CHAINID.SOL]: "SOLANA",
};

export const CHAINID_TO_NATIVE_TOKENS: Record<CHAINID, Assets> = {
  [CHAINID.ETH_MAINNET]: "WETH",
  [CHAINID.ETH_KOVAN]: "WETH",
  [CHAINID.AVAX_MAINNET]: "WAVAX",
  [CHAINID.AVAX_FUJI]: "WAVAX",
  [CHAINID.BINANCE_MAINNET]: "WBNB",
  [CHAINID.BINANCE_TESTNET]: "WBNB",
  [CHAINID.SOL]: "SOL",
};

export const isEthNetwork = (chainId: number): boolean =>
  chainId === CHAINID.ETH_MAINNET || chainId === CHAINID.ETH_KOVAN;

export const isEthMainnet = (chainId: number): boolean =>
  chainId === CHAINID.ETH_MAINNET;

export const isAvaxNetwork = (chainId: number): boolean =>
  chainId === CHAINID.AVAX_MAINNET || chainId === CHAINID.AVAX_FUJI;

export const isBinanceNetwork = (chainId: number): boolean =>
  chainId === CHAINID.BINANCE_MAINNET;

export const isEthAuction = (vault: string) =>
  isEthNetwork(AuctionAddressMap[vault as AuctionOptions].chainId);

export const isAvaxAuction = (vault: string) =>
  isAvaxNetwork(AuctionAddressMap[vault as AuctionOptions].chainId);

export const NATIVE_TOKENS = ["WETH", "WAVAX"];
export const isNativeToken = (token: string): boolean =>
  NATIVE_TOKENS.includes(token);

export const AuctionList = [
  "WBTC-call",
  "AAVE-call",
  "UNI-call",
  "USDC-put",
  "yvUSDC-put",
  "rETH-call",
  "wstETH-call",
  "WETH-call",
  "WAVAX-call",
  "PERP-call",
  "USDC.e-put",
  "sAVAX-call",
  "APE-call",
  "WBNB-call",
  "SOL-call",
  "BAL-call",
  "SPELL-call",
  "SPELLTWO-call",
  "BADGER-call",
  "SAMB-call",
] as const;

export const gasLimit: Partial<{
  [asset in Assets]: string;
}> = {
  AAVE: "320000",
  UNI: "320000",
  USDC: "210000",
  WBTC: "190000",
  WETH: "210000",
  rETH: "300000",
  WAVAX: "300000",
  "USDC.e": "300000",
  sAVAX: "300000",
};
export type AuctionOptions = typeof AuctionList[number];

export const BiddingAssets: Record<
  number,
  Record<AuctionContracts, Assets[]>
> = {
  [CHAINID.ETH_MAINNET]: {
    GNOSIS: ["WBTC", "WETH", "AAVE", "UNI", "USDC", "rETH"],
    PARADIGM: ["WBTC", "AAVE", "UNI", "WETH", "USDC", "rETH"],
    "R-EARN": ["USDC"],
    "R-EARN-STETH": ["stETH"],
  },
  [CHAINID.ETH_KOVAN]: {
    GNOSIS: ["WETH", "USDC"],
    PARADIGM: ["WETH", "USDC"],
    "R-EARN": [],
    "R-EARN-STETH": [],
  },
  [CHAINID.AVAX_MAINNET]: {
    GNOSIS: ["WAVAX", "sAVAX", "USDC.e"],
    PARADIGM: ["WAVAX", "sAVAX", "USDC.e"],
    "R-EARN": [],
    "R-EARN-STETH": [],
  },
  [CHAINID.AVAX_FUJI]: {
    GNOSIS: ["WAVAX"],
    PARADIGM: ["WAVAX"],
    "R-EARN": [],
    "R-EARN-STETH": [],
  },
  [CHAINID.BINANCE_MAINNET]: {
    GNOSIS: [],
    PARADIGM: ["WBNB"],
    "R-EARN": [],
    "R-EARN-STETH": [],
  },
  [CHAINID.BINANCE_TESTNET]: {
    GNOSIS: [],
    PARADIGM: ["WBNB"],
    "R-EARN": [],
    "R-EARN-STETH": [],
  },
};

export const getRelevantAuction = (asset: Assets): AuctionOptions[] => {
  switch (asset) {
    case "WBTC":
      return ["WBTC-call"];
    case "rETH":
      return ["rETH-call"];
    case "WETH":
      return ["wstETH-call", "WETH-call"];
    case "AAVE":
      return ["AAVE-call"];
    case "UNI":
      return ["UNI-call"];
    case "USDC":
      return ["USDC-put", "PERP-call", "BAL-call"];
    case "sAVAX":
      return ["sAVAX-call"];
    case "WAVAX":
      return ["WAVAX-call"];
    case "APE":
      return ["APE-call"];
    case "USDC.e":
      return ["USDC.e-put"];
    default:
      return [];
  }
};

export const getAuctionChain = (vault: AuctionOptions): CHAINID => {
  switch (vault) {
    case "WBTC-call":
    case "AAVE-call":
    case "UNI-call":
    case "PERP-call":
    case "USDC-put":
    case "yvUSDC-put":
    case "wstETH-call":
    case "WETH-call":
    case "rETH-call":
    case "APE-call":
    case "BAL-call":
    case "SPELL-call":
    case "SPELLTWO-call":
    case "BADGER-call":
    case "SAMB-call":
      return isDevelopment() ? CHAINID.ETH_KOVAN : CHAINID.ETH_MAINNET;
    case "sAVAX-call":
    case "WAVAX-call":
    case "USDC.e-put":
      return isDevelopment() ? CHAINID.AVAX_FUJI : CHAINID.AVAX_MAINNET;
    case "WBNB-call":
      return isDevelopment()
        ? CHAINID.BINANCE_TESTNET
        : CHAINID.BINANCE_MAINNET;
    case "SOL-call":
      return CHAINID.SOL;
  }
};

export const AuctionAddressMap: {
  [auction in AuctionOptions]: {
    vault: string;
    chainId: number;
    strikeSelection: string;
  };
} = {
  "WBTC-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultWBTCCall,
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionWBTC,
      }
    : {
        vault: deployment.mainnet.RibbonThetaVaultWBTCCall,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionWBTCCall,
      },
  "AAVE-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultAAVECall,
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH,
      }
    : {
        vault: deployment.mainnet.RibbonThetaVaultAAVECall,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionAAVECall,
      },
  "UNI-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultAAVECall, //invalid
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH, //invalid
      }
    : {
        vault: deployment.mainnet.RibbonThetaVaultUNICall,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionUNICall,
      },
  "USDC-put": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaYearnVaultETHPut,
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH,
      }
    : {
        vault: deployment.mainnet.RibbonThetaYearnVaultETHPut,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionETHPut,
      },
  "yvUSDC-put": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaYearnVaultETHPut,
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH,
      }
    : {
        vault: deployment.mainnet.RibbonThetaYearnVaultETHPut,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionETHPut,
      },
  "rETH-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultSTETHCall,
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH,
      }
    : {
        vault: deployment.mainnet.RibbonThetaVaultRETHCall,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionETHCall,
      },
  "wstETH-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultSTETHCall,
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH,
      }
    : {
        vault: deployment.mainnet.RibbonThetaVaultSTETHCall,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionETHCall,
      },
  "WETH-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultETHCall,
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH,
      }
    : {
        vault: deployment.mainnet.RibbonThetaVaultETHCall,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionETHCall,
      },
  "WAVAX-call": isDevelopment()
    ? {
        vault: deployment.fuji.RibbonThetaVaultAVAXCall,
        chainId: CHAINID.AVAX_FUJI,
        strikeSelection: deployment.fuji.StrikeSelectionAVAXCall,
      }
    : {
        vault: deployment.avax.RibbonThetaVaultAVAXCall,
        chainId: CHAINID.AVAX_MAINNET,
        strikeSelection: deployment.avax.StrikeSelectionAVAXCall,
      },
  "PERP-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultAAVECall, //invalid
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH, //invalid
      }
    : {
        vault: deployment.mainnet.RibbonTreasuryVaultPERP,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionPERP,
      },
  "USDC.e-put": isDevelopment()
    ? {
        vault: deployment.fuji.RibbonThetaVaultAVAXPut, //invalid
        chainId: CHAINID.AVAX_FUJI,
        strikeSelection: deployment.fuji.StrikeSelectionAVAXCall, //invalid
      }
    : {
        vault: deployment.avax.RibbonThetaVaultAVAXPut,
        chainId: CHAINID.AVAX_MAINNET,
        strikeSelection: deployment.avax.StrikeSelectionAVAXPut,
      },
  "sAVAX-call": isDevelopment()
    ? {
        vault: deployment.fuji.RibbonThetaVaultSAVAXCall, //invalid
        chainId: CHAINID.AVAX_FUJI,
        strikeSelection: deployment.fuji.StrikeSelectionSAVAX, //invalid
      }
    : {
        vault: deployment.avax.RibbonThetaVaultSAVAXCall,
        chainId: CHAINID.AVAX_MAINNET,
        strikeSelection: deployment.avax.StrikeSelectionAVAXCall,
      },
  "APE-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultAAVECall, //invalid
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH, //invalid
      }
    : {
        vault: deployment.mainnet.RibbonThetaVaultAPECall,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionAPECall,
      },
  "BAL-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultETHCall, //invalid
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH, //invalid
      }
    : {
        vault: deployment.mainnet.RibbonTreasuryVaultBAL,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionETHCall, //no strike selection
      },
  "SPELL-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultETHCall, //invalid
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH, //invalid
      }
    : {
        vault: deployment.mainnet.RibbonTreasuryVaultSPELL,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionSPELL,
      },
  "SPELLTWO-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultETHCall, //invalid
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH, //invalid
      }
    : {
        vault: deployment.mainnet.RibbonTreasuryVaultSPELLTWO,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionSPELLTWO,
      },
  "BADGER-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultETHCall, //invalid
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH, //invalid
      }
    : {
        vault: deployment.mainnet.RibbonTreasuryVaultBADGER,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionBADGER,
      },
  "SAMB-call": isDevelopment()
    ? {
        vault: deployment.kovan.RibbonThetaVaultETHCall, //invalid
        chainId: CHAINID.ETH_KOVAN,
        strikeSelection: deployment.kovan.StrikeSelectionETH, //invalid
      }
    : {
        vault: deployment.mainnet.RibbonTreasuryVaultSAMB,
        chainId: CHAINID.ETH_MAINNET,
        strikeSelection: deployment.mainnet.StrikeSelectionETHCall, //no strike selection
      },
  "WBNB-call": isDevelopment()
    ? {
        vault: deployment.binanceTestnet.RibbonThetaVaultBNBCall, //invalid
        chainId: CHAINID.BINANCE_MAINNET,
        strikeSelection: deployment.binanceTestnet.StrikeSelectionBNBCall, //invalid
      }
    : {
        vault: deployment.binance.RibbonThetaVaultBNBCall,
        chainId: CHAINID.BINANCE_MAINNET,
        strikeSelection: deployment.binance.StrikeSelectionBNBCall,
      },
  "SOL-call": {
    vault: "",
    chainId: CHAINID.SOL,
    strikeSelection: "",
  },
};

export const BLOCKCHAIN_EXPLORER_NAME: Record<number, string> = {
  [CHAINID.ETH_MAINNET]: "Etherscan",
  [CHAINID.ETH_KOVAN]: "Etherscan",
  [CHAINID.AVAX_MAINNET]: "SnowTrace",
  [CHAINID.AVAX_FUJI]: "SnowTrace",
  [CHAINID.BINANCE_MAINNET]: "BscScan",
  [CHAINID.BINANCE_TESTNET]: "BscScanTestnet",
};

export const BLOCKCHAIN_EXPLORER_URI: Record<number, string> = {
  [CHAINID.ETH_MAINNET]: "https://etherscan.io",
  [CHAINID.ETH_KOVAN]: "https://kovan.etherscan.io",
  [CHAINID.AVAX_MAINNET]: "https://snowtrace.io",
  [CHAINID.AVAX_FUJI]: "https://testnet.snowtrace.io",
  [CHAINID.BINANCE_MAINNET]: "https://bscscan.com",
  [CHAINID.BINANCE_TESTNET]: "https://testnet.bscscan.com",
};

export const getEtherscanURI = (chainId: number) =>
  BLOCKCHAIN_EXPLORER_URI[chainId as CHAINID];

export const getBiddingToken = (vault: AuctionOptions): Assets => {
  switch (vault) {
    case "WBTC-call":
      return "WBTC";
    case "AAVE-call":
      return "AAVE";
    case "UNI-call":
      return "UNI";
    case "PERP-call":
    case "USDC-put":
    case "yvUSDC-put":
    case "BAL-call":
      return "USDC";
    case "rETH-call":
      return "rETH";
    case "wstETH-call":
    case "WETH-call":
      return "WETH";
    case "sAVAX-call":
      return "sAVAX";
    case "WAVAX-call":
      return "WAVAX";
    case "USDC.e-put":
      return "USDC.e";
    case "APE-call":
      return "APE";
    case "WBNB-call":
      return "WBNB";
    case "SOL-call":
      return "SOL";
    case "SPELL-call":
      return "SPELL";
    case "SPELLTWO-call":
      return "SPELL";
    case "BADGER-call":
      return "BADGER";
    case "SAMB-call":
      return "SAMB";
  }
};

export const getVaultAssets = (vault: AuctionOptions): Assets => {
  switch (vault) {
    case "WBTC-call":
      return "WBTC";
    case "AAVE-call":
      return "AAVE";
    case "UNI-call":
      return "UNI";
    case "PERP-call":
      return "PERP";
    case "USDC-put":
      return "USDC";
    case "yvUSDC-put":
      return "yvUSDC";
    case "rETH-call":
      return "rETH";
    case "wstETH-call":
      return "wstETH";
    case "WETH-call":
      return "WETH";
    case "sAVAX-call":
      return "sAVAX";
    case "WAVAX-call":
      return "WAVAX";
    case "USDC.e-put":
      return "USDC.e";
    case "APE-call":
      return "APE";
    case "WBNB-call":
      return "WBNB";
    case "SOL-call":
      return "SOL";
    case "BAL-call":
      return "BAL";
    case "SPELL-call":
      return "SPELL";
    case "SPELLTWO-call":
      return "SPELL";
    case "BADGER-call":
      return "BADGER";
    case "SAMB-call":
      return "SAMB";
  }
};

export const getUnderlyingToken = (vault: AuctionOptions): Assets => {
  switch (vault) {
    case "WBTC-call":
      return "WBTC";
    case "AAVE-call":
      return "AAVE";
    case "UNI-call":
      return "UNI";
    case "rETH-call":
      return "rETH";
    case "USDC-put":
    case "yvUSDC-put":
    case "wstETH-call":
    case "WETH-call":
      return "WETH";
    case "sAVAX-call":
      return "sAVAX";
    case "USDC.e-put":
    case "WAVAX-call":
      return "WAVAX";
    case "PERP-call":
      return "PERP";
    case "APE-call":
      return "APE";
    case "WBNB-call":
      return "WBNB";
    case "SOL-call":
      return "SOL";
    case "BAL-call":
      return "BAL";
    case "SPELL-call":
      return "SPELL";
    case "SPELLTWO-call":
      return "SPELL";
    case "BADGER-call":
      return "BADGER";
    case "SAMB-call":
      return "SAMB";
  }
};

export const getTokenAddress = (token: Assets, chainId: number) => {
  const network = NETWORKS[chainId];
  return isDevelopment()
    ? (addresses[network].assets as any)[token]
    : (addresses[network].assets as any)[token];
};

export const SUBGRAPHS: CHAINID[] = isDevelopment()
  ? [CHAINID.ETH_KOVAN, CHAINID.AVAX_FUJI]
  : [CHAINID.ETH_MAINNET, CHAINID.AVAX_MAINNET];

export const getERC20TokenAddress = (token: Assets, chainId: number) => {
  const network = NETWORKS[chainId];
  return isDevelopment()
    ? (addresses[network].assets as any)[token]
    : (addresses[network].assets as any)[token];
};

export const getGnosisAuction = (chainId: number) =>
  GNOSIS_AUCTION[chainId as CHAINID];

export const getSwapContract = (chainId: number) =>
  SWAP_CONTRACT[chainId as CHAINID];

export const getREarnContract = (chainId: number) =>
  REARN_CONTRACT[chainId as CHAINID];

export const getREarnstEthContract = (chainId: number) =>
  REARN_STETH_CONTRACT[chainId as CHAINID];

export const getUniswapMulticallAddress = (chainId: number) =>
  UNISWAP_MULTICALL[chainId as CHAINID];

export const getDepositNativeAuction = (chainId: number) =>
  DEPOSIT_NATIVE_AUCTION[chainId as CHAINID];

export const isParadigmAuction = (auction: AuctionOptions) => {
  switch (auction) {
    case "WBTC-call":
    case "AAVE-call":
    case "UNI-call":
    case "SOL-call":
    case "USDC.e-put":
    case "WAVAX-call":
    case "sAVAX-call":
    case "WETH-call":
    case "USDC-put":
    case "wstETH-call":
    case "rETH-call":
      return true;
    case "yvUSDC-put":
    case "PERP-call":
    case "APE-call":
    case "BAL-call":
    case "SPELL-call":
    case "SPELLTWO-call":
    case "BADGER-call":
    case "SAMB-call":
      return false;
  }
};

export const GNOSIS_AUCTION: Record<CHAINID, string> = {
  [CHAINID.ETH_MAINNET]: "0x0b7fFc1f4AD541A4Ed16b40D8c37f0929158D101",
  [CHAINID.ETH_KOVAN]: "0x3D1D40101E185E1fE3aedCF05FE2F5BA0Af1f25D",
  [CHAINID.AVAX_MAINNET]: "0xb5D00F83680ea5E078e911995c64b43Fbfd1eE61",
  [CHAINID.AVAX_FUJI]: "0xa5cd8D8effACB7Ad861e3797404924199D1463a5",
  [CHAINID.BINANCE_MAINNET]: "",
  [CHAINID.BINANCE_TESTNET]: "",
  [CHAINID.SOL]: "",
};

export const SWAP_CONTRACT: Record<CHAINID, string> = {
  [CHAINID.ETH_MAINNET]: "0xEb8CAB2F4663247db8eAfb556F498c861bB4e161",
  [CHAINID.ETH_KOVAN]: "0x65B7A53D9342758b59F9Cd9F4Bb56de6e572743B",
  [CHAINID.AVAX_MAINNET]: "0x15988ceA1F11D62e63bAa02B0e8B3046942983c0",
  [CHAINID.AVAX_FUJI]: "0x81111914490ADA09e4dA1E522AC262aA06bd01e1",
  [CHAINID.BINANCE_MAINNET]: "0xa6438476a0163efd0fff53fd7f5eb17b8b6394c2",
  [CHAINID.BINANCE_TESTNET]: "0x70D370231c91f95215a7749D357Fb46501c43c53",
  [CHAINID.SOL]: "",
};

export const REARN_CONTRACT: Record<CHAINID, string> = {
  [CHAINID.ETH_MAINNET]: "0x84c2b16FA6877a8fF4F3271db7ea837233DFd6f0",
  [CHAINID.ETH_KOVAN]: "",
  [CHAINID.AVAX_MAINNET]: "",
  [CHAINID.AVAX_FUJI]: "",
  [CHAINID.BINANCE_MAINNET]: "",
  [CHAINID.BINANCE_TESTNET]: "",
  [CHAINID.SOL]: "",
};

export const REARN_STETH_CONTRACT: Record<CHAINID, string> = {
  [CHAINID.ETH_MAINNET]: "0xCE5513474E077F5336cf1B33c1347FDD8D48aE8c",
  [CHAINID.ETH_KOVAN]: "",
  [CHAINID.AVAX_MAINNET]: "",
  [CHAINID.AVAX_FUJI]: "",
  [CHAINID.BINANCE_MAINNET]: "",
  [CHAINID.BINANCE_TESTNET]: "",
  [CHAINID.SOL]: "",
};

export const UNISWAP_MULTICALL: Record<CHAINID, string> = {
  [CHAINID.ETH_MAINNET]: "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696",
  [CHAINID.ETH_KOVAN]: "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696",
  [CHAINID.AVAX_MAINNET]: "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696",
  [CHAINID.AVAX_FUJI]: "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696",
  [CHAINID.BINANCE_MAINNET]: "",
  [CHAINID.BINANCE_TESTNET]: "",
  [CHAINID.SOL]: "",
};

export const DEPOSIT_NATIVE_AUCTION: Record<CHAINID, string> = {
  [CHAINID.ETH_MAINNET]: "0x10D15DEA67f7C95e2F9Fe4eCC245a8862b9B5B96",
  [CHAINID.ETH_KOVAN]: "0x10D15DEA67f7C95e2F9Fe4eCC245a8862b9B5B96",
  [CHAINID.AVAX_MAINNET]: "0x193c8993480DF4c1dBBdB39dB07511f7D789cedb",
  [CHAINID.AVAX_FUJI]: "0x193c8993480DF4c1dBBdB39dB07511f7D789cedb",
  [CHAINID.BINANCE_MAINNET]: "",
  [CHAINID.BINANCE_TESTNET]: "",
  [CHAINID.SOL]: "",
};

export const AUCTION_CONTRACT: Record<CHAINID, AuctionContracts[]> = {
  [CHAINID.ETH_MAINNET]: ["GNOSIS", "PARADIGM", "R-EARN", "R-EARN-STETH"],
  [CHAINID.ETH_KOVAN]: ["GNOSIS", "PARADIGM"],
  [CHAINID.AVAX_MAINNET]: ["GNOSIS", "PARADIGM"],
  [CHAINID.AVAX_FUJI]: ["GNOSIS", "PARADIGM"],
  [CHAINID.BINANCE_MAINNET]: ["PARADIGM"],
  [CHAINID.BINANCE_TESTNET]: ["PARADIGM"],
  [CHAINID.SOL]: [],
};

export const KEEPERS = [
  "0x422f7Bb366608723c8fe61Ac6D923023dCCBC3d7",
  "0xA4290C9EAe274c7A8FbC57A1E68AdC3E95E7C67e",
  "0xb793898783802543D17FcCd78BE611241501649d",
  "0x004FCF8052D3c7eCb7558ac0068882425a055528",
  "0xe7Fbda7F214bc0e7D62E2960570314223f39E6ea",
  "0x6b17A6A3F952F7eFFec31Dbbc52128B8c552cc9d",
  "0x382d0f570E49Cf388863536c45b7e1607E703a9B",
  "0xd54f7AB07175d9eFFC4b72581517800B47ee7094",
  "0x31fd01BEb2CaEa630f0aEEb92D6c7b2dBa75F5DE",
];
