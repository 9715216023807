import React, { ReactElement, useContext } from "react";
import { AuctionOptions } from "../constants/constants";
import { AugmentedAuctionData, AugmentedBidData } from "../models/auction";
import { Assets } from "../utils/asset";
import { getAuctionName } from "../utils/auction";
import useFetchSubgraphData from "./useFetchSubgraphData";

export type SubgraphDataContextType = {
  responses: {
    auctions: AugmentedAuctionData[];
    bids: AugmentedBidData[];
  };
  lastUpdated: number;
  loading: boolean;
};

export const defaultSubgraphData = {
  responses: {
    auctions: [],
    bids: [],
  },
  lastUpdated: 0,
  loading: true,
};

export const SubgraphDataContext =
  React.createContext<SubgraphDataContextType>(defaultSubgraphData);

export const SubgraphDataContextProvider: React.FC<{
  children: ReactElement;
}> = ({ children }) => {
  const subgraphData = useFetchSubgraphData();

  return (
    <SubgraphDataContext.Provider value={subgraphData}>
      {children}
    </SubgraphDataContext.Provider>
  );
};

export const useMultiAuctionsData = (auctionName: string) => {
  const contextData = useContext(SubgraphDataContext);

  const auctionInformation = contextData.responses.auctions.filter((value) => {
    const name = getAuctionName(value) + "&" + value.index;

    return name === auctionName;
  });

  return {
    data: auctionInformation,
    loading: contextData.loading,
  };
};

export const useLastUpdated = () => {
  const contextData = useContext(SubgraphDataContext);

  return {
    lastUpdated: contextData.lastUpdated,
    loading: contextData.loading,
  };
};

export const useAuctionData = (auctionId: string) => {
  const contextData = useContext(SubgraphDataContext);

  const auctionInformation = contextData.responses.auctions.filter((value) => {
    return value.id.split("_")[0] === auctionId;
  });

  return {
    data: auctionInformation,
    loading: contextData.loading,
  };
};

export const useSubgraphData = () => {
  const contextData = useContext(SubgraphDataContext);

  return {
    data: contextData.responses,
    loading: contextData.loading,
  };
};

export const useLatestAuction = (auctions: AuctionOptions[]) => {
  const contextData = useContext(SubgraphDataContext);

  contextData.responses.auctions.sort(
    (a: AugmentedAuctionData, b: AugmentedAuctionData) => {
      return Number(b.end) - Number(a.end);
    }
  );

  return {
    data: auctions.map((auction) => {
      return contextData.responses.auctions.filter((subgraphAuction) => {
        const title = subgraphAuction.option.symbol.split("/")[1];
        const vaultAsset = title.split("-")[0] as Assets;
        const optionType = subgraphAuction.option.put ? "put" : "call";
        const vault = vaultAsset + "-" + optionType;

        return (
          !subgraphAuction.live && vault.toLowerCase() === auction.toLowerCase()
        );
      })[0];
    }),
    loading: contextData.loading,
  };
};

export const useBidsData = (auctionId: number, chainId: number) => {
  const contextData = useContext(SubgraphDataContext);

  const bids = contextData.responses.bids.filter((value) => {
    return value.auction === auctionId && value.chainId === chainId;
  });

  return {
    data: bids,
    loading: contextData.loading,
  };
};
