import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { GnosisEasyAuction } from "../codegen";
import { GnosisEasyAuctionFactory } from "../codegen/GnosisEasyAuctionFactory";
import { DepositAndPlaceOrder } from "../codegen";
import { DepositAndPlaceOrderFactory } from "../codegen/DepositAndPlaceOrderFactory";
import {
  getDepositNativeAuction,
  getGnosisAuction,
} from "../constants/constants";
import { useWeb3Context } from "./web3Context";

export const getAuction = (
  library: any,
  useSigner: boolean = true,
  isNativeToken: boolean,
  chainId: number
) => {
  const provider = useSigner ? library.getSigner() : library;

  const auction = isNativeToken
    ? DepositAndPlaceOrderFactory.connect(
        getDepositNativeAuction(chainId),
        provider
      )
    : GnosisEasyAuctionFactory.connect(getGnosisAuction(chainId), provider);
  return auction;
};

const useAuction = (isNativeToken: boolean) => {
  const { library, active, chainId } = useWeb3React();
  const { provider } = useWeb3Context();
  const [auction, setauction] = useState<
    GnosisEasyAuction | DepositAndPlaceOrder | null
  >(null);

  useEffect(() => {
    const auction = getAuction(
      library || provider,
      active,
      isNativeToken,
      chainId || 1
    );
    setauction(auction);
  }, [active, library, provider, chainId, isNativeToken]);

  return auction;
};

export default useAuction;
