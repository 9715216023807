import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { Swap } from "../codegen/Swap";
import { SwapFactory } from "../codegen/SwapFactory";
import { getSwapContract } from "../constants/constants";
import { useWeb3Context } from "./web3Context";

export const getSwap = (
  library: any,
  useSigner: boolean = true,
  chainId: number
) => {
  const provider = useSigner ? library.getSigner() : library;

  const Swap = SwapFactory.connect(getSwapContract(chainId), provider);

  return Swap;
};

const useSwap = () => {
  const { library, active, chainId } = useWeb3React();
  const { provider } = useWeb3Context();
  const [Swap, setSwap] = useState<Swap | null>(null);

  useEffect(() => {
    const Swap = getSwap(library || provider, active, chainId || 1);
    setSwap(Swap);
  }, [active, library, provider, chainId]);

  return Swap;
};

export default useSwap;
