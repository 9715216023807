import useEagerConnect from "../hooks/useEagerConnect";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";

import Header from "./Header/Header";
import { Container } from "react-bootstrap";
import Homepage from "../pages/Home/Homepage";
import Approval from "../pages/Approval/Approval";
import AuctionPage from "../pages/Auction/AuctionPage";
import WalletConnectModal from "../components/Wallet/WalletConnectModal";
import NotFound from "../pages/NotFound";
import colors from "../design/colors";

const Root = styled(Container)`
  background-color: ${colors.background.one};
  min-width: 1200px;
`;

const RootApp = () => {
  useEagerConnect();

  return (
    <Root id="appRoot">
      <>
        <WalletConnectModal />
        {/* <YourPositionModal />
        <WithdrawReminderToast /> */}

        <Router>
          <Header />
          {/* <TxStatusToast /> */}
          <Switch>
            <Route path="/" exact>
              <Homepage />
            </Route>
            <Route path="/approval" exact>
              <Approval />
            </Route>
            <Route path="/auction/:auctionTitle">
              <AuctionPage />
            </Route>
            {/* <Route path="/portfolio">
              <PortfolioPage />
            </Route>
            */}
            <Route>
              <NotFound />
            </Route>
          </Switch>
          {/* <Footer /> */}
        </Router>
      </>
    </Root>
  );
};

export default RootApp;
