import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import { setTimeout } from "timers";

import Indicator from "../../components/Indicator/Indicator";
import sizes from "../../design/sizes";
import { Title, BaseButton } from "../../design";
import {
  WalletStatusProps,
  AccountStatusVariantProps,
  WalletButtonProps,
} from "./types";
import theme from "../../design/theme";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import useConnectWalletModal from "../../hooks/useConnectWalletModal";
import { truncateAddress } from "../../utils/address";

const walletButtonWidth = 55;

const AccountStatusContainer = styled.div<AccountStatusVariantProps>`
  flex-wrap: wrap;
  flex-direction: column;

  ${(props) => {
    switch (props.variant) {
      case "mobile":
        return `
          display: none;

          @media (max-width: ${sizes.md}px) {
            display: flex;
            width: 100%;
          }
        `;
      case "desktop":
        return `
          display: flex;
        `;
    }
  }}
`;

const WalletContainer = styled.div<AccountStatusVariantProps>`
  justify-content: center;
  align-items: center;

  ${(props) => {
    switch (props.variant) {
      case "desktop":
        return `
        display: flex;
        z-index: 1000;
        position: relative;

        @media (max-width: ${sizes.md}px) {
          display: none;
        }
        `;
      case "mobile":
        return `
          display: none;

          @media (max-width: ${sizes.md}px) {
            display: flex;
            align-items: unset;
            padding-top: 16px;
            width: 100%;
          }
        `;
    }
  }}
`;

const WalletButton = styled(BaseButton)<WalletButtonProps>`
  background-color: #424242;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 180px;
  height: 40px;
  border-radius: 5px;

  &:hover {
    opacity: ${theme.hover.opacity};
  }

  ${(props) => {
    switch (props.variant) {
      case "mobile":
        return `
        height: 48px;
        justify-content: center;
        padding: 14px 16px;
        width: ${props.showInvestButton ? `${walletButtonWidth}%` : "90%"};
      `;
      case "desktop":
        return ``;
    }
  }}
`;

const WalletButtonText = styled(Title)<WalletStatusProps>`
  font-size: 15px;

  @media (max-width: ${sizes.md}px) {
    font-size: 16px;
  }

  @media (max-width: 350px) {
    font-size: 13px;
  }

  color: #ffffff;
`;

interface AccountStatusProps {
  variant: "desktop" | "mobile";
  showVaultPositionHook?: (show: boolean) => void;
}

const AccountStatus: React.FC<AccountStatusProps> = ({ variant }) => {
  const { active, account } = useWeb3React();
  const [, setShowConnectModal] = useConnectWalletModal();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [copyState, setCopyState] = useState<"visible" | "hiding" | "hidden">(
    "hidden"
  );

  // Track clicked area outside of desktop menu
  const desktopMenuRef = useRef(null);
  useOutsideAlerter(desktopMenuRef, () => {
    if (variant === "desktop" && isMenuOpen) onCloseMenu();
  });

  useEffect(() => {
    let timer;

    switch (copyState) {
      case "visible":
        timer = setTimeout(() => {
          setCopyState("hiding");
        }, 800);
        break;
      case "hiding":
        timer = setTimeout(() => {
          setCopyState("hidden");
        }, 200);
    }

    if (timer) clearTimeout(timer);
  }, [copyState]);

  const handleButtonClick = useCallback(async () => {
    setShowConnectModal(true);
  }, [setShowConnectModal]);

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const renderButtonContent = () =>
    active && account ? (
      <>
        <Indicator connected={active} />
        <WalletButtonText connected={active}>
          {truncateAddress(account)}
        </WalletButtonText>
      </>
    ) : (
      <WalletButtonText connected={active}>CONNECT WALLET</WalletButtonText>
    );

  return (
    <AccountStatusContainer variant={variant}>
      {/* Main Button and Desktop Menu */}
      <WalletContainer variant={variant} ref={desktopMenuRef}>
        <WalletButton
          variant={variant}
          showInvestButton={false}
          connected={active}
          role="button"
          onClick={handleButtonClick}
        >
          {renderButtonContent()}
        </WalletButton>
      </WalletContainer>
    </AccountStatusContainer>
  );
};
export default AccountStatus;
