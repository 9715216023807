import styled from "styled-components";
import { AugmentedAuctionData, BidData } from "../../models/auction";

import { LinkButton } from "../Common/Buttons";
import {
  getAuctionName,
  getAuctionOption,
  resolveAuctionData,
  resolveClearingStatus,
} from "../../utils/auction";
import { formatNumber } from "../../utils/text";
import { AuctionOptions, isParadigmAuction } from "../../constants/constants";

const LiveAuctionItemContainer = styled.div`
  display: flex;
  height: 135px;
  padding: 22px;
  border-radius: 2px;
  background-color: #ffffff;
`;

const LogoContainer = styled.div<{ color: string }>`
  height: 91px;
  min-width: 91px;
  margin-right: 20px;
  border: solid 1px ${(props) => props.color};
  border-radius: 50%;
  background-color: ${(props) => props.color}16;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const MainDescription = styled.div`
  display: block;
  margin-right: 60px;
`;

const AuctionTimeCaption = styled.div`
  font-size: 13px;
  font-weight: 300;
`;

const AuctionTime = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
`;

const Caption = styled.div`
  font-size: 13px;
  font-weight: 300;
`;

const Value = styled.div`
  font-family: VCR;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
`;

const StackedDetailDescription = styled.div`
  display: block;
  margin-left: auto;
`;

const DetailDescription = styled.div`
  display: flex;
  padding: 3px;
  color: #646464;
  align-items: center;
  margin-left: auto;
`;

const InformationGroup = styled.div`
  display: block;
  min-width: 150px;
  margin-bottom: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-left: 80px;
  align-items: center;
`;

const Separator = styled.div`
  width: 1px;
  height: 20px;
  background-color: #d3d3d3;
  margin: 0px 20px;
`;

const AuctionTitle = styled.div`
  font-family: VCR;
  font-size: 34px;
  line-height: 42px;
  margin-bottom: 4px;
`;

const LiveAuctionItem: React.FC<{
  auctionData: AugmentedAuctionData;
  bidData: BidData[];
  onClick: (link: string) => void;
}> = ({ auctionData, bidData, onClick }) => {
  const {
    biddingToken,
    Logo,
    logoSizeLarge,
    color,
    title,
    starttime,
    endtime,
    size,
    clearing,
    link,
    minBid,
  } = resolveAuctionData(auctionData);

  const { clearingPrice } = resolveClearingStatus(auctionData, bidData);

  const optimisticClear = clearing || clearingPrice;

  const auctionOption = getAuctionOption(
    getAuctionName(auctionData)
  ) as AuctionOptions;

  return (
    <>
      <LiveAuctionItemContainer>
        <LogoContainer color={color}>
          <Logo
            height={logoSizeLarge}
            width={logoSizeLarge}
            backgroundColor="none"
          ></Logo>
        </LogoContainer>
        <MainDescription>
          <AuctionTitle>{title}</AuctionTitle>
          <AuctionTimeCaption>Ending on:</AuctionTimeCaption>
          <AuctionTime>{endtime}</AuctionTime>
        </MainDescription>
        <StackedDetailDescription>
          <DetailDescription>
            <InformationGroup>
              <Caption>Bid With: </Caption>
              <Value>{biddingToken}</Value>
            </InformationGroup>
            <Separator></Separator>
            <InformationGroup>
              <Caption>Min. Bid (per oToken): </Caption>
              <Value>
                {isParadigmAuction(auctionOption)
                  ? "-"
                  : formatNumber(minBid) + " " + biddingToken}
              </Value>
            </InformationGroup>
          </DetailDescription>
          <DetailDescription>
            <InformationGroup>
              <Caption>Size: </Caption>
              <Value>{formatNumber(size)} OTOKENS</Value>
            </InformationGroup>
            <Separator></Separator>
            <InformationGroup>
              <Caption>Current Price (per oToken): </Caption>
              <Value>
                {optimisticClear
                  ? `${formatNumber(optimisticClear)} ${biddingToken}`
                  : "-"}
              </Value>
            </InformationGroup>
          </DetailDescription>
        </StackedDetailDescription>
        <ButtonContainer>
          <LinkButton link={link} children="BID" onPress={onClick}></LinkButton>
        </ButtonContainer>
      </LiveAuctionItemContainer>
    </>
  );
};

export default LiveAuctionItem;
