import { useCallback, useEffect } from "react";
import { BigNumber, ethers } from "ethers";
import { formatUnits } from "ethers/lib/utils";

import { initialBidActionForm, useWebappGlobalState } from "../store/store";
import { AuctionData } from "../models/auction";
export type VaultActionFormTransferData =
  | {
      availableCapacity: BigNumber;
      availableTransferAmount: BigNumber;
    }
  | undefined;

export type WithdrawMetadata = {
  allowStandardWithdraw?: boolean;
  instantWithdrawBalance?: BigNumber;
};

const useVaultActionForm = (auctionData: AuctionData) => {
  const [auctionActionForm, setAuctionActionForm] =
    useWebappGlobalState("bidActionForm");
  /**
   * Utility for reset action form
   */
  const resetActionForm = useCallback(() => {
    setAuctionActionForm((actionForm) => ({
      ...actionForm,
      quantity: "",
      price: "",
      payable: "",
      lastAction: "",
      prevAction: "",
    }));
  }, [setAuctionActionForm]);

  /**
   * Reset form when vault option changes
   */
  useEffect(() => {
    setAuctionActionForm((prevVaultActionForm) => {
      if (prevVaultActionForm.auctionId !== auctionData.id.split("_")[0]) {
        const id = auctionData.id.split("_")[0];
        return {
          ...initialBidActionForm,
          id,
        };
      }

      return prevVaultActionForm;
    });
  }, [setAuctionActionForm, auctionData.id]);

  /**
   * Handle input amount changed
   */
  const handlePriceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawInput = e.target.value;
      const decimal = auctionData.bidding.decimals.toString();

      let prevAction = auctionActionForm.prevAction;
      if (
        auctionActionForm.lastAction !== "price" ||
        !auctionActionForm.prevAction
      ) {
        prevAction = auctionActionForm.lastAction;
      }

      let payable = auctionActionForm.payable;
      if (Number(auctionActionForm.quantity) > 0 && prevAction === "quantity") {
        const price = ethers.utils.parseUnits(
          rawInput ? rawInput : "0",
          decimal
        );
        const quantity = ethers.utils.parseUnits(auctionActionForm.quantity, 8);
        payable = ethers.utils.formatUnits(
          price.mul(quantity).div(10 ** 8),
          decimal
        );
      }

      let quantity = auctionActionForm.quantity;
      if (Number(auctionActionForm.payable) > 0 && prevAction === "payable") {
        const price = ethers.utils.parseUnits(
          rawInput ? rawInput : "0",
          decimal
        );
        const payable = ethers.utils.parseUnits(
          auctionActionForm.payable,
          decimal
        );
        try {
          quantity = ethers.utils.formatUnits(
            payable.mul(10 ** 8).div(price),
            8
          );
        } catch {
          quantity = "0";
        }
      }

      setAuctionActionForm((actionForm) => ({
        ...actionForm,
        price: rawInput && parseFloat(rawInput) < 0 ? "" : rawInput,
        payable: payable,
        lastAction: "price",
        prevAction: prevAction,
        quantity: quantity,
      }));
    },
    [setAuctionActionForm, auctionActionForm, auctionData.bidding.decimals]
  );

  const handleSizeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawInput = e.target.value;
      const decimal = auctionData.bidding.decimals.toString();

      let prevAction = auctionActionForm.prevAction;
      if (
        auctionActionForm.lastAction !== "quantity" ||
        !auctionActionForm.prevAction
      ) {
        prevAction = auctionActionForm.lastAction;
      }

      let payable = auctionActionForm.payable;
      let price = auctionActionForm.price;
      if (Number(auctionActionForm.price) > 0 && prevAction === "price") {
        const quantity = ethers.utils.parseUnits(rawInput ? rawInput : "0", 8);
        const price = ethers.utils.parseUnits(auctionActionForm.price, decimal);
        payable = ethers.utils.formatUnits(
          price.mul(quantity).div(10 ** 8),
          decimal
        );
      }

      if (Number(auctionActionForm.payable) > 0 && prevAction === "payable") {
        const quantity = ethers.utils.parseUnits(rawInput ? rawInput : "0", 8);
        const payable = ethers.utils.parseUnits(
          auctionActionForm.payable,
          decimal
        );
        try {
          price = ethers.utils.formatUnits(
            payable.mul(10 ** 8).div(quantity),
            decimal
          );
        } catch {
          price = "0";
        }
      }

      setAuctionActionForm((actionForm) => ({
        ...actionForm,
        quantity: rawInput && parseFloat(rawInput) < 0 ? "" : rawInput,
        payable: payable,
        lastAction: "quantity",
        prevAction: prevAction,
        price: price,
      }));
    },
    [setAuctionActionForm, auctionActionForm, auctionData.bidding.decimals]
  );

  const handlePayableChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawInput = e.target.value;
      const decimal = auctionData.bidding.decimals.toString();

      let prevAction = auctionActionForm.prevAction;
      if (
        auctionActionForm.lastAction !== "payable" ||
        !auctionActionForm.prevAction
      ) {
        prevAction = auctionActionForm.lastAction;
      }

      let quantity = auctionActionForm.quantity;
      if (Number(auctionActionForm.payable) > 0 && prevAction === "price") {
        const price = ethers.utils.parseUnits(auctionActionForm.price, decimal);
        const payable = ethers.utils.parseUnits(
          rawInput ? rawInput : "0",
          decimal
        );
        try {
          quantity = ethers.utils.formatUnits(
            payable.mul(10 ** 8).div(price),
            8
          );
        } catch {
          quantity = "0";
        }
      }

      let price = auctionActionForm.price;
      if (Number(auctionActionForm.payable) > 0 && prevAction === "quantity") {
        const quantity = ethers.utils.parseUnits(auctionActionForm.quantity, 8);
        const payable = ethers.utils.parseUnits(
          rawInput ? rawInput : "0",
          decimal
        );
        try {
          price = ethers.utils.formatUnits(
            payable.mul(10 ** 8).div(quantity),
            decimal
          );
        } catch {
          price = "0";
        }
      }

      setAuctionActionForm((actionForm) => ({
        ...actionForm,
        payable: rawInput && parseFloat(rawInput) < 0 ? "" : rawInput,
        lastAction: "payable",
        prevAction: prevAction,
        price: price,
        quantity: quantity,
      }));
    },
    [setAuctionActionForm, auctionActionForm, auctionData.bidding.decimals]
  );

  /**
   * Handle max press from user
   */
  const handleMaxClick = useCallback(() => {
    setAuctionActionForm((actionForm) => {
      const decimal = auctionData.bidding.decimals.toString();

      let prevAction = auctionActionForm.prevAction;
      if (
        auctionActionForm.lastAction !== "quantity" ||
        !auctionActionForm.prevAction
      ) {
        prevAction = auctionActionForm.lastAction;
      }

      let payable = auctionActionForm.payable;
      if (Number(auctionActionForm.price) > 0 && prevAction === "price") {
        const quantity = auctionData.size;
        const price = ethers.utils.parseUnits(auctionActionForm.price, decimal);
        payable = ethers.utils.formatUnits(
          price.mul(quantity).div(10 ** 8),
          decimal
        );
      }

      let price = auctionActionForm.price;
      if (Number(auctionActionForm.payable) > 0 && prevAction === "payable") {
        const quantity = auctionData.size;
        const payable = ethers.utils.parseUnits(
          auctionActionForm.payable,
          decimal
        );
        try {
          price = ethers.utils.formatUnits(
            payable.mul(10 ** 8).div(quantity),
            decimal
          );
        } catch {
          price = "0";
        }
      }

      return {
        ...actionForm,
        quantity: formatUnits(auctionData.size, 8),
        lastAction: "quantity",
        prevAction: prevAction,
        payable: payable,
        price: price,
      };
    });
  }, [
    auctionActionForm,
    setAuctionActionForm,
    auctionData.bidding.decimals,
    auctionData.size,
  ]);

  return {
    handlePayableChange,
    handlePriceChange,
    handleSizeChange,
    handleMaxClick,
    resetActionForm,
    auctionActionForm,
  };
};

export default useVaultActionForm;
