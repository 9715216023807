import styled from "styled-components";
import { useCallback, useMemo } from "react";
import { AugmentedAuctionData, BidData } from "../../models/auction";
import { LinkButton } from "../Common/Buttons";
import { resolveAuctionData, resolveClearingStatus } from "../../utils/auction";
import moment from "moment";
import { formatNumber, formatPercent } from "../../utils/text";

const AuctionItemContainer = styled.div`
  display: flex;
  height: 75px;
  padding: 17px;
  border-radius: 2px;
  align-items: center;
  background-color: #ffffff;
`;

const LogoContainer = styled.div<{ color: string }>`
  height: 40px;
  width: 40px;
  margin-right: 12px;
  border: solid 1px ${(props) => props.color};
  border-radius: 50%;
  background-color: ${(props) => props.color}16;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const MainDescription = styled.div`
  display: block;
  margin-right: 60px;
`;

const AuctionTitle = styled.div`
  font-family: VCR;
  font-size: 24px;
  line-height: 20px;
  margin-bottom: 4px;
`;

const AuctionSchedule = styled.div`
  font-size: 13px;
  font-weight: 300;
`;

const Caption = styled.div`
  font-size: 13px;
  font-weight: 300;
`;

const Value = styled.div`
  font-family: VCR;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
`;

const DetailDescription = styled.div<{ left?: Boolean }>`
  display: flex;
  padding: 3px;
  color: #646464;
  align-items: center;
  margin-left: ${(props) => (props.left ? "auto" : "")};
`;

const InformationGroup = styled.div`
  display: block;
`;

const UnclaimedLabel = styled.div`
  display: flex;
  font-family: VCR;
  font-size: 12px;
  height: 25px;
  background-color: #fc0a5430;
  color: #fc0a54;
  padding: 2px 6px;
  border-radius: 4px;
  align-items: center;
  margin-left: auto;
`;

const ParadigmLabel = styled.div`
  display: flex;
  font-family: VCR;
  font-size: 12px;
  height: 25px;
  background-color: #4d6bf030;
  color: #4d6bf0;
  padding: 2px 6px;
  border-radius: 4px;
  align-items: center;
  margin-left: auto;
`;

const ZetaflexLabel = styled.div`
  display: flex;
  font-family: VCR;
  font-size: 12px;
  height: 25px;
  background-color: #ce08fa30;
  color: #ce08fa;
  padding: 2px 6px;
  border-radius: 4px;
  align-items: center;
  margin-left: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-left: 50px;
  align-items: center;
`;

const Separator = styled.div`
  width: 1px;
  height: 20px;
  background-color: #d3d3d3;
  margin: 0px 20px;
`;

const AuctionItem: React.FC<{
  variant: string;
  auctionData: AugmentedAuctionData;
  bidData: BidData[];
  onClick: (link: string) => void;
  account?: string;
  label?: string;
}> = ({ variant, auctionData, bidData, onClick, account, label }) => {
  const {
    biddingToken,
    Logo,
    logoSizeMedium,
    color,
    title,
    endtime,
    starttime,
    size,
    filled,
    clearing,
    link,
  } = resolveAuctionData(auctionData);

  const { clearingPrice, filledSize } = resolveClearingStatus(
    auctionData,
    bidData
  );

  const bidAddresses = useMemo(() => {
    const unclaimedBids = bidData.filter((value) => {
      return !value.claimtx;
    });
    return unclaimedBids.map((value) => {
      return value.account.address;
    });
  }, [bidData]);

  const optimisticClear = clearing || clearingPrice;

  const optimisticFill = filled || filledSize;

  const scheduleText = useCallback(() => {
    switch (variant) {
      case "concluded":
        return "Concluded on: ";
      case "upcoming":
        return "Scheduled: ";
      default:
        return "";
    }
  }, [variant]);

  const time = moment().unix();
  const startingIn = useMemo(() => {
    const timeLeft = Number(auctionData.start) - time;
    const days = Math.floor(timeLeft / 86400);
    const hours = Math.floor((timeLeft % 86400) / 3600);
    const minute = Math.ceil((timeLeft % 3600) / 60);

    return timeLeft > 0
      ? `${days.toString().padStart(2, "0")}D ${hours
          .toString()
          .padStart(2, "0")}H ${minute.toString().padStart(2, "0")}M`
      : label
      ? "-"
      : `STARTING`;
  }, [time, auctionData.start, label]);

  const AuctionDetails = useCallback(
    (unclaimed) => {
      switch (variant) {
        case "concluded":
          return (
            <DetailDescription left={unclaimed}>
              <InformationGroup>
                <Caption>Filled: </Caption>
                <Value>
                  {optimisticFill ? formatPercent(optimisticFill) + "%" : "-"}
                </Value>
              </InformationGroup>
              <Separator></Separator>
              <InformationGroup>
                <Caption>Size (oTokens): </Caption>
                <Value>{formatNumber(size)}</Value>
              </InformationGroup>
              <Separator></Separator>
              <InformationGroup>
                <Caption>Clearing Price (per oToken): </Caption>
                <Value>
                  {optimisticClear
                    ? optimisticClear === "-"
                      ? "-"
                      : `${formatNumber(optimisticClear)} ${biddingToken}`
                    : "-"}
                </Value>
              </InformationGroup>
            </DetailDescription>
          );
        case "upcoming":
          return (
            <DetailDescription left={unclaimed}>
              <InformationGroup style={{ minWidth: "100px" }}>
                <Caption>Starting In: </Caption>
                <Value>{startingIn}</Value>
              </InformationGroup>
              <Separator></Separator>
              <InformationGroup>
                <Caption>Bid With: </Caption>
                <Value>{biddingToken}</Value>
              </InformationGroup>
              <Separator></Separator>
              <InformationGroup>
                <Caption>Est. Size (oTokens): </Caption>
                <Value>{size === "0.0" ? "-" : formatNumber(size)}</Value>
              </InformationGroup>
            </DetailDescription>
          );
        default:
          return <></>;
      }
    },
    [biddingToken, size, optimisticClear, optimisticFill, startingIn, variant]
  );

  const unclaimed = useMemo(() => {
    return account && bidAddresses.includes(account.toLowerCase());
  }, [account, bidAddresses]);

  return (
    <>
      <AuctionItemContainer>
        <LogoContainer color={color}>
          <Logo
            height={logoSizeMedium}
            width={logoSizeMedium}
            backgroundColor="none"
          ></Logo>
        </LogoContainer>
        <MainDescription>
          <AuctionTitle>{title}</AuctionTitle>
          <AuctionSchedule>
            {scheduleText()}
            {variant === "upcoming" ? starttime.toString() : endtime.toString()}
          </AuctionSchedule>
        </MainDescription>
        {unclaimed ? (
          <UnclaimedLabel className="mr-5">UNCLAIMED</UnclaimedLabel>
        ) : (
          <></>
        )}
        {label === "zetaflex" ? (
          <ZetaflexLabel className="mr-5">VIA ZETAFLEX</ZetaflexLabel>
        ) : (
          <></>
        )}
        {label === "paradigm" ? (
          <ParadigmLabel className="mr-5">VIA PARADIGM</ParadigmLabel>
        ) : (
          <></>
        )}
        {AuctionDetails(!unclaimed && !label)}
        <ButtonContainer>
          <LinkButton
            link={link}
            children="VIEW"
            onPress={onClick}
          ></LinkButton>
        </ButtonContainer>
      </AuctionItemContainer>
    </>
  );
};

export default AuctionItem;
