import styled from "styled-components";
import { useCallback, useState } from "react";
import { AugmentedAuctionData } from "../../models/auction";
import { resolveAuctionData } from "../../utils/auction";
import { formatNumber, formatStrikeNumber } from "../../utils/text";
import {
  Assets,
  getAssetColor,
  getAssetLogo,
  getAssetLogoSize,
} from "../../utils/asset";
import ButtonArrow from "../Common/ButtonArrow";
import { AnimatePresence, motion } from "framer-motion";
import { useApprovalGlobalState } from "../../store/store";
import {
  getERC20TokenAddress,
  getEtherscanURI,
  getRelevantAuction,
} from "../../constants/constants";
import { useLatestAuction } from "../../hooks/subgraphDataContext";
import { BaseLink } from "../../design";
import { ExternalIcon } from "../../assets/icons/icons";
import { useWeb3React } from "@web3-react/core";

const AuctionItemContainer = styled.div`
  position: relative;
  display: flex;
  height: 45px;
  padding: 0px 15px 0px 10px;
  align-items: center;
  border-radius: 2px;
  background-color: #ffffff;
  z-index: 2;
`;

const OptionInfoContainer = styled.div`
  position: relative;
  display: flex;
  height: 25px;
  padding: 0px 7px;
  align-items: center;
  border-radius: 2px;
  background-color: #ffffff;
  z-index: 2;
`;

const DetailsContainer = styled(motion.div)`
  position: relative;
  margin-top: -2px;
  padding: 10px;
  align-items: center;
  border-radius: 2px;
  background-color: #efefef;
  z-index: 1;

  & > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: unset;
    }
  }
`;

const LogoContainer = styled.div<{ color: string }>`
  min-height: 25px;
  min-width: 25px;
  margin-right: 12px;
  border: solid 1px ${(props) => props.color};
  border-radius: 50%;
  background-color: ${(props) => props.color}16;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const SmallLogoContainer = styled.div<{ color: string }>`
  height: 15px;
  width: 15px;
  margin-right: 7px;
  border: solid 1px ${(props) => props.color};
  border-radius: 50%;
  background-color: ${(props) => props.color}16;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const MainDescription = styled.div`
  display: flex;
  margin-right: auto;
`;

const AuctionTitle = styled.div`
  font-family: VCR;
  line-height: 20px;
  font-size: 24px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: solid 2px #cccccc;
`;
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      margin: 3px;
      background: #ebebeb;
    }
  }
  &:disabled ~ ${RadioButtonLabel} {
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      margin: 3px;
      background: #cccccc;
    }
  }
  ${(props) =>
    props.checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      border: solid 2px #16CEB998;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        margin: 3px;
        background: #16CEB998
      }
    }
  `}
`;

const OptionInfoTitleColumn = styled.div`
  display: flex;
  border-bottom: solid 1px #d6d6d6;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  color: #757575;
`;

const OptionInfoDetailsColumn = styled.div`
  display: flex;
  width: 100%;
  padding-left: 7.5px;
  padding-right: 7.5px;
  color: #757575;
`;

const OptionInfoCaption = styled.div<{ bold?: boolean }>`
  font-size: 10px;
  font-weight: 600;
  text-align: left;
`;

const OptionInfoLogoContainer = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 600;
  text-align: left;
  align-items: center;
`;

const OptionInfoTitle = styled.div<{ ratio: string }>`
  display: block;
  flex: ${(props) => props.ratio} 1 0;
  width: auto;
  padding: 0px 0px 6px 0px;
`;

const OptionInfoDetails = styled.div<{ ratio: string }>`
  font-family: VCR;
  display: block;
  flex: ${(props) => props.ratio} 1 0;
  width: auto;
  font-weight: 100;
`;

const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
`;

const MoreInfoContainer = styled.div`
  font-size: 12px;
  color: #757575;
`;

const ApprovedLabel = styled.div`
  font-family: VCR;
  font-size: 12px;
  background-color: #16ceb930;
  color: #16ceb9;
  padding: 2px 6px;
  border-radius: 4px;
`;

const Radio: React.FC<{
  name: string;
  value: string;
  checked: boolean;
  disabled: boolean;
  onChange: () => void;
}> = ({ name, value, checked, disabled, onChange }) => (
  <Item>
    <RadioButton
      type="radio"
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
    <RadioButtonLabel />
  </Item>
);

const Label = styled.label`
  margin-bottom: unset;
`;

const TokenItem: React.FC<{
  asset: Assets;
  approved?: boolean;
  showMoreInfo?: boolean;
}> = ({ asset, approved, showMoreInfo }) => {
  const Logo = getAssetLogo(asset);
  const color = getAssetColor(asset);

  const [open, setOpen] = useState(false);

  const { chainId: currentChainId } = useWeb3React();

  const relevantAuctions = getRelevantAuction(asset);
  const { data: latestAuctions, loading } = useLatestAuction(relevantAuctions);
  const logoSize = getAssetLogoSize(asset, "s");

  const address = getERC20TokenAddress(
    asset.toLowerCase() as Assets,
    currentChainId!
  );

  const [approvalForm, setApprovalForm] =
    useApprovalGlobalState("approvalForm");

  const handleCheck = useCallback(() => {
    setApprovalForm((approvalForm) => {
      return { ...approvalForm, asset: asset };
    });
  }, [setApprovalForm, asset]);

  const renderOptionInfo = (auctionData: AugmentedAuctionData) => {
    const {
      vaultAsset,
      logoSizeXSmall,
      Logo,
      optionType,
      starttime,
      size,
      strike,
      clearing,
      color,
    } = resolveAuctionData(auctionData);

    return (
      <OptionInfoContainer>
        <OptionInfoDetailsColumn>
          <OptionInfoDetails ratio="15">
            <OptionInfoLogoContainer>
              <SmallLogoContainer color={color}>
                <Logo
                  height={logoSizeXSmall}
                  width={logoSizeXSmall}
                  backgroundColor="none"
                ></Logo>
              </SmallLogoContainer>
              {vaultAsset + " " + optionType.toUpperCase()}
            </OptionInfoLogoContainer>
          </OptionInfoDetails>
          <OptionInfoDetails ratio="13">
            <OptionInfoCaption>
              {starttime.slice(0, 9).toUpperCase()}
            </OptionInfoCaption>
          </OptionInfoDetails>
          <OptionInfoDetails ratio="10">
            <OptionInfoCaption>
              {"$" + formatStrikeNumber(strike)}
            </OptionInfoCaption>
          </OptionInfoDetails>
          <OptionInfoDetails ratio="10">
            <OptionInfoCaption>{formatNumber(size)}</OptionInfoCaption>
          </OptionInfoDetails>
          <OptionInfoDetails ratio="12">
            <OptionInfoCaption>
              {formatNumber(clearing!) + " /OTOKEN"}
            </OptionInfoCaption>
          </OptionInfoDetails>
        </OptionInfoDetailsColumn>
      </OptionInfoContainer>
    );
  };

  return (
    <div>
      <AuctionItemContainer>
        <LogoContainer color={color}>
          <Logo
            height={logoSize}
            width={logoSize}
            backgroundColor="none"
          ></Logo>
        </LogoContainer>

        <MainDescription>
          <AuctionTitle>{asset}</AuctionTitle>
          <BaseLink
            to={getEtherscanURI(currentChainId!) + "/address/" + address}
            target="_blank"
            rel="noreferrer noopener"
            className="ml-1"
          >
            <ExternalIcon
              color="#646464"
              height="20px"
              width="20px"
            ></ExternalIcon>
          </BaseLink>
        </MainDescription>

        {showMoreInfo ? (
          <ArrowButton onClick={() => setOpen((open) => !open)}>
            <MoreInfoContainer className="mr-2">
              {open ? "Close" : "More Info"}
            </MoreInfoContainer>
            <ButtonArrow isOpen={open} color="#757575"></ButtonArrow>
          </ArrowButton>
        ) : (
          <></>
        )}
        {approved ? (
          <ApprovedLabel className="ml-1">{"APPROVED"}</ApprovedLabel>
        ) : (
          <Label>
            <Radio
              name="name"
              value="name"
              disabled={approved!}
              checked={approvalForm.asset === asset}
              onChange={handleCheck}
            ></Radio>
          </Label>
        )}
      </AuctionItemContainer>

      <AnimatePresence>
        {open && (
          <DetailsContainer
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
            transition={{
              duration: 0.2,
              type: "keyframes",
              ease: "easeInOut",
            }}
          >
            <OptionInfoTitleColumn>
              <OptionInfoTitle ratio="15">
                <OptionInfoCaption>BID FOR</OptionInfoCaption>
              </OptionInfoTitle>
              <OptionInfoTitle ratio="13">
                <OptionInfoCaption>PREVIOUS</OptionInfoCaption>
              </OptionInfoTitle>
              <OptionInfoTitle ratio="10">
                <OptionInfoCaption>STRIKE</OptionInfoCaption>
              </OptionInfoTitle>
              <OptionInfoTitle ratio="10">
                <OptionInfoCaption>SIZE</OptionInfoCaption>
              </OptionInfoTitle>
              <OptionInfoTitle ratio="12">
                <OptionInfoCaption>PRICE</OptionInfoCaption>
              </OptionInfoTitle>
            </OptionInfoTitleColumn>

            {loading ? (
              <></>
            ) : (
              latestAuctions.map((auction) => {
                return renderOptionInfo(auction);
              })
            )}
          </DetailsContainer>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TokenItem;
